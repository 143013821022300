import {API_CLIENT_ID, API_CLIENT_SECRET, API_URL, API_GRANT_TYPE} from './Config';
import {startLoading,endLoading,SnackBar,handleHTTPError} from './CommonActions';
import store from '../store';
import axios from 'axios';


export function GetPages(){

    return function (dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/api/admin/locations/page/list',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            }
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                    dispatch(storePages(json));
                    dispatch(endLoading());
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Error loading data','error'));
        });

    }
}


export function storePages(pages){
    return{type:'STORE_LOCATION_PAGES',pages:pages};
}


export function DeletePage(PageID){

  return function (dispatch){
      dispatch(startLoading());
      fetch(API_URL+'/api/admin/locations/page/delete?id='+PageID,{
          method: 'GET',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
          }
      }).then(handleHTTPError)
      .then((response)=>{
            dispatch(endLoading());
            response.json().then((json)=>{
                  dispatch(GetPages());
                  dispatch(SnackBar(true,'Page deleted','success'));
            });
      }).catch((error)=>{
        dispatch(endLoading());
        dispatch(SnackBar(true,'Error deleting page','error'));
      });

  }
}

export function GetPage(pageID){

  return function (dispatch){
      dispatch(startLoading());
      fetch(API_URL+'/api/admin/locations/page/list?id='+pageID,{
          method: 'GET',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
          }
      }).then(handleHTTPError)
      .then((response)=>{
            dispatch(endLoading());
            response.json().then((json)=>{
                  dispatch(storeActivePage(json));

            });
      }).catch((error)=>{
        dispatch(endLoading());
        dispatch(SnackBar(true,'Error loading locations','error'));
      });

  }
}


export function storeActivePage(page){
  return{type:'STORE_ACTIVE_PAGE',p:page};
}

export function LockActiveDeal(){
  return{type:'LOCK_ACTIVE_MESSAGE'};
}


export function UpdatePage(data){
  console.log("UpdatePage")
  console.log(data)
  return function(dispatch) {
      dispatch(SnackBar(true,'Uploading image, please wait...','info'));

      var fd = new FormData();

      if(data.uploadImage !== null){
        fd.append('file',data.uploadImage,data.uploadImage.name);
      }

      fd.append('id',data.id);
      fd.append('location',data.location);
      fd.append('title',data.title);
      fd.append('description',data.description);
      fd.append('seo_title',data.seo_title);
      fd.append('seo_description',data.seo_description);
      fd.append('seo_url',data.seo_url);
      fd.append('image_alt',data.image_alt);

      if(data.tags !== null && data.tags.length > 0){
        for(var x=0; x < data.tags.length; x++){
          fd.append('Tags[]',data.tags[x].value);
        }

      }

      if(data.activities.length > 0){
        for(var x=0; x < data.activities.length; x++){
          fd.append('activities[]',data.activities[x].id);
        }

      }



      axios.post(API_URL+'/api/admin/locations/page/update',fd,{
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
          },
      }).then((response)=>{
            dispatch(endLoading());
            dispatch(GetPages());

              dispatch(GetPage(response.data.id));


            dispatch(SnackBar(true,'Your profie was updated','success'));
      }).catch((error)=>{
          console.log(error)
        dispatch(endLoading());
        dispatch(SnackBar(true,'Data saving error','error'));
      });
  }
}
