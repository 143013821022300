import React from 'react';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { withRouter} from 'react-router-dom'

import Emoji1 from '../activity/images/img-emoji.png';
import Emoji2 from '../activity/images/img-emoji.png';

import { compose } from 'redux';
import { connect } from 'react-redux';


class ActivityStatus extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            
        }
    }

    backToListing =()=>{
      this.props.history.push('/Tours');
    }
    
    createNew =()=>{
      this.props.history.push('/NewActivity');
    }

    render() {

        

        return (<div>
            

            
              <div className="supplier-block message-details">              
                <div className="block-content">
                  <div className="inner-block-content">
                    <div className="one-full-col">
                      <div className="step-message">
                        <div className="step-img">                         
                          <div className="message-greet"></div>
                        </div>
      
                        <div className="step-msg">
                          
                          <p></p>
                          <a onClick={()=>this.backToListing()} style={{cursor:'pointer'}} className="button-orange btn-last-step">Tours & Activities</a> &nbsp;
                          <a href={'http://aktivitar.com/activity/details/'+this.props.tid} target="_blank" style={{cursor:'pointer'}} className="button-orange btn-last-step">View Product</a>

                        </div>
                        <div className="clear"></div>
                      </div>
                    </div>
                  <div className="clear"></div>
                </div>
              </div>        
            </div>
            




        </div>)
        
    }
}

export default compose(withRouter)(ActivityStatus);