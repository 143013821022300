import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import Paper from '@material-ui/core/Paper';
import MessageIcon from '@material-ui/icons/Message';
import SendIcon from '@material-ui/icons/Send';
import InputBase from '@material-ui/core/InputBase';
import { Divider } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  card: {
    minWidth: 400,
    marginTop:10,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  actions: {
    display: 'flex',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
});

class RecipeReviewCard extends React.Component {
  state = { expanded: false };

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };

  render() {
    const { classes } = this.props;

    return (
        <Card className={classes.card}>
  
     
        <CardHeader
          avatar={
            <Avatar aria-label="Recipe" className={classes.avatar}>
              R
            </Avatar>
          }
          title="Sed eu commodo risus"
          subheader="May 14, 2019"
        />
        
        <CardContent>
          <Typography component="p">
          Suspendisse semper erat vel dignissim imperdiet. Nam sed nunc imperdiet, scelerisque ante quis, dapibus ante.
            </Typography>     
        </CardContent>
        <CardActions style={{textAlign:'right'}}>
        <Button size="small" color="primary" >Approve</Button>
        <Button size="small" color="primary">Hide</Button>
      </CardActions>
        <div>
        <Divider/>
        <CardHeader
          avatar={
            <Avatar aria-label="Recipe" className={classes.avatar}>
              P
            </Avatar>
          }
         
          title="Quisque posuere convallis elit"
          subheader="May 14, 2019"
        />
        
        <CardContent>
          <Typography component="p">
          Aenean congue dui et pharetra convallis. Mauris fermentum accumsan leo.
           </Typography>
        </CardContent>
        <CardActions style={{textAlign:'right'}}>
        <Button size="small" color="primary" >Approve</Button>
        <Button size="small" color="primary">Hide</Button>
      </CardActions>
        </div>

        <div>
        <Divider/>
        <CardHeader
          avatar={
            <Avatar aria-label="Recipe" className={classes.avatar}>
              R
            </Avatar>
          }
         
          title="Pellentesque pulvinar felis"
          subheader="May 14, 2019"
        />
        
        <CardContent>
          <Typography component="p">
          Nam sed nunc imperdiet, scelerisque ante quis, dapibus ante. Aenean volutpat velit tincidunt leo porttitor laoreet.
          </Typography>
        </CardContent>
        <CardActions style={{textAlign:'right'}}>
        <Button size="small" color="primary" >Approve</Button>
        <Button size="small" color="primary">Hide</Button>
      </CardActions>
        </div>

        <div>
        <Divider/>
        <CardHeader
          avatar={
            <Avatar aria-label="Recipe" className={classes.avatar}>
              P
            </Avatar>
          }
         
          title="Donec a libero ac eros"
          subheader="May 14, 2019"
        />
        
        <CardContent>
          <Typography component="p">
          Vestibulum luctus. Aliquam erat volutpat. Maecenas pellentesque lobortis diam, eu blandit metus venenatis id.
          </Typography>
        </CardContent>
        <CardActions style={{textAlign:'right'}}>
        <Button size="small" color="primary" >Approve</Button>
        <Button size="small" color="primary">Hide</Button>
      </CardActions>
        </div>
      </Card>

      
    );
  }
}

RecipeReviewCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RecipeReviewCard);