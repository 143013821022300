export default function reducer(state={
    tags:[]
},action){

    switch(action.type){
        case "STORE_TAGS":{
            return{...state,tags:action.tags}
        }
    }

    return state;
}
