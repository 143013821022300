export default function reducer(state={
    list:[],
    activeMessage:{
        title:"",
        id:0,
        description:"",
        discount:null,
        image_name:null,
        updated:false,
    }
},action){

    switch(action.type){
        case "STORE_ADMIN_MESSAGES":{
            return{...state,list:action.messages}
        }
        case "STORE_ACTIVE_MESSAGE":{
            return{...state,activeMessage:{
                ...state.activeMessage,
                id:action.m.id,
                title:action.m.title,
                description:action.m.description,                
                image_name:action.m.image_name,
                updated:true
            }}
        }
        case "LOCK_ACTIVE_MESSAGE":{
            return{...state,activeMessage:{
                ...state.activeMessage,                
                updated:false
            }}
        }         
    }

    return state;
}