import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import TextField from '@material-ui/core/TextField';


import { compose } from 'redux';
import { connect } from 'react-redux';

import { ViewRefundForm,createRefund } from '../../Actions/FinanceActions';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


  
  

   


const mapStateToProps = (state) => {
    return {       
       refundRequestView : state.Finance.refundRequestView, 
       refundRequestViewData : state.Finance.refundRequestViewData, 
       refundRequestViewAction : state.Finance.refundRequestViewAction,
                      
    }
}

const mapDispatchToProps = dispatch => ({
  ViewRefundForm : (show,data,on) => dispatch(ViewRefundForm(show,data,on)),
  createRefund:(amount,remark,data)=>dispatch(createRefund(amount,remark,data))
});

class RefundRequestView extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            amount:0,
            remark:0
        };
    }

    handleConfirmaton = () =>{
      
      this.props.refundRequestViewAction(3,this.props.refundRequestViewData);
      this.props.ViewRefundForm(false,null,null)
    }

    handleChage = (e,field)=>{
        this.setState({
            [field]:e.target.value
        });
    }

    handleClose = ()=> {        
       
        this.props.SnackBar(false,null,this.props.alertVarient);
    }


    getRefundStatus(status){        
      switch(status){
      case 0 :{ return "Pending" }
      case 1:{ return "Accepted" }
      case 2 :{ return "Rejected" }
      case 3 :{ return "Completed" }    
      }
    }

    render() {

        return (
            <div>             
              <Dialog
        open={this.props.refundRequestView}
        TransitionComponent={Transition}
        keepMounted
        onClose={()=>{ this.props.ViewRefundForm(false,null,null);}}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Refund Request"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
              <div>
                  <table style={{width:500}}>
                      <tr>
                          <th style={{ width:150, textAlign:'left',  height:25 }}>ACTIVITY</th>
                          <td>
                              {this.props.refundRequestViewData !== null ? this.props.refundRequestViewData.activity.title:null}
                          </td>
                      </tr>
                      <tr>
                          <th style={{ width:150, textAlign:'left',  height:25 }}></th>
                          <td>
                            &nbsp;
                          </td>
                      </tr>  
                      <tr>
                          <th style={{ width:150, textAlign:'left', height:25 }}>START DATE</th>
                          <td>
                          {this.props.refundRequestViewData !== null ? this.props.refundRequestViewData.booking.start_date:null}
                          </td>
                      </tr>
                      <tr>
                          <th style={{ width:150, textAlign:'left',  height:25 }}></th>
                          <td>
                            &nbsp;
                          </td>
                      </tr>
                     
                      <tr>
                          <th style={{ width:150, textAlign:'left',  height:25 }}></th>
                          <td>
                            &nbsp;
                          </td>
                      </tr>
                      <tr>
                          <th style={{ width:150, textAlign:'left', height:25 }}>AMOUNT</th>
                          <td style={{verticalAlign:'top'}}>                              
                          {this.props.refundRequestViewData !== null ? this.props.refundRequestViewData.amount:null}
                          </td>
                      </tr>
                      <tr>
                          <th style={{ width:150, textAlign:'left',  height:25 }}></th>
                          <td>
                            &nbsp;
                          </td>
                      </tr>
                      <tr>
                          <th style={{ width:150, textAlign:'left' }}>ADMIN REMARK</th>
                          <td style={{verticalAlign:'top'}}>
                              {this.props.refundRequestViewData !== null ? this.props.refundRequestViewData.admin_remark:null}
                          </td>
                      </tr>
                      <tr>
                          <th style={{ width:150, textAlign:'left',  height:25 }}></th>
                          <td>
                            &nbsp;
                          </td>
                      </tr>
                      <tr>
                          <th style={{ width:150, textAlign:'left' }}>SUPPLIER REMARK</th>
                          <td style={{verticalAlign:'top'}}>
                              {this.props.refundRequestViewData !== null ? this.props.refundRequestViewData.supplier_remark:null}
                          </td>
                      </tr>
                      <tr>
                          <th style={{ width:150, textAlign:'left',  height:25 }}></th>
                          <td>
                            &nbsp;
                          </td>
                      </tr>
                      <tr>
                          <th style={{ width:150, textAlign:'left' }}>REFUND STATUS</th>
                          <td style={{verticalAlign:'top'}}>
                              {this.props.refundRequestViewData !== null ? this.getRefundStatus(this.props.refundRequestViewData.status):null}
                          </td>
                      </tr>
                  </table>
              </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{ this.props.ViewRefundForm(false,null,null);}}  color="primary">
            Cancel
          </Button>
          <Button onClick={()=>{this.handleConfirmaton();}}  color="primary">
            Complete Refund
          </Button>
        </DialogActions>
      </Dialog>
              </div>);
    }
    
}


export default compose(connect(mapStateToProps, mapDispatchToProps))(RefundRequestView);
