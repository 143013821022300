import React, { Fragment } from 'react';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import UploadIcon from '@material-ui/icons/CloudUpload';

import { compose } from 'redux';
import { connect } from 'react-redux';



import Button from '@material-ui/core/Button';


import { UpdateSlider,DeleteSlider,UploadSlide } from '../Actions/SettingsAction';

const mapStateToProps = (state) => {
    return {
        
    }
}
const mapDispatchToProps = dispatch => ({ 
    UpdateSlider:(data)=>dispatch(UpdateSlider(data)),
    DeleteSlider:(id)=>dispatch(DeleteSlider(id)),
    UploadSlide:(data)=>dispatch(UploadSlide(data))
});

class EditSlide extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            editMode: false,
            id:0,
            title:"",
            subtext:"",
            imageName:null,
            updated:false,
            imageUpdated:false
        }
    }


    handleEditMode = () =>{
        this.setState({
            editMode:true
        });
    }


    handleApply =()=>{

        

        this.handleUpdate();

        this.setState({
            editMode:false,
            updated:false
        });
    }


    static getDerivedStateFromProps(props, state) {
        
        if (state.updated === false) {
            return {
                id:props.row.id,
                title:props.row.title,
                subtext:props.row.sub_text,
                imageName:props.row.image_name,                
                updated:true
            }
        }

        if (state.imageName !== props.row.image_name) {
            return {                
                imageName:props.row.image_name,               
                
            }
        }

        

        
    }

    handleUpdate = () =>{
        this.props.UpdateSlider({id:this.state.id,title:this.state.title,sub_text:this.state.subtext});
    }

    handleEditField = (e,field) => {
        this.setState({
            [field]:e.target.value
        })
    }

    handleDelete = () =>{
        this.props.DeleteSlider(this.state.id);
    }

    uploadImage = (e) => {

        if (e.target.files[0] != null) {
            
          this.props.UploadSlide({uploadImage:e.target.files[0],id:this.state.id});
          this.setState({
            editMode:false,
            imageUpdated:true
            });      
        }
    
      }

    render() {
        const { classes } = this.props;
    
        
        return <Fragment>
            {this.state.editMode === false ? (            
            <tr >               
                <td align="left" style={{width:'10%'}}>{this.state.id}</td>
                <td align="left" style={{width:'15%'}}>{ this.state.imageName !== null ? <img src={this.state.imageName} width={120} height="auto" /> : null  }</td>
                <td align="left" style={{width:'20%'}}>{this.state.title}</td>
                <td align="left" style={{width:'20%'}}>{this.state.subtext !== null ? this.state.subtext.substring(0,20):""}...</td>              
                <td align="center">
                <IconButton onClick={()=>this.handleEditMode()} size="small" >
                    <EditIcon />
                </IconButton>    
                 &nbsp;
                 <IconButton onClick={()=>this.handleDelete()} size="small">
                     <DeleteIcon />
                 </IconButton>
                </td>
              </tr> 
                        ) : (
            
                <tr >               
                <td align="left" style={{width:'10%'}}>{this.state.id}</td>
                <td align="left" style={{width:'15%'}}>{ this.state.imageName !== null ? <img src={this.state.imageName} width={120} height="auto" /> : null  }</td>
                <td align="left" style={{width:'20%'}}>
                    <input type="text" value={this.state.title} onChange={(e)=>this.handleEditField(e,'title')} />            
                </td>
                <td align="left" style={{width:'20%'}}>
                    <input type="text" value={this.state.subtext} onChange={(e)=>this.handleEditField(e,'subtext')} />            
                </td>              
                <td align="center">
                <input
                              accept="image/*"
                              style={{ display: "none" }}
                              id="file-slide"
                              type="file"
                              onChange={this.uploadImage}
                            />
                            <label htmlFor="file-slide">                        
                            <IconButton component="a" size="small">
                                    <UploadIcon/>
                                </IconButton></label> &nbsp;
                                <IconButton onClick={()=>this.handleApply()}>
                    <SaveIcon/>
                </IconButton>                
                </td>
              </tr>
            )}
        </Fragment>
    }
}

EditSlide.defaultProps = {
    readOnly:false
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(EditSlide);