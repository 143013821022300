import React, { Props, Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DateAndTimePickers from '../Components/DateAndTimePickers';
import ViewTotalBookings from './Dialogs/ViewTotalBookings';
import Graph from '../Img/graph.png'
import Typography from '@material-ui/core/Typography';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CssBaseline from "@material-ui/core/CssBaseline";
import Pagination from "material-ui-flat-pagination";

//import FormControl from '@material-ui/core/FormControl';
//import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import Input from '@material-ui/core/Input';
import OutlinedInput from '@material-ui/core/OutlinedInput';
//import InputLabel from '@material-ui/core/InputLabel';

import { compose } from 'redux';
import { connect } from 'react-redux';

import { GetSiteContent,UpdateSiteContent } from '../Actions/SettingsAction';
import { fetchCategories } from '../Actions/CategoryAction';

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },


  table: {
    minWidth: 700,

  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
});




let id = 0;
function createData(name, type, actions) {
  id += 1;
  return { id, name, type, actions };
}

const rows = [
  createData('1', '2'),

];

const mapStateToProps = (state) => {
  return {
      loading : state.Common.loading,
      loggedIn : state.Auth.loggedIn,
      categories: state.Categories.categories,
      site_aboutus: state.Common.site_aboutus,
      site_privacy: state.Common.site_privacy,
      site_cookie: state.Common.site_cookie,
      site_disclaimer: state.Common.site_disclaimer,
      site_guidelines: state.Common.site_guidelines,
      aboutus_meta_title:state.Common.aboutus_meta_title,
      aboutus_meta_description:state.Common.aboutus_meta_description,
      privacy_meta_title:state.Common.privacy_meta_title,
      privacy_meta_description:state.Common.privacy_meta_description,
      cookie_policy_meta_title:state.Common.cookie_policy_meta_title,
      cookie_policy_meta_description:state.Common.cookie_policy_meta_description,
      disclainer_meta_title:state.Common.disclainer_meta_title,
      disclainer_meta_description:state.Common.disclainer_meta_description,
      guidelines_meta_title:state.Common.guidelines_meta_title,
      guidelines_meta_description:state.Common.guidelines_meta_description, 
  }
}
const mapDispatchToProps = dispatch => ({
  GetSiteContent: () => dispatch(GetSiteContent()),
  
  UpdateSiteContent:(data)=>dispatch(UpdateSiteContent(data))
});


class Pages extends Component {

  constructor(props) {
    super(props);

    this.state = {
      viewOpen: false,
      site_aboutus: this.props.site_aboutus,
      site_privacy:  this.props.site_privacy,
      site_cookie:  this.props.site_cookie,
      site_disclaimer:  this.props.site_disclaimer,
      site_guidelines:  this.props.site_guidelines,
      aboutus_meta_title:"",
      aboutus_meta_description:"",
      privacy_meta_title:"",
      privacy_meta_description:"",
      cookie_policy_meta_title:"",
      cookie_policy_meta_description:"",
      disclainer_meta_title:"",
      disclainer_meta_description:"",
      guidelines_meta_title:"",
      guidelines_meta_description:"",
    };

    
    this.props.GetSiteContent();
  }

  componentWillReceiveProps = (nextProps) =>{
    this.setState({
                    site_aboutus: nextProps.site_aboutus,
                    site_privacy:  nextProps.site_privacy,
                    site_cookie:  nextProps.site_cookie,
                    site_disclaimer:  nextProps.site_disclaimer,
                    site_guidelines:  nextProps.site_guidelines,
                    aboutus_meta_title:nextProps.aboutus_meta_title,
                    aboutus_meta_description:nextProps.aboutus_meta_description,
                    privacy_meta_title:nextProps.privacy_meta_title,
                    privacy_meta_description:nextProps.privacy_meta_description,
                    cookie_policy_meta_title:nextProps.cookie_policy_meta_title,
                    cookie_policy_meta_description:nextProps.cookie_policy_meta_description,
                    disclainer_meta_title:nextProps.disclainer_meta_title,
                    disclainer_meta_description:nextProps.disclainer_meta_description,
                    guidelines_meta_title:nextProps.guidelines_meta_title,
                    guidelines_meta_description:nextProps.guidelines_meta_description,                    
                  });
}

  handleViewTr = () => {
    this.setState({
      viewOpen: true,
    });
  }

  handleClose = () => {
    this.setState({ viewOpen: false });
  }

  handleChange = (e,field) =>{
    this.setState({
      [field]:e.target.value
    })
  }

  handleUpdateSettings = () =>{
      this.props.UpdateSiteContent({
                                        aboutus:this.state.site_aboutus,
                                        privacy:this.state.site_privacy,
                                        cookie_policy:this.state.site_cookie,
                                        disclainer:this.state.site_disclaimer,
                                        guidelines:this.state.site_guidelines,
                                        aboutus_meta_title:this.state.aboutus_meta_title,
                                        aboutus_meta_description:this.state.aboutus_meta_description,
                                        privacy_meta_title:this.state.privacy_meta_title,
                                        privacy_meta_description:this.state.privacy_meta_description,
                                        cookie_policy_meta_title:this.state.cookie_policy_meta_title,
                                        cookie_policy_meta_description:this.state.cookie_policy_meta_description,
                                        disclainer_meta_title:this.state.disclainer_meta_title,
                                        disclainer_meta_description:this.state.disclainer_meta_description,
                                        guidelines_meta_title:this.state.guidelines_meta_title,
                                        guidelines_meta_description:this.state.guidelines_meta_description,
                                    });
  }

  render() {

    const { classes } = this.props;




    return (
      <div className={classes.root} elevation={1} style={{ marginBottom: 30 }}>
        <div style={{ paddingRight: 30, paddingTop: 10, marginBottom: 30 }}>


        </div>
        <div width='100%' style={{ paddingRight: 30, paddingTop: 6, marginBottom: 30 }}  >
          <table style={{ width:'100%' }}>

            <tr>
              
              <td>
              <br/>     
                <h4> About us </h4>
                <TextField                
                
                style={{width:'100%'}}
                id="outlined-name"
                label="Title"
                className={classes.textField}
                style={{ width: '100%' }}
                 value={this.state.aboutus_meta_title}
                 onChange={(e)=>this.handleChange(e,'aboutus_meta_title')}
                margin="normal"
                variant="outlined"
              />
              <TextField                
                
                style={{width:'100%'}}
                id="outlined-name"
                label="Description"
                className={classes.textField}
                style={{ width: '100%' }}
                 value={this.state.aboutus_meta_description}
                 onChange={(e)=>this.handleChange(e,'aboutus_meta_description')}
                margin="normal"
                variant="outlined"
              />
              <TextField
                multiline
                rows="10"
                style={{width:'100%'}}
                id="outlined-name"
                
                className={classes.textField}
                style={{ width: '100%' }}
                 value={this.state.site_aboutus}
                 onChange={(e)=>this.handleChange(e,'site_aboutus')}
                margin="normal"
                variant="outlined"
              /> 
                </td>

              
            </tr>

            <tr>
             
              <td>
              <br/>
              <h4>Privacy Policy </h4>
              <TextField                
                
                style={{width:'100%'}}
                id="outlined-name"
                label="Title"
                className={classes.textField}
                style={{ width: '100%' }}
                 value={this.state.privacy_meta_title}
                 onChange={(e)=>this.handleChange(e,'privacy_meta_title')}
                margin="normal"
                variant="outlined"
              />
              <TextField                
                
                style={{width:'100%'}}
                id="outlined-name"
                label="Description"
                className={classes.textField}
                style={{ width: '100%' }}
                 value={this.state.privacy_meta_description}
                 onChange={(e)=>this.handleChange(e,'privacy_meta_description')}
                margin="normal"
                variant="outlined"
              />
              <TextField
                multiline
                rows="10"
                style={{width:'100%'}}
                id="outlined-name"
                
                className={classes.textField}
                style={{ width: '100%' }}
                 value={this.state.site_privacy}
                 onChange={(e)=>this.handleChange(e,'site_privacy')}
                margin="normal"
                variant="outlined"
              />


              </td>
              
            </tr>

            <tr>
              
              <td>
              <br/>
                <h4> Cookie Policy</h4>
                <TextField                
                
                style={{width:'100%'}}
                id="outlined-name"
                label="Title"
                className={classes.textField}
                style={{ width: '100%' }}
                 value={this.state.cookie_policy_meta_title}
                 onChange={(e)=>this.handleChange(e,'cookie_policy_meta_title')}
                margin="normal"
                variant="outlined"
              />
              <TextField                
                
                style={{width:'100%'}}
                id="outlined-name"
                label="Description"
                className={classes.textField}
                style={{ width: '100%' }}
                 value={this.state.cookie_policy_meta_description}
                 onChange={(e)=>this.handleChange(e,'cookie_policy_meta_description')}
                margin="normal"
                variant="outlined"
              />
                <TextField
                multiline
                rows="10"
                style={{width:'100%'}}
                id="outlined-name"
                
                className={classes.textField}
                style={{ width: '100%' }}
                 value={this.state.site_cookie}
                 onChange={(e)=>this.handleChange(e,'site_cookie')}
                margin="normal"
                variant="outlined"
              />


              </td>
              
            </tr>

            <tr>
              
              <td>
                <br/>
              <h4>Product Disclaimers </h4>
              <TextField                
                
                style={{width:'100%'}}
                id="outlined-name"
                label="Title"
                className={classes.textField}
                style={{ width: '100%' }}
                 value={this.state.disclainer_meta_title}
                 onChange={(e)=>this.handleChange(e,'disclainer_meta_title')}
                margin="normal"
                variant="outlined"
              />
              <TextField                
                
                style={{width:'100%'}}
                id="outlined-name"
                label="Description"
                className={classes.textField}
                style={{ width: '100%' }}
                 value={this.state.disclainer_meta_description}
                 onChange={(e)=>this.handleChange(e,'disclainer_meta_description')}
                margin="normal"
                variant="outlined"
              />
              <TextField
                multiline
                rows="10"
                style={{width:'100%'}}
                id="outlined-name"
                
                className={classes.textField}
                style={{ width: '100%' }}
                 value={this.state.site_disclaimer}
                 onChange={(e)=>this.handleChange(e,'site_disclaimer')}
                margin="normal"
                variant="outlined"
              />
              </td>
              
            </tr> 
            <tr>
              
              <td>
                <br/>
              <h4>Review Guidelines </h4>
              <TextField                
                
                style={{width:'100%'}}
                id="outlined-name"
                label="Title"
                className={classes.textField}
                style={{ width: '100%' }}
                 value={this.state.guidelines_meta_title}
                 onChange={(e)=>this.handleChange(e,'guidelines_meta_title')}
                margin="normal"
                variant="outlined"
              />
              <TextField                
                
                style={{width:'100%'}}
                id="outlined-name"
                label="Description"
                className={classes.textField}
                style={{ width: '100%' }}
                 value={this.state.guidelines_meta_description}
                 onChange={(e)=>this.handleChange(e,'guidelines_meta_description')}
                margin="normal"
                variant="outlined"
              />
              <TextField
                multiline
                rows="10"
                style={{width:'100%'}}
                id="outlined-name"
                
                className={classes.textField}
                style={{ width: '100%' }}
                 value={this.state.site_guidelines}
                 onChange={(e)=>this.handleChange(e,'site_guidelines')}
                margin="normal"
                variant="outlined"
              />
              </td>
              
            </tr>  
            <tr>
              
              <td align="Right" style={{float:'right'}}  >
                <Button    onClick={()=>this.handleUpdateSettings()} variant="contained" size="small" color="primary" style={{background:"#f7a414", textAlign:'center'}} className={classes.button}>
                 <b>Save</b> 
                </Button>
              </td>
              <td style={{}} ></td>
                <td>
                  
                </td>
            </tr>



          </table>
        </div>


      </div>

    );
  }
}


Pages.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Pages);