import { combineReducers } from 'redux';

import Auth from './AuthReducer';
import Common from './CommonReducer';
import Categories from './Category';
import Activities from './Activities';
import Account from './Account';
import Suppliers from './Suppliers';
import Locations from './Locations';
import Deals from './Deals';
import Reviews from './Reviews';
import Booking from './Booking';
import Messages from './Messages';
import Finance from './Finance';
import LocationPages from './LocationPages';
import Tags from './Tags';

export default combineReducers({
    Auth,
    Common,
    Categories,
    Activities,
    Account,
    Suppliers,
    Locations,
    Deals,
    Reviews,
    Booking,
    Messages,
    Finance,
    LocationPages,
    Tags
   })
