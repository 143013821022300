import React from 'react';



class HyperLinkTextBox extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            editMode: false,
            value:""
        }
    }


    componentWillReceiveProps(nextProps) {
        // This will erase any local state updates!
        // Do not do this.
        this.setState({ value: nextProps.value });
      }

    


    handleEditMode = () =>{
        this.setState({
            editMode:true
        });
    }

    onChange = (e) =>{
        this.setState({
            value:e.target.value
        })
    }


    handleApply =()=>{

        if(this.props.validate !== null){
            if(this.props.validate === 'phone'){
                var phoneno = /^[\d\.\-]+$/;
                if(!phoneno.test(this.state.value)){
                    this.props.onError(true,this.props.label+" number is invalid",'error');
                    return false;
                }
            }
        } 

        if(this.props.required === true){
            if(this.props.value === null){
                this.props.onError(true,this.props.label+" can not be empty",'error');
                return false;
            }

            if(this.props.value.length === 0){
                this.props.onError(true,this.props.label+" can not be empty",'error');
                return false;
            }
        }





        this.setState({
            editMode:false
        },()=>{
            this.props.onOK(this.state.value);
        });
    }

    render() {

    
        
        return <div>
            {this.state.editMode === false ? (
            <div className="profrm-row">
            <label>{this.props.label}</label>
            <input disabled={true}   type="text" value={this.state.value} style={{backgroundColor:'rgba(202, 201, 201, 0.21)'}} name=""/>
            <span onClick={()=>this.handleEditMode()}></span>
          </div>
            
            ) : (
            
              <div className="profrm-row active">
                <label>{this.props.label}</label>
                <input onChange={this.onChange} type="text" value={this.state.value} name=""/>
                <span onClick={()=>this.handleApply()}></span>
              </div>
            )}

            {this.props.review === true ? (<div style={{fontSize:12,display:'block',marginTop:-15,marginBottom:15}}>
            <a style={{color:'green',cursor:'pointer'}} onClick={()=>this.props.reviewAction(1,this.props.field)} >Approve</a>&nbsp;&nbsp;<a style={{color:'red',cursor:'pointer'}} onClick={()=>this.props.reviewAction(0,this.props.field)} >Reject</a>
            </div>):null}
            
            
        </div>
    }
}

HyperLinkTextBox.defaultProps ={
    onOK:null,
    review:false,
    validate:null,
    value:""    
}

export default HyperLinkTextBox;