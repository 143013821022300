import React, { Props, Component, Fragment } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import MessagesPanel from '../Components/MessagesPanel';
import BookingsView from '../Components/BookingsView';

import Paper from '@material-ui/core/Paper';
import Drawer from './Common/Drawer'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InputLabel from '@material-ui/core/InputLabel';
import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';


import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { compose } from 'redux';
import { connect } from 'react-redux';

import AppBar from './Common/MainAppBar';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Label from '@material-ui/icons/Label';

import { validateUser, storeAuthToken, fetchUser, storeUser } from '../Actions/AuthActions';
import { Redirect } from 'react-router-dom'

import logo from '../Img/logo.png'
import Profile from '../Img/profile.png'
import Loader from './Common/Loader';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Divider } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Images from '../Components/Images';


import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';

import SchedulePanel from '../Components/SchedulePanel';

import { fetchCategories } from '../Actions/CategoryAction';
import { fetchLocations } from '../Actions/LocationAction';
import { updateActivity, GetActivity, LockActiveActivity, uploadPicture,ChangeStatus } from '../Actions/ActivityActions';
import Chip from '@material-ui/core/Chip/Chip';


const drawerWidth = 240;

const styles = theme => ({

  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },

  button: {
    display: 'block',
    marginTop: theme.spacing.unit * 2,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },

  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },

  fab: {
    margin: theme.spacing.unit,
  },

  extendedIcon: {
    marginRight: theme.spacing.unit,
  },

  wrap: {
    width: 480,
    margin: 'auto',
    marginTop: 100,

  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,

  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '98%'
  },

  button: {
    margin: theme.spacing.unit,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },

  logoWrap: {
    textAlign: 'center',
  },
  toolbar: theme.mixins.toolbar,
});




const mapStateToProps = (state) => {
  return {
    loading: state.Common.loading,
    loggedIn: state.Auth.loggedIn,
    categories: state.Categories.categories,
    activeTour: state.Activities.activeTour,
    locations: state.Locations.locations,
  }
}

const mapDispatchToProps = dispatch => ({

  fetchCategories: () => dispatch(fetchCategories()),
  updateActivity: (data) => dispatch(updateActivity(data)),
  GetActivity: (aid) => dispatch(GetActivity(aid)),
  LockActiveActivity: () => dispatch(LockActiveActivity()),
  uploadPicture: (file, aid) => dispatch(uploadPicture(file, aid)),
  fetchLocations:()=>dispatch(fetchLocations()),
  ChangeStatus:(data)=>dispatch(ChangeStatus(data))
});


const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#666ad1',
      main: '#303f9f',
      dark: '#001970',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff5c8d',
      main: '#d81b60',
      dark: '#a00037',
      contrastText: '#000',
    },

    third: {
      light: '#6d6d6d',
      main: '#424242',
      dark: '#1b1b1b',
      contrastText: '#fff',
    },
  },
});

class ToursDetails extends Component {

  constructor(props) {
    super(props);

    const { aid } = this.props.match.params;

    this.state = {
      activeStep: 0,
      completed: new Set(),
      skipped: new Set(),

      id: 0,
      title: "",
      tour_type: 0,
      duration: 0,
      duration_value: 0,
      categories: new Map(),
      overview: null,
      adults_age_from: null,
      adults_age_to: null,
      children_age_from: null,
      children_age_to: null,
      infant_age_from: null,
      infant_age_to: null,
      inclusion: null,
      exclision: null,
      know: null,
      condtions: null,
      contact_number: null,
      categories: new Map(),
      images: new Array(),
      itinery: new Array(),
      pricing_for: null,
      schedules: new Array(),
      status:null,
      updated: false,
    };


    this.props.fetchCategories();
    this.props.fetchLocations();

    if (aid != undefined) {
      this.props.GetActivity(aid);
    }

    
  }


  static getDerivedStateFromProps(props, state) {


    
    if (props.activeTour.updated) {
      
      props.LockActiveActivity();
      return {
        id: props.activeTour.id,
        title: props.activeTour.title,
        tour_type: props.activeTour.tour_type,
        duration: props.activeTour.duration,
        duration_value: props.activeTour.duration_value,
        categories: new Map(),
        overview: props.activeTour.overview,
        adults_age_from: props.activeTour.adults_age_from,
        adults_age_to: props.activeTour.adults_age_to,
        children_age_from: props.activeTour.children_age_from,
        children_age_to: props.activeTour.children_age_to,
        infant_age_from: props.activeTour.infant_age_from,
        infant_age_to: props.activeTour.infant_age_to,
        inclusion: props.activeTour.inclusion,
        exclision: props.activeTour.exclision,
        know: props.activeTour.know,
        condtions: props.activeTour.condtions,
        contact_number: props.activeTour.contact_number,
        pricing_for: props.activeTour.pricing_for !== null ? props.activeTour.pricing_for.toString() : null,
        images: props.activeTour.images,
        itinery: props.activeTour.itinery,
        status: props.activeTour.status,
        schedules: props.activeTour.schedules
      }
    }

    return null;


  }

  componentDidMount() {
    document.body.style.background = "#eee";

  }

  

  getTripType(type){
        
    switch(type){
      case 1 :{ return "Round tour" }
      case 2 :{ return "Day Tour" }
      case 3 :{ return "Activity" }
    }
}

getDuration(measure){        
  switch(measure){
    case 1 :{ return "Days" }
    case 2 :{ return "Hours" }
    case 3 :{ return "Minutes" }
  }
}


getStatus(status){        
  switch(status){
    case 0 :{ return "Pending" }
    case 1:{ return "Active" }
    case 2 :{ return "Rejected" }
    case 3 :{ return "Canceled" }
  }
}
getPricingBasis(status){ 
  console.log(status);       
  switch(status){    
    case '1':{ return "Per Person" }
    case '2' :{ return "Per Group" }
  }
}

changeStatus = (status) =>{
    this.props.ChangeStatus({id:this.state.id,status:status});
}

  render() {

    const { classes } = this.props;

    


    return <Fragment>

      <AppBar title="Dashboard"></AppBar>

      <Drawer></Drawer>

      <div style={{ width: 'auto', marginLeft: drawerWidth + 0, paddingTop: 35, height: 250 }}>

      <div>

      <Table>

          <TableBody>
            <TableRow>
                  <TableCell style={{backgroundColor:'#e3f2fd', width:500,fontWeight:600, fontSize:15}}>Tours Details</TableCell>
                  <TableCell style={{backgroundColor:'#e3f2fd', width:0,fontWeight:600, fontSize:15}}><Button style={{ width:150, marginLeft: 350 }} variant="contained" color="primary" component={Link} to="/Tours" className={classes.button}>Back</Button></TableCell> 

                         

            </TableRow>

          </TableBody>
        </Table>

        <Table>
          <TableBody>
            <TableRow>

              <TableCell style={{ backgroundColor: '#f2f2f2', width: 700, fontWeight: 500, paddingTop: 25 }} component="th">

                <Paper className={classes.root} elevation={1} style={{ marginBottom: 0 }}>

                  <div style={{textAlign:'right', paddingBottom:15}}>
                      <Chip label={this.getStatus(this.state.status)}></Chip>
                  </div>                         

                <Divider/>
                <br />
                 
                <Typography variant="h6" component="h3">
                Basic
                  </Typography>           
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell style={{ backgroundColor: '#f2f2f2', width: 200, fontWeight: 600 }} component="th">Title</TableCell>
                                    <TableCell style={{ backgroundColor: '#f2f2f2', width: 200, fontWeight: 600 }} component="th">Tour Type</TableCell>
                                    <TableCell style={{ backgroundColor: '#f2f2f2', width: 200, fontWeight: 600 }} >Duration</TableCell>
                                    <TableCell style={{ backgroundColor: '#f2f2f2', width: 200, fontWeight: 600 }} >Categories</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{this.state.title}</TableCell>
                                    <TableCell>{this.getTripType(this.state.tour_type)}</TableCell>
                                    <TableCell> { this.state.duration_value} &nbsp; {this.getDuration(this.state.duration)} </TableCell>
                                    <TableCell> 30 </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>

        
        

        <Divider/>
                <br />
                 
      <Typography variant="h6" component="h3">
                      Overview
      </Typography>
      
      
      <Typography component="p">
        {this.state.overview}
      </Typography>
      <br />
      <Divider/>
                <br />
                <Typography variant="h6" component="h3">Pricing </Typography>
      
      
                        <div style={{ marginTop:15}}>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell style={{ backgroundColor: '#f2f2f2', width: 200, fontWeight: 600 }} component="th">Sale Type</TableCell>
                                    <TableCell style={{ backgroundColor: '#f2f2f2', width: 200, fontWeight: 600 }} component="th">Cut-off Time</TableCell>
                                    <TableCell style={{ backgroundColor: '#f2f2f2', width: 200, fontWeight: 600 }} >Pricing Basis</TableCell>
                                    
                                </TableRow>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell> { this.getPricingBasis(this.state.pricing_for) } </TableCell>                                    
                                </TableRow>
                            </TableBody>
                        </Table>
                        </div>
      
        <br />
                  

        <Typography color="textSecondary">
    Age Details:
</Typography>
<Table>
    <TableBody>
        <TableRow>
            <TableCell style={{ backgroundColor: '#f2f2f2', width: 200, fontWeight: 600 }} component="th">Adults</TableCell>
            <TableCell style={{ backgroundColor: '#f2f2f2', width: 200, fontWeight: 600 }} >Children</TableCell>
            <TableCell style={{ backgroundColor: '#f2f2f2', width: 200, fontWeight: 600 }} >Infants</TableCell>
        </TableRow>
        <TableRow>
            <TableCell>{this.state.adults_age_from+" - "+this.state.adults_age_to}</TableCell>
            <TableCell>{this.state.children_age_from+" - "+this.state.children_age_to}</TableCell>
            <TableCell>{this.state.infant_age_from+" - "+this.state.infant_age_to}</TableCell>
        </TableRow>
    </TableBody>
</Table>
<br />



<Typography variant="h6" component="h3">
    Schedules
</Typography>

<div style={{marginTop:25}}>
<SchedulePanel  shedules={this.state.schedules}></SchedulePanel>  
</div>


<Divider/>
                <br />
                 
                      <Typography variant="h6" component="h3">
                       Terms & Conditions
      </Typography>
      <br />
      <Typography color="textSecondary">Inclusion:</Typography>
      
      <Typography component="p">
      {this.state.inclusion} 
      </Typography>
      <br />

      <Typography color="textSecondary">Exclusion:</Typography>
      
      <Typography component="p">
      {this.state.exclision}
      </Typography>
      <br />
     
      <Typography color="textSecondary">Know before you go:</Typography>
      
      <Typography component="p">
      {this.state.know}
      </Typography>
      <br />

      <Typography color="textSecondary">Condition:</Typography>
      
      <Typography component="p">
      {this.state.condtions}
      </Typography>
      <br />

      <Typography color="textSecondary">Cancelation Policy:</Typography>
      
      <Typography component="p">
      
      </Typography>
      <br />

      
     <br />
      <Divider/>

                <br />
                 
                      <Typography variant="h6" component="h3">
                     Itinerary
      </Typography>
      <br />

       {this.state.itinery.map((it, index) => (

            <div>
              <Typography color="textSecondary">{it.label}</Typography>
            <Typography component="p">
            {it.summery} 
            </Typography>
            <br />
            </div>
            
             ))}   
      
      
      
      <br />
      
      <br />
      <Divider/>    
      <br />   
                 <Typography variant="h6" component="h3">
                  Photos
 </Typography>
 <br />

 
          <div>
          <GridList cellHeight={336} className={classes.gridList}>
              <GridListTile key="Subheader" cols={2} style={{ height: 'auto' }}>
                <ListSubheader component="div">Uploaded photos</ListSubheader>
              </GridListTile>
              {this.state.images.map(tile => (
                <GridListTile key={tile.id}>
                  <img src={tile.url} alt={tile.title} />
                  <GridListTileBar

                    actionIcon={
                      <IconButton aria-label={`info about ${tile.title}`} className={classes.icon}>
                        <InfoIcon />
                      </IconButton>
                    }
                  />
                </GridListTile>
              ))}
            </GridList>
          </div>
          <br />
          <Divider/>

<br />
 
      <Typography variant="h6" component="h3">
       Contact Details
</Typography>
<br />

<Typography component="p">
    {this.state.contact_number}                
 </Typography>

 <br/><br/>
 <Divider/>
 <br/> 
<TableRow>
 
<Button onClick={()=>{this.changeStatus(1);}} style={{ width:150, marginLeft: 0 }} variant="contained" color="primary" className={classes.button}>Accept</Button>
<Button onClick={()=>{this.changeStatus(2);}} style={{ width:150, marginLeft: 0 }} variant="contained" color="primary" className={classes.button}>Reject</Button>
<Button onClick={()=>{this.changeStatus(3);}} style={{ width:150, marginLeft: 0 }} variant="contained" color="third" className={classes.button}>Cancel</Button>
 

</TableRow>



                </Paper>

              </TableCell>
            </TableRow>
          </TableBody>
          <br />
        </Table>
        
      </div>
      </div>

    </Fragment>
  
  }
}

ToursDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(ToursDetails);