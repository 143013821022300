import React, { Props,Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DateAndTimePickers from '../Components/DateAndTimePickers';
import ViewTotalBookings from './Dialogs/ViewTotalBookings';
import Graph from '../Img/graph.png'
import Typography from '@material-ui/core/Typography';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';


import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CssBaseline from "@material-ui/core/CssBaseline";
import Pagination from "material-ui-flat-pagination";

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },


  table: {
    minWidth: 700,
 
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
});




let id = 0;
function createData(name, type,  actions) {
  id += 1;
  return { id, name, type, actions };
}

const rows = [
    createData('1','2'),
   
  ];

  class CustomizedTable extends Component{

    constructor(props){
      super(props);
  
      this.state = {
          viewOpen:false
      };
  }
  
  handleViewTr = ()=>{    
        this.setState({
            viewOpen:true,
        });
  }
  
  handleClose =()=>{
      this.setState({viewOpen:false});
  }
  
    render(){
  
      const { classes } = this.props;



  
  return (
    <Paper className={classes.root} elevation={1}  style={{marginBottom:30}}>
        <div style={{paddingRight:30, paddingTop:10, marginBottom:30}}>   
        
        
        </div>   

    <Table className={classes.table}>

<TableRow>
<TableBody width="100%">
{rows.map(row => (
<TableRow>
 <TableCell  align="left" style={{width:"299px",padding:10}}>{row.actions}
<Button color="#ffffff" className={classes.button}>
<Paper style={{padding:10, minWidth:200, height:90}}>
  <div align="left" style={{fontWeight:600, fontSize:15, color:'#000'}}> Total Income</div>
  <div align="left" style={{fontWeight:400, fontSize:25, color:'#3f51b5'}}>Rs: 2,500 000.00</div>
</Paper>
</Button>
</TableCell> 


<TableCell align="left" style={{padding:10}}>{row.actions}
<Button color="#ffffff" className={classes.button}>
<Paper style={{padding:10, minWidth:200, height:90}}>
  <div align="left" style={{fontWeight:600, fontSize:15, color:'#000'}}> Total Refund</div>
  <div align="left" style={{fontWeight:400, fontSize:25, color:'#3f51b5'}}>Rs: 500 000.00</div>
</Paper>
</Button>
</TableCell>

<TableCell align="left" style={{width:"299px",padding:10}}>{row.actions}
<Button color="#ffffff" className={classes.button}>
<Paper style={{padding:10, minWidth:200, height:90}}>
  <div align="left" style={{fontWeight:600, fontSize:15, color:'#000'}}> Total Profit</div>
  <div align="left" style={{fontWeight:400, fontSize:25, color:'#3f51b5'}}>Rs: 2,000 000.00</div>
</Paper>
</Button>
</TableCell>

</TableRow>
))}
</TableBody>



<TableBody>
{rows.map(row => (
<TableRow>
<TableCell align="left" style={{padding:10}}>{row.actions}
<Button color="#ffffff" className={classes.button}>
<Paper style={{padding:10, minWidth:200, height:90}}>
  <div align="left" style={{fontWeight:600, fontSize:15, color:'#000'}}> Total No of Suppliers</div>
  <div align="left" style={{fontWeight:400, fontSize:25, color:'#3f51b5'}}>Rs: 2,500 000.00</div>
</Paper>
</Button>
</TableCell>

<TableCell align="left" style={{padding:10}}>{row.actions}
<Button color="#ffffff" className={classes.button}>
<Paper style={{padding:10, minWidth:200, height:90}}>
  <div align="left" style={{fontWeight:600, fontSize:15, color:'#000'}}> Total Payable</div>
  <div align="left" style={{fontWeight:400, fontSize:25, color:'#3f51b5'}}>Rs: 500 000.00</div>
</Paper>
</Button>
</TableCell>

<TableCell align="left" style={{padding:10}}>{row.actions}

<Button size="small" color="secondary" onClick={this.handleViewTr} className={classes.button} >

<Paper style={{padding:10, minWidth:200, height:90}}>
  <div align="left" style={{fontWeight:600, fontSize:15, color:'#000'}}> Total No of Bookings</div>
  <div align="left" style={{fontWeight:400, fontSize:25, color:'#3f51b5'}}>Rs: 2,000 000.00</div>
</Paper>
</Button>
</TableCell>

</TableRow>
))}
</TableBody>
</TableRow>
</Table>
<ViewTotalBookings closeHandle={this.handleClose} open={this.state.viewOpen}></ViewTotalBookings>

<br />  <br />
<TableRow>
<img src={Graph} alt="Graph"/>

</TableRow>

<br />
<div style={{marginLeft:10}} className={classes.root} elevation={1} style={{ marginBottom: 0 }}>
        
<tableRow>     
<Typography style={{padding:20}} variant="h6" component="h3">Statistic </Typography></tableRow>
<tableRow>

  <TableCell style={{width:300}}><Typography align="left" > Total Refund/Total Income </Typography></TableCell>
  <TableCell style={{width:50}}><Typography component="p" color="primary">18%</Typography></TableCell>
  <br />
  <TableCell style={{width:300}}><Typography align="left" > Total Profit/Total Income </Typography></TableCell>
  <TableCell style={{width:50}}><Typography component="p" color="primary">20%</Typography></TableCell>

  <br />
  <TableCell style={{width:300}}><Typography align="left" > Total Refund/Total Profit </Typography></TableCell>
  <TableCell style={{width:50}}><Typography component="p" color="primary">8%</Typography></TableCell>

  <br />
  <TableCell style={{width:300}}><Typography align="left" > Average profit of a booking </Typography></TableCell>
  <TableCell style={{width:50}}><Typography component="p" color="primary">$125</Typography></TableCell>

  <br />
  <TableCell style={{width:300}}><Typography align="left" > Average income of a booking </Typography></TableCell>
  <TableCell style={{width:50}}><Typography component="p" color="primary">$18</Typography></TableCell>

  <br />
  <TableCell style={{width:300}}><Typography align="left" > Average income of a supplier </Typography></TableCell>
  <TableCell style={{width:50}}><Typography component="p" color="primary">$2000</Typography></TableCell>

</tableRow>
<br />
<br />
 </div>



</Paper>
    
    );
  }
}


CustomizedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomizedTable);