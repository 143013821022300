import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import TextField from '@material-ui/core/TextField';

class TermsBox extends React.Component {

    taRef = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            editMode: false,
            selectedPolicy:null,
            updated:false,
        }
    }


    static getDerivedStateFromProps(props, state) {
        
        
            if( state.updated === false){
                return {
                    editMode: props.seleted === null,                    
                    updated:true,
                }
            }
        
    }

    handleEditMode = () =>{
        this.setState({
            editMode:true
        });
    }

    handlePolicySelect = (e) =>{
        this.setState({
            selectedPolicy:e.target.value
        },()=>this.props.policyChange(this.state.selectedPolicy));
    }


    handleApply =()=>{

        if(this.props.required === true){
            if(this.props.value === null){
                this.props.onError(true,this.props.label+" can not be empty",'error');
                return false;
            }

            if(this.props.value.length === 0){
                this.props.onError(true,this.props.label+" can not be empty",'error');
                return false;
            }
        }

        

        this.setState({
            editMode:false
        });
    }

    previewPolicy = (id) => {
        for(var x=0; x < this.props.templates.length; x++){
            if(this.props.templates[x].id.toString() === id){
                return this.props.templates[x].content;
            }
        }
    }

    auto_grow = (element) => {
        if( this.taRef.current !== null){
            this.taRef.current.style.height = "5px";
            this.taRef.current.style.height = (this.taRef.current.scrollHeight)+"px";
        }
       
     }

     componentDidMount=()=>{
        this.auto_grow();
      }

      componentDidUpdate = () =>{
            this.auto_grow();
      }

    render() {

    
        
        return <div>
            {this.state.editMode === false ? (<div className="supplier-block terms-conditions-details">
                <a onClick={()=>this.handleEditMode()} className="edit-block"></a>
                <div className="block-content">
                    <div className="inner-block-content">
                        <div className="one-full-col ">
                            <div className="supply-col">
                                <label>{this.props.label}</label>
                                <div className="col-variable">
                                    <div className="inner-description" style={{whiteSpace:'pre-wrap'}}>
                                        {this.props.seleted !== undefined &&  this.props.seleted !== ""  ? (<div>
                                            { this.props.seleted}<br/><br/>
                                        </div>) : null}
                                        
                                        {this.props.value}
                          </div>
                                </div>
                            </div>
                        </div>
                        <div className="clear"></div>
                    </div>
                </div>

            </div>
            ) : (<div className="supplier-block terms-conditions-details editable">               
                <div className="block-content">
                    <div className="inner-block-content">
                        <div className="one-full-col ">
                            <div className="supply-col">
                                <label>{this.props.label}</label>
                                <div className="col-variable">
                                    <div className="inner-description">
                                        { this.props.templates !== undefined ? (
                                            <table>
                                                <tr>
                                                     <td style={{padding:5,width:'30%',verticleAlign:'top'}}>
                                                    <FormControl style={{ minWidth: 200, marginTop: 16 }} variant="outlined" >
                                            <InputLabel
                                              ref={ref => {
                                                this.InputLabelRef = ref;
                                              }}
                                              htmlFor="outlined-age-native-simple"
                                            >
                                              Select Policy
                          </InputLabel>
                                            <Select
                                              native
                                               onChange={this.handlePolicySelect}             
                                              input={
                                                <OutlinedInput
                                                  name="Tour Type"
                                                  labelWidth={70}
                                                  id="outlined-age-native-simple"
                                                />
                                              }
                                            >

                                              <option value={0} />

                                              { this.props.templates.map((pol,index)=>{
                                                  return <option value={pol.id} >{pol.title}</option>
                                              }) }  
                                              

                                             
                                              
                                              
                                              
                                            </Select>
                                          </FormControl>
                                                    </td>
                                                    <td style={{padding:5,width:'70%',verticleAlign:'top'}}>
                                                            {this.state.selectedPolicy !== null ? this.previewPolicy(this.state.selectedPolicy):this.props.seleted}
                                                    </td>
                                                </tr>
                                            </table>
                                        ):null}

                                        {this.props.fieldType === 'textarea' && this.props.hideTextArea === false ? (<textarea ref={this.taRef} className="textarea-inner-description" onChange={this.props.onChange} value={this.props.value}></textarea>):null }      
                                        {this.props.fieldType === 'phone' ? (<tr><td  style={{fontSize:17,verticalAlign:'middle'}} >+94</td><td><TextField variant="outlined" type='text' onChange={this.props.onChange} value={this.props.value}></TextField></td></tr>):null }

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="clear"></div>
                        <div className="button-container">
                                 { this.props.showDone === true ? (<a onClick={()=>this.handleApply()} className="button-apply">Done</a>) : null}
                        </div>
                    </div>
                </div>
                
            </div>)}
        </div>
    }
}

TermsBox.defaultProps ={
    showDone:true,
    fieldType:'textarea',
    hideTextArea:false
}

export default TermsBox;