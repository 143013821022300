import React, { Props,Component, Fragment } from 'react';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CssBaseline from "@material-ui/core/CssBaseline";
import Pagination from "material-ui-flat-pagination";
import CardActions from '@material-ui/core/CardActions';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ViewTransactions from './Dialogs/ViewTransactions';


const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);




const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
});

let id = 0;
function createData(name, type, duration, location, status, status2, actions) {
  id += 1;
  return { id, name, type, duration, location, status, status2, actions };
}




const rows = [
  createData('221','Emcee Travels', '20,500','2,000','16,600','2,000'),
  createData('222','Emcee Travels', '20,500','2,000','16,600','2,000'),
  createData('223','Emcee Travels', '20,500','2,000','16,600','2,000'),
  createData('224','Emcee Travels', '20,500','2,000','16,600','2,000'),
  createData('225','Emcee Travels', '20,500','2,000','16,600','2,000'),

];



class CustomizedTable extends Component{

  constructor(props){
    super(props);

    this.state = {
        viewOpen:false
    };
}

handleViewTr = ()=>{    
      this.setState({
          viewOpen:true,
      });
}

handleClose =()=>{
    this.setState({viewOpen:false});
}

  render(){

    const { classes } = this.props;

    return (
      <Paper className={classes.root} elevation={1} style={{ marginBottom: 30 }}>
        <div style={{ paddingRight: 30, paddingTop: 10 }}>
          <Table style={{ textAlign: "right" }} className={classes.table} >
          <TextField
            id="outlined-bare"
            className={classes.textField}
            defaultValue="Search"
            margin="normal"
            variant="outlined"
          />
       
  
         
          <FormControl style={{ marginLeft: 30}} className={classes.formControl}>
            <InputLabel htmlFor="age-native-simple">Filter</InputLabel>
            <Select
              native
              inputProps={{
                name: 'age',
                id: 'age-native-simple',
              }}
            >
              <option value="" />
              <option value={2019}>2019</option>
              <option value={2018}>2018</option>
              <option value={2017}>2017</option>
            </Select>
          </FormControl>
          </Table>
        </div>
  
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell><p style={{width:45, fontWeight:600, fontSize:15, color:'#000'}}>Sup Id</p></TableCell>
              <TableCell align="left"><p style={{width:100, fontWeight:600, fontSize:15, color:'#000'}}>Name</p></TableCell>
              <TableCell align="left"><p style={{fontWeight:600, fontSize:15, color:'#000'}}>Credit</p></TableCell>
              <TableCell align="left"><p style={{width:75,fontWeight:600, fontSize:15, color:'#000'}}>Commission </p></TableCell>
              <TableCell align="left"><p style={{fontWeight:600, fontSize:15, color:'#000'}}>Debet</p></TableCell>
              <TableCell align="left"><p style={{fontWeight:600, fontSize:15, color:'#000'}}>Balance</p></TableCell>
              <TableCell align="right"><p style={{fontWeight:600, fontSize:15, color:'#000'}}>Actions</p></TableCell>
  
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="left">{row.type}</TableCell>
                <TableCell align="left">{row.duration}</TableCell>
                <TableCell align="left">{row.location}</TableCell>
                <TableCell align="left">{row.status}</TableCell>
                <TableCell align="left">{row.status}</TableCell>
                <TableCell align="left">{row.actions}
                
                <CardActions style={{textAlign:'right'}}>
          <Button size="small" color='primary' component={Link} to=" " className={classes.button} >Pay</Button>
         
          <Button size="small" color="secondary" onClick={this.handleViewTr} className={classes.button} >View
          </Button>
                  
  
          <Button size="small" color="default" component={Link} to=" " className={classes.button} >Postpone</Button>
          
        </CardActions>
              
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
  
      
  
        <div style={{ textAlign: 'right', backgroundColor: '#fff', height: '30' }} >
          <CssBaseline />
          <Pagination
            limit={10}
            total={100}
          />
        </div>
        <ViewTransactions closeHandle={this.handleClose} open={this.state.viewOpen}></ViewTransactions>
      </Paper>
  
    );
  }
}


CustomizedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomizedTable);