import {API_CLIENT_ID, API_CLIENT_SECRET, API_URL, API_GRANT_TYPE} from './Config';
import {startLoading,endLoading,SnackBar,handleHTTPError} from './CommonActions';
import store from '../store';


export function fetchLocations(){

    return function (dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/api/locations/get',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',              
            }
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                    dispatch(storeLocations(json));                  
                    dispatch(endLoading());                                                      
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Error loading locations','error'));
        });
        
    }
}


export function storeLocations(locations){
    return{type:'STORE_LOCATIONS',locations:locations};
}


export function AddLocation(data) {

  return function(dispatch) {
      dispatch(startLoading());
      fetch(API_URL+'/api/admin/locations/add',{
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
          },
          body:JSON.stringify(data),
      }).then(handleHTTPError)
      .then((response)=>{
            dispatch(endLoading());
            response.json().then((json)=>{
                dispatch(endLoading());
                dispatch(fetchLocations()); 
                dispatch(SnackBar(true,'Category Added','success'));                                    
            });
      }).catch((error)=>{              
        dispatch(endLoading());            
        dispatch(SnackBar(true,'Could not save data','error'));
      });
  }   

}

export function UpdateLocation(data) {

  return function(dispatch) {
      dispatch(startLoading());
      fetch(API_URL+'/api/admin/locations/update',{
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
          },
          body:JSON.stringify(data),
      }).then(handleHTTPError)
      .then((response)=>{
            dispatch(endLoading());
            response.json().then((json)=>{
                dispatch(endLoading());
                dispatch(fetchLocations()); 
                dispatch(SnackBar(true,'Category updated','success'));                                    
            });
      }).catch((error)=>{              
        dispatch(endLoading());            
        dispatch(SnackBar(true,'Could not save data','error'));
      });
  }   

}


export function DeleteLocation(id) {

  return function(dispatch) {
      dispatch(startLoading());
      fetch(API_URL+'/api/admin/locations/delete?id='+id,{
          method: 'GET',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
          },            
      }).then(handleHTTPError)
      .then((response)=>{
            dispatch(endLoading());
            response.json().then((json)=>{
                dispatch(endLoading());
                dispatch(fetchLocations()); 
                dispatch(SnackBar(true,'Category deleted','success'));                                    
            });
      }).catch((error)=>{              
        dispatch(endLoading());            
        dispatch(SnackBar(true,'Could not save data','error'));
      });
  }
}