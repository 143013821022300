import {API_CLIENT_ID, API_CLIENT_SECRET, API_URL, API_GRANT_TYPE} from './Config';
import {startLoading,endLoading,SnackBar,handleHTTPError} from './CommonActions';
import store from '../store';
import axios from 'axios';

export function GetSiteSettings(){

    return function (dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/api/settings/site/get',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            }
        }).then(response => response.json(), error => console.log('An error occurred.', error))
          .then((json) =>{
                  dispatch(StoreSiteSettings(json));
                  dispatch(endLoading());
          });
    }
}


export function StoreSiteSettings(config){
    return{type:'STORE_SITE_SETTINGS',settings:config};
}


export function UpdateSiteSettings(data) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/settings/site/update',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            body:JSON.stringify(data),
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(GetSiteSettings());
                  dispatch(SnackBar(true,'Site updated','success'));
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not save data','error'));
        });
    }

}

export function UpdateSiteCommission(data) {

  return function(dispatch) {
      dispatch(startLoading());
      fetch(API_URL+'/api/settings/site/update/commission',{
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
          },
          body:JSON.stringify(data),
      }).then(handleHTTPError)
      .then((response)=>{
            dispatch(endLoading());
            response.json().then((json)=>{
                dispatch(endLoading());
                dispatch(GetSiteSettings());
                dispatch(SnackBar(true,'Site updated','success'));
            });
      }).catch((error)=>{
        dispatch(endLoading());
        dispatch(SnackBar(true,'Could not save data','error'));
      });
  }

}


export function GetSiteContent(){

    return function (dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/api/content/site/get',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            }
        }).then(response => response.json(), error => console.log('An error occurred.', error))
          .then((json) =>{
                  dispatch(StoreSiteContent(json));
                  dispatch(endLoading());
          });
    }
}


export function StoreSiteContent(config){
    return{type:'STORE_SITE_CONTENT',settings:config};
}


export function UpdateSiteContent(data) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/content/site/update',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            body:JSON.stringify(data),
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(GetSiteSettings());
                  dispatch(SnackBar(true,'Site updated','success'));
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not save data','error'));
        });
    }

}



export function FetchSliders(){

    return function (dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/api/sliders/get',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            }
        }).then(response => response.json(), error => console.log('An error occurred.', error))
          .then((json) =>{
                  dispatch(StoreSliders(json));
                  dispatch(endLoading());
          });
    }
}


export function StoreSliders(list){
    return{type:'STORE_SLIDERS',slides:list};
}


export function AddSlider(data) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/admin/sliders/add',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            body:JSON.stringify(data),
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(FetchSliders());
                  dispatch(SnackBar(true,'Slide Added','success'));
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not save data','error'));
        });
    }

}

export function UpdateSlider(data) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/admin/sliders/update',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            body:JSON.stringify(data),
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(FetchSliders());
                  dispatch(SnackBar(true,'Slide updated','success'));
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not save data','error'));
        });
    }

}


export function DeleteSlider(id) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/admin/sliders/delete?id='+id,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(FetchSliders());
                  dispatch(SnackBar(true,'Slide deleted','success'));
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not save data','error'));
        });
    }

}


export function UploadSlide(data){

    return function(dispatch) {
        dispatch(SnackBar(true,'Uploading image, please wait...','info'));

        var fd = new FormData();

        if(data.uploadImage !== null){
          fd.append('file',data.uploadImage,data.uploadImage.name);
        }
        fd.append('id',data.id);


        axios.post(API_URL+'/api/admin/sliders/upload',fd,{
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
        }).then((response)=>{
              dispatch(endLoading());
              dispatch(FetchSliders());
              dispatch(SnackBar(true,'Image uploaded','success'));
        }).catch((error)=>{
            console.log(error)
          dispatch(endLoading());
          dispatch(SnackBar(true,'Data saving error','error'));
        });
    }
  }


  export function FetchFeaturedCat(){

    return function (dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/api/featured/categories/get',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            }
        }).then(response => response.json(), error => console.log('An error occurred.', error))
          .then((json) =>{
                  dispatch(StoreFeaturedCat(json));
                  dispatch(endLoading());
          });
    }
}


export function StoreFeaturedCat(list){
    return{type:'STORE_FEATURED_CATS',cats:list};
}


export function AddFeaturedCat(data) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/admin/featured/categories/add',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            body:JSON.stringify(data),
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(FetchFeaturedCat());
                  dispatch(SnackBar(true,'Slide Added','success'));
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not save data','error'));
        });
    }

}

export function UpdateFeaturedCat(data) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/admin/featured/categories/update',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            body:JSON.stringify(data),
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(FetchFeaturedCat());
                  dispatch(SnackBar(true,'Slide updated','success'));
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not save data','error'));
        });
    }

}


export function DeleteFeaturedCat(id) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/admin/featured/categories/delete?id='+id,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(FetchFeaturedCat());
                  dispatch(SnackBar(true,'Slide deleted','success'));
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not save data','error'));
        });
    }

}


export function UploadFeaturedCatImage(data){

    return function(dispatch) {
        dispatch(SnackBar(true,'Uploading image, please wait...','info'));

        var fd = new FormData();

        if(data.uploadImage !== null){
          fd.append('file',data.uploadImage,data.uploadImage.name);
        }
        fd.append('id',data.id);


        axios.post(API_URL+'/api/admin/featured/categories/upload',fd,{
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
        }).then((response)=>{
              dispatch(endLoading());
              dispatch(FetchFeaturedCat());
              dispatch(SnackBar(true,'Image uploaded','success'));
        }).catch((error)=>{
            console.log(error)
          dispatch(endLoading());
          dispatch(SnackBar(true,'Data saving error','error'));
        });
    }
  }



  export function FetchPolicies(){

    return function (dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/api/settings/site/policy/get',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            }
        }).then(response => response.json(), error => console.log('An error occurred.', error))
          .then((json) =>{
                  dispatch(StorePolicies(json));
                  dispatch(endLoading());
          });
    }
}


export function StorePolicies(list){
    return{type:'STORE_POLICIES',policies:list};
}


export function AddPolicy(data) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/admin/settings/site/policy/add',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            body:JSON.stringify(data),
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(FetchPolicies());
                  dispatch(SnackBar(true,'Slide Added','success'));
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not save data','error'));
        });
    }

}

export function UpdatePolicy(data) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/admin/settings/site/policy/update',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            body:JSON.stringify(data),
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(FetchPolicies());
                  dispatch(SnackBar(true,'Slide updated','success'));
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not save data','error'));
        });
    }

}


export function DeletePolicy(id) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/admin/settings/site/policy/delete?id='+id,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(FetchPolicies());
                  dispatch(SnackBar(true,'Slide deleted','success'));
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not save data','error'));
        });
    }

}

export function FetchPending(){

  return function (dispatch){
      dispatch(startLoading());
      fetch(API_URL+'/api/admin/settings/pending',{
          method: 'GET',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
          }
      }).then(response => response.json(), error => console.log('An error occurred.', error))
        .then((json) =>{
                dispatch(StorePending(json));
                dispatch(endLoading());
        });
  }
}


export function StorePending(list){
  return{type:'STORE_PENDING',pending:list};
}

export function fetchTags(){

    return function (dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/api/admin/settings/tags',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            }
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                    dispatch(storeTags(json));
                    dispatch(endLoading());
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Error loading locations','error'));
        });

    }
}


export function storeTags(tags){
    return{type:'STORE_TAGS',tags:tags};
}
