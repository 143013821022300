import React, { Props, Component, Fragment } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import Drawer from './Common/Drawer' 
import SearchSelect from '../Components/TagInput';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InputLabel from '@material-ui/core/InputLabel';
import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { compose } from 'redux'; 
import { connect } from 'react-redux';

import AppBar from './Common/MainAppBar';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Label from '@material-ui/icons/Label';

import { fetchCategories } from '../Actions/CategoryAction';
import { GetSuppliers } from '../Actions/SupplierActions';
import { Redirect } from 'react-router-dom'

import logo from '../Img/logo.png'
import Profile from '../Img/profile.png'
import Loader from './Common/Loader';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Pagination from 'material-ui-flat-pagination';
import { Divider, Chip } from '@material-ui/core';

const drawerWidth = 240;

const styles = theme => ({

  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },

  button: {
    display: 'block',
    marginTop: theme.spacing.unit * 2,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },

  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },

  fab: {
    margin: theme.spacing.unit,
  },

  extendedIcon: {
    marginRight: theme.spacing.unit,
  },

  wrap: {
    width: 480,
    margin: 'auto',
    marginTop: 100,

  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,

  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '98%'
  },

  button: {
    margin: theme.spacing.unit,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },

  logoWrap: {
    textAlign: 'center',
  },
  toolbar: theme.mixins.toolbar,
});




const mapStateToProps = (state) => {
  return {
    loading: state.Common.loading,
    loggedIn: state.Auth.loggedIn,
    suppliers: state.Suppliers.list,
    categories: state.Categories.categories,
  }
}

let id = 0;
function createData(name, name2, type, status, actions) {
  id += 1;
  return { id, name, name2, type, status, actions };
}


const mapDispatchToProps = dispatch => ({
  
  GetSuppliers: (suplyr,kw,ctgry,stus) => dispatch(GetSuppliers(suplyr,kw,ctgry,stus)),
   fetchCategories: () => dispatch(fetchCategories()),
});


// const getStatus = (status) => {
//   switch (status) {
//     case 0: { return "Pending" }//gray
//     case 1: { return "Approved" }//green
//     case 2: { return "In Review" } //yellow
//     case 3: { return "Banned" }//red

//   }
// }


const getStatus = (status) => {
  switch (status) {
    case 0: { return "Pending" } //gray
        case 1: { return "Approved" } //green
        case 3: { return "In review" } //yellow
        case 4: { return "Banned" }// red 
        case 2: { return "Rejected" }// black

  }
}
// const StatusStyle = (status) => {
//   switch (status) {
//     case 0: { return { backgroundColor: '#90A4AE', color: '#FFF' } } //gray #90A4AE
//     case 1: { return { backgroundColor: '#4CAF50', color: '#FFF' } } //green #4CAF50                  
//     case 2: { return { backgroundColor: '#FFEB3B', color: '#FFF' } } //yellow #FFEB3B
//     case 3: { return { backgroundColor: '#f44336', color: '#FFF' } } //red #f44336
   
//   }
// }

const StatusStyle = (status) => {
  switch (status) {
    case 0: { return { backgroundColor: '#90A4AE', color: '#FFF' } } //gray #90A4AE
    case 1: { return { backgroundColor: '#4CAF50', color: '#FFF' } } //green #4CAF50                  
    case 3: { return { backgroundColor: '#FFEB3B', color: '#000' } } //yellow #FFEB3B
    case 4: { return { backgroundColor: '#f44336', color: '#FFF' } } //red #f44336
    case 2: { return { backgroundColor: '#1a1818', color: '#FFF' } } //black #f44336
   
  }
}

const StatusChip = (status) => {
  return <div><Chip size="small" style={{ width:'3%'}}  style={StatusStyle(status)} label={getStatus(status)}></Chip></div> 
}



class Suppliers extends Component {

  constructor(props) {
    super(props);

    this.state = {
      age: '',
      name: 'hai',
      labelWidth: 0,
      keyword:null,
      tourtype:null,
      tourstatus:null,
     
      supplier:null,
    };


    this.props.GetSuppliers(this.state.supplier,this.state.keyword,this.state.category,this.state.tourstatus);
    this.props.fetchCategories();
  }

  componentDidMount() {
    document.body.style.background = "#eee";

  }

  StatusStyle = (status) => {
    switch (status) {
      case 0: { return { backgroundColor: '#90A4AE', color: '#FFF' } } //gray #90A4AE
      case 1: { return { backgroundColor: '#4CAF50', color: '#FFF' } } //green #4CAF50                  
      case 3: { return { backgroundColor: '#FFEB3B', color: '#FFF' } } //yellow #FFEB3B
      case 4: { return { backgroundColor: '#f44336', color: '#FFF' } } //red #f44336
      case 2: { return { backgroundColor: '#1a1818', color: '#FFF' } } //black #f44336
    }
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });

  };


  /*getStatus(status){
    switch(status){
      case 0:
          return "Pending";
      case 1:
          return "Active";
      case 2:
          return "Rejected";
      case 3:
            return "Banned";    
      default:
          return "Pending";    
    }
  }*/ 

  // getStatus = (status) => {
  //     switch (status) {
  //       case 0: { return "Draft" }
  //       case 1: { return "Confirmed" }
  //       case 2: { return "Rejected" }
  //       case 3: { return "Canceled" }
  //       case 4: { return "Completed" }
  //       case 5: { return "Dispute" }
  //     }
  //   }

    getStatus = (status) => {
      switch (status) {
        case 0: { return "Pending" } //gray
        case 1: { return "Approved" } //green
        case 3: { return "In review" } //yellow
        case 4: { return "Banned" }// red 
        case 2: { return "Rejected" }// black
   
       }
     }


  
    getDuration(measure){        
      switch(measure){
        case 1 :{ return "Days" }
        case 2 :{ return "Hours" }
        case 3 :{ return "Minutes" }
      }
    }

    selectSupplier = (value) => {
      if(value === null){
        this.setState({
          supplier:null
      },()=>this.searchTour());
      }else{
        this.setState({
          supplier:value.id
      },()=>this.searchTour());
      }
     
    }

    selectkeyword = (e) =>{
      this.setState({
        keyword:e.target.value
      },()=>this.searchTour())
  }

    selectCategory = (e) =>{
      this.setState({
        category:e.target.value
      },()=>this.searchTour())
  }
 
  selecttourStatus = (e) =>{
    this.setState({
      tourstatus:e.target.value
    },()=>this.searchTour())
}
  
  
    searchTour=()=>{
      
      this.props.GetSuppliers( this.state.supplier, this.state.category, this.state.tourstatus, this.state.keyword);
  }


  // searchTour = () =>{
  //   this.props.GetSuppliers({category: this.state.category,
  //     });
  // }


  render() {

    const { classes } = this.props;

    
    return <Fragment>

      <AppBar title="Dashboard"></AppBar>


      <Drawer></Drawer>


      <div style={{ width: 'auto', marginLeft: drawerWidth + 0, paddingTop: 35, height: 250 }}><div>


        <Table>

          <TableBody>
            <TableRow>
              <TableCell style={{backgroundColor: 'white', width: 500, fontWeight: 600, fontSize: 16 }}>Suppliers</TableCell>

            </TableRow>

          </TableBody>
        </Table>

        <Table>
          <TableBody>
            <TableRow>


              <TableCell style={{ backgroundColor: '#f2f2f2', width: 700, fontWeight: 500, paddingTop: 25 }} >

                <Paper className={classes.root} elevation={1} >

  <div className="tab-pan">
<div className="tab-bok">
        <div float='right'>
                <Table align="right"  style={{width:"30%", textAlign: "right", alignContent:'right', alignSelf:'center', float:'right'}} className={classes.table}  >
                                                
                    <tr align="right">


                    <td alighn="center" style={{width:"27%"}}>
                                 <FormControl style={{ width: 240 }}>

                                  <SearchSelect
                                    options={this.props.suppliers}
                                    getOptionLabel={option => `${option.work_name}`}
                                    getOptionValue={option => `${option.id}`}
                                    value={this.state.supplier}
                                    placeholder="Supplier"
                                    onSelect={this.selectSupplier} 
                                    onChange={this.handleChange('supplier')}
                                    />
                                    </FormControl>
                                    </td>
                    {/* <td align="right" style={{width:"30%"}}>

                    <FormControl className={classes.formControl}>
                      <TextField
                      label='Keyword'
                     value={this.state.keyword}
                      onChange={this.selectkeyword}  
                      >
                     
                      </TextField>
                      </FormControl>

                    </td> */}

                    <td align="right" style={{width:"20%"}} >

                     

                        <FormControl className={classes.formControl}>
                          <InputLabel htmlFor="status-native-simple">Catogery</InputLabel>
                          <Select
                            native
                            value={this.state.category}
                            onChange={this.selectCategory}
                            inputProps={{
                              name: 'status',
                              id: 'status-native-simple',
                            }}
                          >
                            <option value="" />
                            
                            { this.props.categories.map((item)=>{
                                  return <option value={item.id}>{item.name}</option>; 
                            }) }
                            
                          </Select>
                        </FormControl>

                    </td>

                    <td alighn="right" style={{width:"20%"}} >

                    <FormControl className={classes.formControl}>
                          <InputLabel htmlFor="status-native-simple"> Status </InputLabel>
                          <Select
                                native

                                
                                //onChange={this.handleChange('status')}
                                onChange={this.selecttourStatus}

                               
                                inputProps={{
                                  name: 'status',
                                  id: 'status-native-simple',
                                }}
                              >
                          
                               <option value="" />
                               <option value="0">Pending</option>
                                <option value="1">Approved</option>
                                <option value="2">Rejected</option>
                                <option value="3">In Review</option>
                                <option value="4">Banned</option>
                         

                               
                              </Select>
                        </FormControl>

                    </td>


                    </tr>


                </Table>

                </div>



               <Table style={{ textAlign: "right" }} className={classes.table} >
                 {/* <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="status-native-simple">Filter</InputLabel>
                    <Select
                      native
                      value={this.state.age}
                      onChange={this.handleChange('status')}
                      inputProps={{
                        name: 'status',
                        id: 'status-native-simple',
                      }}
                    >
                      <option value="" />
                      <option value={1}>Pending</option>
                      <option value={2}>Accept</option>
                      <option value={3}>Reject</option>
                      <option value={4}>Cancel</option>
                    </Select>
                  </FormControl> */}
                  </Table>

                
                  <Table className={classes.table}> 
                    
                      <tr>
                       
                        <th style={{width:"5%" }}><p >#</p></th>
                        <th style={{width:"18%" }}><p >Supplier Name</p></th>
                        <th style={{width:"16%" }}><p >Contact Person</p></th> 
                        <th style={{width:"14%" }}><p  >Commission (%)</p></th>
                        <th style={{width:"20%" }}><p >Category</p></th>                        
                        <th ><p >Status</p></th>
                        <th style={{width:"3%" }} align="right"><p ></p></th>

                      </tr>
                    

                    
                      {this.props.suppliers!== null ? this.props.suppliers.map((row,index) => (
                        <tr key={row.id}>
                          
                          <td  scope="row" >{index+1}</td>
                          <td  scope="row">{row.work_name} <br/> <small> ID : {row.sid}</small> </td>
                          <td  scope="row"> {row.contact_name} <br/>
                          <small> {row.contact_mobile_phone}</small>
                           </td>
                          <td  scope="row"> {row.sales_commission_rate} </td>
                          {/* <td  scope="row"> {row.id} </td>  */}
                          <td>{row.category[0]!==undefined ? row.category[0].category.name :null } { row.category.length > 1 ? (<em>+{row.category.length-1} more</em>) : null } </td>                         
                          <td align="right" style={{width:"2%"}}>{StatusChip(row.status)}</td> 
                          <td align="right" style={{width:"2%"}}>
                            <Button variant="contained" color="primary" style={{background:"#f7a414", textAlign:'center'}} size='small' component={Link} to={"/UsersDetails/"+row.id} className={classes.button}> <b>View</b> </Button>
                          </td>
                        </tr>
                     )) : null}
                    </Table>
                    
                  
                           

                 

                  </div> </div>
                </Paper>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
      </div>
    </Fragment>
  }
}

Suppliers.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Suppliers);