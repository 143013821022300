import React from 'react';



class NewStaticTextBox extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            editMode: false,
            value:""
        }
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            value:nextProps.value
        })
    }


    handleEditMode = () =>{
        this.setState({
            editMode:true
        });
    }

    onChange = (event)=>{
        
        this.setState({
            value:event.target.value
        })
    }


    handleApply =()=>{

        if(this.props.validate !== null){
            if(this.props.validate === 'phone'){
                var phoneno = /^[\d\.\-]+$/;
                if(!phoneno.test(this.state.value)){
                    this.props.onError(true,this.props.label+" number is invalid",'error');
                    return false;
                }
            }
        }

        if(this.props.required === true){
            if(this.props.value === null){
                this.props.onError(true,this.props.label+" can not be empty",'error');
                return false;
            }

            if(this.props.value.length === 0){
                this.props.onError(true,this.props.label+" can not be empty",'error');
                return false;
            }
        }

        if(this.props.onOK !== null){
            this.props.onOK(this.state.value);
        }

        this.setState({
            editMode:false
        });
    }

    render() {

    
        
        return <div>
            {this.state.editMode === false ? (
            <div className="profrm-row">
            <label>{this.props.label}</label>
            <input disabled={true}   type="text" value={this.state.value} style={{backgroundColor:'rgba(202, 201, 201, 0.21)'}} name=""/>
            <span onClick={()=>this.handleEditMode()}></span>
          </div>
            
            ) : (
            
              <div className="profrm-row active">
                <label>{this.props.label}</label>
                {this.props.type === 'text' ? <input onChange={this.onChange} type="text" value={this.state.value} name=""/> : null}
                {this.props.type === 'password' ? <input onChange={this.onChange} type="password" value={this.state.value} name=""/> : null}
                <span onClick={()=>this.handleApply()}></span>
              </div>
            )}

            {this.props.verified !== false && this.props.verified === 0 ? <span style={{display:'block',marginTop:-15,fontSize:11}}>Unverified</span>:null}
            {this.props.verified !== false && this.props.verified === 1 ? <span style={{display:'block',marginTop:-15,fontSize:11}}>Verified</span>:null}    
            {this.props.review === true && this.props.rejected === false ? (<div style={{fontSize:12,display:'block',marginTop:-15,marginBottom:15}}>
            <a style={{color:'green',cursor:'pointer'}} onClick={()=>this.props.reviewAction(1,this.props.field)} >Approve</a>&nbsp;&nbsp;<a style={{color:'red',cursor:'pointer'}} onClick={()=>this.props.reviewAction(0,this.props.field)} >Reject</a>
            </div>):null}
            {this.props.rejected === true ? (<div style={{fontSize:12,display:'block',marginTop:-15,marginBottom:15}}>
            <a style={{color:'green',cursor:'pointer'}} onClick={()=>this.props.reviewAction(1,this.props.field)} >Approve</a>
            </div>):null}
            
            
        </div>
    }
}

NewStaticTextBox.defaultProps ={
    type:'text',
    review:false,
    validate:null,
    onOK:null,
    verified:false,
    rejected:false    
}

export default NewStaticTextBox;