import React, { Props, Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';


//import FormControl from '@material-ui/core/FormControl';
//import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import Input from '@material-ui/core/Input';
import OutlinedInput from '@material-ui/core/OutlinedInput';
//import InputLabel from '@material-ui/core/InputLabel';

// site content subpanals
import Span from '../Components/siteContpanal';

import SSlider from '../Components/siteSlider';

import { compose } from 'redux';
import { connect } from 'react-redux';

import { GetSiteSettings,UpdateSiteSettings,UpdateSiteCommission } from '../Actions/SettingsAction';
import { fetchCategories } from '../Actions/CategoryAction';


const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },


  table: {
    minWidth: 700,

  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
});




let id = 0;
function createData(name, type, actions) {
  id += 1;
  return { id, name, type, actions };
}

const rows = [
  createData('1', '2'),

];

const mapStateToProps = (state) => {
  return {
      loading : state.Common.loading,
      loggedIn : state.Auth.loggedIn,
      categories: state.Categories.categories,
      site_title: state.Common.site_title,
      site_description: state.Common.site_description,
      site_keywords: state.Common.site_keywords,
      site_featured: state.Common.site_featured,
      commission: state.Common.commission,
  }
}
const mapDispatchToProps = dispatch => ({
  GetSiteSettings: () => dispatch(GetSiteSettings()),
  fetchCategories:()=>dispatch(fetchCategories()),
  UpdateSiteSettings:(data)=>dispatch(UpdateSiteSettings(data)),
  UpdateSiteCommission:(data)=>dispatch(UpdateSiteCommission(data))
});


class CustomizedTable extends Component {

  constructor(props) {
    super(props);

    this.state = {
      viewOpen: false,
      site_title: this.props.site_title,
      site_description: this.props.site_description,
      site_keywords: this.props.site_keywords,
      site_featured: this.props.site_featured,
      commission:this.props.commission ,
    };

    this.props.fetchCategories();
    this.props.GetSiteSettings();
  }


  componentWillReceiveProps = (nextProps) =>{
      this.setState({
                      site_title: nextProps.site_title,
                      site_description: nextProps.site_description,
                      site_keywords: nextProps.site_keywords,
                      site_featured: nextProps.site_featured,
                      commission:nextProps.commission ,
                    });
  }


  handleViewTr = () => {
    this.setState({
      viewOpen: true,
    });
  }

  handleClose = () => {
    this.setState({ viewOpen: false });
  }

  handleChange = (e,field) =>{
    this.setState({
      [field]:e.target.value
    })
  }

  handleUpdateSettings = () =>{
      this.props.UpdateSiteSettings({
                                        title:this.state.site_title,
                                        description:this.state.site_description,
                                        keywords:this.state.site_keywords,
                                        featured:this.state.site_featured
                                    });
  }

  handleCommissionUpdate = () =>{
      this.props.UpdateSiteCommission({c:this.state.commission});
  }

  render() {

    const { classes } = this.props;




    return (
     <Fragment>
        
          <table >

            <tr>
              <td> <label htmlFor=""> <h4> Site Title </h4> </label> </td>
              <td>     <TextField
                id="outlined-name"
                label="Name"
                className={classes.textField}
                style={{ width: 450 }}
                 value={this.state.site_title}
                 onChange={(e)=>this.handleChange(e,'site_title')}
                margin="normal"
                variant="outlined"
              /> </td>

              <td> </td>

            </tr>

            <tr>
              <td> <label htmlFor=""> <h4>Description </h4> </label> </td>
              <td>
                <TextField
                  id="outlined-name"
                  label="Description"
                  className={classes.textField}
                  style={{ width: 450 }}
                  value={this.state.site_description}
                  onChange={(e)=>this.handleChange(e,'site_description')}
                  margin="normal"
                  variant="outlined"
                />


              </td>
              <td> </td>
            </tr>

            <tr>
              <td><label htmlFor=""> <h4> Keywords</h4> </label></td>
              <td>
                <TextField
                  id="outlined-name"
                  label="Keywords"
                  className={classes.textField}
                  style={{ width: 450 }}
                     value={this.state.site_keywords}
                     onChange={(e)=>this.handleChange(e,'site_keywords')}
                  margin="normal"
                  variant="outlined"
                />


              </td>
              <td> </td>
            </tr> 
                        
            <tr>
              <td style={{ width:'10px'}}></td>
              
              <td style={{ width:'70px',float:'right'}} >
              <Button  onClick={()=>this.handleUpdateSettings()} variant="contained" color="primary" size="small" style={{background:"#f7a414"}} className={classes.button}>
                 <b>Save</b> 
              </Button>
              </td>
              <td style={{  width:'450px'}}   >

            
              </td>
            

            </tr>

              

          </table>
<br/><br/>
          <table width="100%" style={{ marginLeft:'106px' }}>
          <label htmlFor=""><h4>Global Commission Rate</h4> </label>
          <tr>
             
             
              <td>
                <TextField
                  id="outlined-name"
                   label="Global commission Rate"
                  className={classes.textField}
                  style={{ width: 450 }}
                     value={this.state.commission}
                     onChange={(e)=>this.handleChange(e,'commission')}
                  margin="normal"
                  variant="outlined"
                />


              </td>
            
            </tr>  
            <tr>
            
             {/* <td  >
               <div>
               <Button style={{ align:'Right',marginLeft:'300px'}}    variant="contained" color="primary"  size="small" style={{background:"#f7a414"}} className={classes.button}>
                 <b>Update</b> 
              </Button>
               </div>
            
               </td>  */}
               <td>
  <div style={{ }}   >
  <Button onClick={()=>this.handleCommissionUpdate()}  style={{background:"#f7a414",  marginLeft: '379px'}} size="small" variant="contained" color="primary" className={classes.button}  >Update</Button>
  </div>
</td>
            </tr>    

          </table>
                
          
       
       <br/>
      
       <br/>
          </Fragment>
     

    );
  }
}


CustomizedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(CustomizedTable);