import React, { Props, Component, Fragment } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import MessagesPanel from '../Components/MessagesPanel';
import BookingsView from '../Components/BookingsView';
import DateAndTimePickers from '../Components/DateAndTimePickers';
import Grid from '@material-ui/core/Grid';

import Paper from '@material-ui/core/Paper';

import Drawer from './Common/Drawer'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InputLabel from '@material-ui/core/InputLabel';
import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';

  
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { compose } from 'redux';
import { connect } from 'react-redux';

import AppBar from './Common/MainAppBar';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Label from '@material-ui/icons/Label';

import { validateUser, storeAuthToken, fetchUser, storeUser } from '../Actions/AuthActions';
import { Redirect } from 'react-router-dom'

import logo from '../Img/logo.png'
import Profile from '../Img/profile.png'
import Loader from './Common/Loader';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Divider } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';


import { GetMessage } from '../Actions/MessagesActions';

const drawerWidth = 240;

const styles = theme => ({

  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },

  button: {
    display: 'block',
    marginTop: theme.spacing.unit * 2,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },

  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },

  fab: {
    margin: theme.spacing.unit,
  },

  extendedIcon: {
    marginRight: theme.spacing.unit,
  },

  wrap: {
    width: 480,
    margin: 'auto',
    marginTop: 100,

  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,

  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '98%'
  },

  button: {
    margin: theme.spacing.unit,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },

  logoWrap: {
    textAlign: 'center',
  },
  toolbar: theme.mixins.toolbar,
});




const mapStateToProps = (state) => {
  return {
    loading: state.Common.loading,
    loggedIn: state.Auth.loggedIn,
    activeMessage:state.Messages.activeMessage,
    
  }
}




const mapDispatchToProps = dispatch => ({

  GetMessage:(did)=>dispatch(GetMessage(did)),  

});


const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#666ad1',
      main: '#303f9f',
      dark: '#001970',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff5c8d',
      main: '#d81b60',
      dark: '#a00037',
      contrastText: '#000',
    },

    third: {
      light: '#6d6d6d',
      main: '#424242',
      dark: '#1b1b1b',
      contrastText: '#fff',
    },
  },
});


  

class ViewMessage extends React.Component {

  constructor(props) {
    super(props);

    var {mid} = this.props.match.params;

    this.state = {      
      id:0,
      title:"",
      description:"",
      discount:null,
      image_name:null,
      
     
    };

    if(mid !== undefined && mid !== null){
      this.props.GetMessage(mid);      
    }

   
  }

  

  

  handleSubChange = (e,index) => {
    const item = e.target.id;
    const isChecked = e.target.checked;
    this.setState({
        ...this.state,
        activeSubscription:{
            ...this.state.activeSubscription,
            activities:Object.values({
              ...this.state.activeSubscription.activities,
              [index]:{
                ...this.state.activeSubscription.activities[index],
                checked: isChecked
              } 
            })
            
          }
    });
    

  }

  componentDidMount() {
    document.body.style.background = "#eee";

  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });

  };

  changeDate = (value,field) =>{
    this.setState({
      ...this.state,
      activeSubscription:{
          ...this.state.activeSubscription,
          [field]:value          
        }
  });
  }

  

  render() {

    const { classes } = this.props;

    

    return <Fragment>

      <AppBar title="Dashboard"></AppBar>

      <Drawer></Drawer>

      <div style={{ width: 'auto', marginLeft: drawerWidth + 0, paddingTop: 35, height: 250 }}><div>

      <Table>

          <TableBody>
            <TableRow>
                  <TableCell style={{backgroundColor:'white',width:500, fontWeight:600, fontSize:15}}> Admin Message</TableCell>
                  <TableCell style={{backgroundColor:'white',fontWeight:600, fontSize:15}}><Button style={{ width:150,background: "#f7a414", marginLeft: 350 }} variant="contained" color="primary" component={Link} to="/Admin" className={classes.button}>Back</Button></TableCell> 

                          

            </TableRow>

          </TableBody>
        </Table>

        <Table>
          <TableBody>
            <TableRow>

              <TableCell style={{ backgroundColor: '#f2f2f2', width: 700, fontWeight: 500, paddingTop: 25 }} component="th">

                <Paper className={classes.root} elevation={1} style={{ marginBottom: 0 }}>
                <table width="100%">
                      <tr>
                          <td style={{verticalAlign:'top', width:'50%'}}>
                          <Typography style={{ fontWeight: 600, fontSize: 17, marginBottom: 10 }} color="black"> {this.props.activeMessage.title} </Typography>
                <Typography style={{ fontWeight: 400, fontSize: 15, marginBottom: 10 }} color="textSecondary" >{this.props.activeMessage.description}</Typography>
                          </td>
                          <td style={{verticalAlign:'top', padding:15}}>
                              <img src={this.props.activeMessage.image_name} width="100%" height="auto" />
                          </td>
                      </tr>
                  </table>
                  
                <Divider/>              
                      
                </Paper>

              </TableCell>
            </TableRow>
          </TableBody>
          <br />
        </Table>
        
      </div>
      </div>

    </Fragment>
  }
}

ViewMessage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(ViewMessage);