import React, { Props,Component, Fragment} from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';

import Drawer from './Common/Drawer'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InputLabel from '@material-ui/core/InputLabel';
import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { compose } from 'redux';
import { connect } from 'react-redux';

import AppBar from './Common/MainAppBar'; 

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Label from '@material-ui/icons/Label';

import { validateUser, storeAuthToken, fetchUser, storeUser } from '../Actions/AuthActions';
import {Redirect} from 'react-router-dom'

import logo from '../Img/logo.png'
import Profile from '../Img/profile.png'
import Loader from './Common/Loader';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


import { GetMessages,DeleteMessage } from '../Actions/MessagesActions';


const drawerWidth = 240;

const styles = theme => ({
 
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },

  button: {
    display: 'block',
    marginTop: theme.spacing.unit * 2,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },

  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },

  fab: {
    margin: theme.spacing.unit,
  },

  extendedIcon: {
    marginRight: theme.spacing.unit,
  },

  wrap:{
    width:480,
    margin:'auto',
    marginTop:100,
            
},
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit * 2,
      
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width:'98%'
      },

      button: {
        margin: theme.spacing.unit,
      },
      drawer: {
        width: drawerWidth,
        flexShrink: 0,
      },
      drawerPaper: {
        width: drawerWidth,
      },
      content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
      }, 

      logoWrap:{
        textAlign:'center',
      }, 
      toolbar: theme.mixins.toolbar,
  });

  


const mapStateToProps = (state) => {
    return {
        loading : state.Common.loading,
        loggedIn : state.Auth.loggedIn,
        messages:state.Messages.list 
    }
}




const mapDispatchToProps = dispatch => ({

  GetMessages:()=>dispatch(GetMessages()),
  DeleteMessage:(MsgID)=>dispatch(DeleteMessage(MsgID))    
});



class Tours extends Component {

    constructor(props){
        super(props);

        this.props.GetMessages();
    }

    componentDidMount(){
      document.body.style.background = "#eee";
      
    }

    handleChange = name => event => {
      this.setState({
        [name]: event.target.value,
      });

    };

    handleDelete = (MsgID) =>{
      this.props.DeleteMessage(MsgID);
    }

    render(){

      const { classes } = this.props;

    
      
     

        return <Fragment>
   
                    <AppBar title="Dashboard"></AppBar>
                    
                   
                       <Drawer></Drawer>
                      
                       
                      <div style={{width:'auto', marginLeft: drawerWidth+0, paddingTop:35, height:250 }}><div>

                          
                          <Table>
                            
                            <TableBody>
                            <TableRow>
                                    <TableCell style={{ backgroundColor: 'white',width:500,fontWeight:600, fontSize:16}}>Messages</TableCell>
                                   <TableCell style={{backgroundColor: 'white',width:0,fontWeight:600, fontSize:15}}><Button style={{ width:200, marginLeft: 300,background:"#f7a414" }} variant="contained" color="primary"  size="small"  component={Link} to="/MessageCreate" className={classes.button}> <b> Create Message </b> </Button></TableCell>
                            </TableRow> 

                            </TableBody>
                          </Table>

                        <Table>
                            <TableBody>
                          <TableRow>
                               
                               
                              
                               
                                <TableCell style={{backgroundColor:'#f2f2f2', width:700,fontWeight:500, paddingTop:25}} component="th">
                                
                                <Paper className={classes.root} elevation={1}  style={{marginBottom:30}}>
                                 
  <div className="tab-pan">
  <div className="tab-bok">



                                <Table style={{textAlign:"right"}} className={classes.table} >
                                  {/* <FormControl className={classes.formControl}>
          <InputLabel htmlFor="age-native-simple">Filter</InputLabel>
          <Select
            native
           
            inputProps={{
              name: 'age',
              id: 'age-native-simple',
            }}
          >
            <option value="" />
            <option value={10}>01</option>
            <option value={20}>02</option>
            <option value={30}>03</option>
          </Select>
        </FormControl> */}
        </Table>

          <div className="tab-bok">
<Table className={classes.table}>

          <tr>
            <th style={{width:'60%'}}><p>Title</p></th>
            <th><p >Date</p></th>
            {/* <th ><p style={{fontWeight:600, fontSize:15, color:'#000'}}>Status</p></th> */}
            <th align="right"><p style={{fontWeight:600, fontSize:15, color:'#000'}}></p></th>
          </tr>
        
        
          {this.props.messages.map(row => (
            <tr key={row.id}>
            <td component="th" scope="row">
              {row.title}
            </td>
            <td >{row.created_at}</td>
            {/* <td >{row.status}</td> */}
            <td align="right">
              <Button component={Link} to={"/MessageView/"+row.id}  variant="contained" size="small"  color="primary" style={{background:"#f7a414", alignItems:"center",alignContent:'center'}} className={classes.button}> 
                 &nbsp;&nbsp;<b>View</b> 
              </Button> 
              <Button onClick={()=>this.handleDelete(row.id)}  variant="contained" size="small"  color="primary" style={{background:"#f7a414", alignItems:"center",alignContent:'center'}} className={classes.button}> 
                 &nbsp;&nbsp;<b>Delete</b> 
              </Button>              
            </td>
          </tr>
          ))}
        
      </Table>
</div>
      </div></div>
            </Paper>
          
  </TableCell>

                            </TableRow>                           
      
                            </TableBody>
                        </Table>
                        </div>
                      </div> 

              </Fragment>
    }
}

Tours.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default compose(connect(mapStateToProps, mapDispatchToProps),withStyles(styles))(Tours);