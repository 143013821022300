import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import TextField from '@material-ui/core/TextField';


import { compose } from 'redux';
import { connect } from 'react-redux';

import { ShowRemark } from '../../Actions/CommonActions';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});








const mapStateToProps = (state) => {
  return {
    showRemark: state.Common.showRemark,
    remarkOnOK: state.Common.remarkOnOK,
    remarkData: state.Common.remarkData,
  }
}

const mapDispatchToProps = dispatch => ({
  ShowRemark: (status,onOK,data) => dispatch(ShowRemark(status,onOK,data)),  
});

class RejectPopup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reference: ""
    };
  }

  handleConfirmaton = () => {    
    this.props.remarkOnOK({id:this.props.remarkData.id,status:this.props.remarkData.status,remark:this.state.reference,data:this.props.remarkData});
    this.setState({reference:""});
  }

  handleChage = (e, field) => {
    this.setState({
      [field]: e.target.value
    });
  }

  handleClose = () => {

    this.props.SnackBar(false, null, this.props.alertVarient);
  }

  render() {

    return (
      <div>
        <Dialog
          open={this.props.showRemark}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => { this.props.ShowRemark(false, null, null); }}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{this.props.remarkData !== null ? this.props.remarkData.title:null}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <div>
                <table style={{ width: 500 }}>
                  <tr>

                    <td>

                      <label htmlFor="">Remark</label> <br />
                      <TextField
                        id="outlined-multiline-static"
                        onChange={(e)=>this.handleChage(e,'reference')}
                        value={this.state.reference}
                        multiline
                        rows="4"
                        width="200px"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                      />
                    </td>





                  </tr>

                </table>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { this.props.ShowRemark(false, null, null); }} style={{ color: 'orange' }} color="primary">
              Cancel
          </Button>
            <Button disabled={this.state.reference.length === 0} onClick={() => { this.handleConfirmaton(); }} style={{ color: 'orange' }} color="primary">
              OK
          </Button>
          </DialogActions>
        </Dialog>
      </div>);
  }

}


export default compose(connect(mapStateToProps, mapDispatchToProps))(RejectPopup);
