import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const TAX_RATE = 0.07;

class ViewTransactions extends React.Component {
  state = {
    open: false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };


  render() {
    const { fullScreen } = this.props;
  
function ccyFormat(num) {
  return `${num.toFixed(2)}`;
}


function priceRow(qty, unit) {
  return qty * unit;
}

function createRow(id, desc, qty, unit) {
  const price = priceRow(qty, unit);
  return { id, desc, qty, unit, price };
}

function subtotal(items) {
  return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
}

const rows = [
  ['21/05/2019', 'Lorem ipsum', 1.15],
  ['21/05/2019','Dolor sit amet',  45.99],
  ['21/05/2019','Consectetur adipiscing', 17.99],
].map((row, id) => createRow(id, ...row));

const invoiceSubtotal = subtotal(rows);
const invoiceTaxes = TAX_RATE * invoiceSubtotal;
const invoiceTotal = invoiceTaxes + invoiceSubtotal;


    return (
      <div>
 
        <Dialog
          fullScreen={fullScreen}
          open={this.props.open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{"Emcee Travels"}</DialogTitle>
        <TableCell align="left">Supplier Account</TableCell>
          <DialogContentText id="responsive-dialog-title"></DialogContentText>
          <DialogContent>
            <DialogContentText>
       
       
      <Table>
        <TableHead>
          <TableRow>
          <TableCell>Date</TableCell>
            <TableCell>Description</TableCell>
            <TableCell align="right">B/Rex</TableCell>
            <TableCell align="right">Credit</TableCell>
            <TableCell align="right">Commission</TableCell>
            <TableCell align="right">Debet</TableCell>
            <TableCell align="right">Balance</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.date}>
              <TableCell>{row.desc}</TableCell>
              <TableCell align="right">{row.qty}</TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right">{row.unit}</TableCell>
              <TableCell align="right">{row.unit}</TableCell>
              <TableCell align="right">{row.unit}</TableCell>
              <TableCell align="right">{row.unit}</TableCell>
            </TableRow>
          ))}
       
          <TableRow>
            <TableCell rowSpan={1} />
            <TableCell colSpan={2}>Total</TableCell>
            <TableCell align="right">22,600</TableCell>
            <TableCell align="right">2000</TableCell>
            <TableCell align="right">16,600</TableCell>
            <TableCell align="right">40,000</TableCell>
          </TableRow>
    
        </TableBody>
      </Table>
   


       
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} style={{ color:'orange'}}   color="primary">
              Disagree
            </Button>
            <Button onClick={this.props.closeHandle} color="primary" autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

ViewTransactions.propTypes = {
  fullScreen: PropTypes.bool.isRequired,

};


export default withMobileDialog()(ViewTransactions);