import React, { Fragment } from "react"
import { withStyles } from "@material-ui/core/styles"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import SeasonPanel from "./SeasonPanel"
import LocationRow from "./LocationRow"
import TagInput from "../Components/TagInput"
import TimeField from "react-simple-timefield"
import { compose } from "redux"
import { connect } from "react-redux"
import { SnackBar } from '../Actions/CommonActions';

const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '33.33%',
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});


const getLocation = (locations,locationgroups) => {
  var retLocation = locations
  for (let i = 0; i < locations.length; i++) { // locations[i].value
    for (let x = 0; x < locationgroups.length; x++) {
      var pickUpLocations = locationgroups[x].pickUpLocations
        for (let y = 0; y < pickUpLocations.length; y++) {
          var location = pickUpLocations[y].location
          if(location.value==locations[i].value) {
            retLocation.splice(i, 1)
          }
        }
    }
  }
  return retLocation
}

class Schedule extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      editSchedule: this.props.editSchedule,
      pickuplocation: null,
      pickuptime: '00:00',
      pickupplace: '',
      addLocationShow: false,
      expanded: this.props.group.id === 0
    }
  }

  onApply = () => {
    this.setState({
      editSchedule: false
    });
  }

  onEdit = () => {
    this.setState({
      editSchedule: true,
    });
  }

  handleTimeChange = (time) => {
    this.setState({
      pickuptime: time
    });
  }

  handleTextChange = (e, field) => {
    this.setState({
      [field]: e.target.value
    });
  }

  selectLocation = (value) => {
    this.setState({
      pickuplocation: value
    });
  }

  addPickupPoint = () => {
    if(this.state.pickuplocation==null) {
      this.props.SnackBar(true, 'Pick up Location can not be emp', 'error');
      return false
    }
    if(this.state.pickupplace=="") {
      this.props.SnackBar(true, 'Pick up place can not be empty', 'error');
      return false
    }
    this.props.addGroupPickupLocation(this.props.index, {
      location: this.state.pickuplocation,
      pickup_time: this.state.pickuptime,
      description: this.state.pickupplace
    });

    this.setState({
      pickuplocation: null,
      pickuptime: '',
      pickupplace: '',
      addLocationShow: false
    })
  }

  render() {

    const { classes } = this.props;



    return <div style={{ marginTop: 10 }}>

      <ExpansionPanel expanded={this.state.expanded}   >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ backgroundColor: '#707070', color: '#fff' }}
          onClick={() => this.setState({ expanded: !this.state.expanded })}
        >



          <table style={{ width: '100%' }}>
            <tr>
              <td style={{ width: '33.33%' }} ><Typography className={classes.heading} style={{ fontWeight: 500 }}>Location Group - {this.props.index + 1}</Typography></td>
              <td style={{ width: '33.33%' }} >&nbsp;</td>
              <td style={{ width: '33.33%' }} ></td>
            </tr>
          </table>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}>
          <table width={'100%'} >
            <tr>
              <td>
                <div style={{ backgroundColor: '#f8f8f8', width: '100%', padding: 15 }}>
                  <div className="block-content" style={{ boxShadow: 'none' }} >

                    <div className="data-table-block" style={{ boxShadow: 'none' }}>
                      <table className="data-table" cellspacing="0">
                        <tr class="header-tr">
                          <th>Pickup Location</th>
                          <th>Pickup Time</th>
                          <th colspan="2">Pick up Place</th>
                        </tr>
                        {this.props.group !== undefined ? (this.props.group.pickUpLocations.map((item, pIndex) => {
                          return <LocationRow
                            item={item}
                            sIndex={this.props.index}
                            pIndex={pIndex}
                            // locations={this.props.locations}
                            locationgroups={this.props.locationgroups}
                            updatePickupLocation={this.props.updateGroupPickupLocation}
                            SnackBar={(show, message, varient)=> this.props.SnackBar(show, message, varient)}
                            removeLocation={this.props.deleteGroupPickupLocation} />
                           
                        })) : null}

                        {this.state.addLocationShow === true ? (<tr className="add-option">
                          <td>
                            <TagInput 
                              options={getLocation(this.props.locations,this.props.locationgroups)}
                              selectedLocations={this.state.pickuplocation}
                              onPickupChange={this.selectLocation} /></td>
                          <td><TimeField
                            value={this.state.pickuptime}                     // {String}   required, format '00:00' or '00:00:00'
                            onChange={(value) => { this.handleTimeChange(value) }}      // {Function} required                    
                            colon=":"                       // {String}   default: ":"
                            className="add-more"
                            style={{ width: 70, textAlign: 'center' }}                      // {Boolean}  default: false
                          /></td>
                          <td><input type="text" value={this.state.pickupplace} onChange={(e) => this.handleTextChange(e, 'pickupplace')} className="add-more" /></td>
                          <td><a onClick={() => this.addPickupPoint()} style={{ cursor: 'pointer' }} className="add-more-button"></a></td>
                        </tr>) : null}

                        <tr className="add-option">
                          <td colspan={4} style={{ textAlign: 'center' }} >
                            <div onClick={() => this.setState({ addLocationShow: true })} className="group-add-button">
                              <a className="add-more-button" style={{ height: 30 }}></a>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>

                  {this.props.index !== 0 ? (<Fragment>
                    <div style={{ clear: 'both' }} ></div>
                    <div className="button-container">

                      <div style={{ float: 'right' }}><a onClick={() => this.props.deleteLocationGroup(this.props.index)} className="button-apply">Delete Location Group</a></div>
                      <div style={{ clear: 'both' }} ></div>
                    </div>
                  </Fragment>) : null}

                </div>
              </td>

            </tr>
            <tr>
              <td>
                <div style={{ padding: 15 }}>
                  {this.props.group !== undefined && this.props.group.seasons !== undefined ? this.props.group.seasons.map((season, index) => {
                    return <SeasonPanel
                      activity={this.props.activity}
                      GroupIndex={this.props.index}
                      SeasonIndex={index}
                      season={season}
                      changeScheduleTitle={this.props.changeScheduleTitle}
                      changeDate={this.props.changeDate}
                      updatePricGroup={this.props.updatePricGroup}
                      removePricGroup={this.props.removePricGroup}
                      addPriceGroup={this.props.addPriceGroup}
                      deleteSeason={this.props.deleteSeason} />
                  }) : null}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div style={{ padding: 15 }}>
                  <div onClick={() => this.props.addSeason(this.props.index)} className="group-add-button2">
                    ADD NEW AVAILABILITY
                  </div>
                </div>

              </td>
            </tr>
          </table>

          <div style={{ clear: 'both' }}></div>


        </ExpansionPanelDetails>

      </ExpansionPanel>
    </div>;



  }

}

Schedule.defaultProps = {
  editSchedule: false
}

const mapStateToProps = (state) => {
  return {
    locations: state.Locations.locations,
  }
}

const mapDispatchToProps = dispatch => ({
  SnackBar: (show, message, varient) => dispatch(SnackBar(show, message, varient))
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Schedule)