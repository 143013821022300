import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'; 
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';      

import FinancesDashboard from '../Components/FinancesDashboard';
import Site from '../Components/Site';

import FinancesSupplierAccounts from '../Components/FinancesSupplierAccounts';

import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper'; 

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InputLabel from '@material-ui/core/InputLabel';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Button from '@material-ui/core/Button';
import {logout} from '../Actions/AuthActions';



import { fetchCategories,AddCategory } from '../Actions/CategoryAction';

import { compose } from 'redux';
import { connect } from 'react-redux';

import EditableCategoryRow from './EditableCategoryRow';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
});



const mapStateToProps = (state) => {
    return {
        loading : state.Common.loading,
        loggedIn : state.Auth.loggedIn,
        categories: state.Categories.categories,
    }
}
const mapDispatchToProps = dispatch => ({
  fetchCategories: () => dispatch(fetchCategories()),
  AddCategory:(data)=>dispatch(AddCategory(data))
});

class TabsWrappedLabel extends React.Component {


    constructor(props){
      super(props);
  
      this.state={
        value: 'one',
        categoryName:null,
      }
  
      this.props.fetchCategories();
    
    }
  
    
    handleView = (data) => {
      this.props.ViewRefund(data);
    }
  
    handleChange = (event, value) => {
      this.setState({ value });
    };
  
    getRefundStatus(status){        
      switch(status){
      case 0 :{ return "Pending" }
      case 1:{ return "Accepted" }
      case 2 :{ return "Rejected" }
      case 3 :{ return "Completed" }    
      }
    }
  
    getPaymentStatus(status){        
      switch(status){
      case 0 :{ return "Pending" }
      case 1:{ return "Completed" }        
      }
    }


    handleNewCategoryName = (e) => {
      this.setState({
        categoryName:e.target.value
      });
    }

    handleAddCategory = () =>{
        if(this.state.categoryName !== null){
            this.props.AddCategory({name:this.state.categoryName});
            this.setState({
              categoryName:null,
            })
        }
    }
  
    render() {
        const { classes } = this.props;
        const { value } = this.state;
    
     
        
    
        return (
              

          
  
              

            <table className={classes.table}>
          
            <tr>
              <th style={{width:"30%"}} align="left"><p >ID</p></th>   
              <th style={{width:"50%"}}align="left"><p >Name</p></th>           
            
              
              <th style={{width:"20%"}} align="center"></th>
            </tr>
         
          <tr >               
                <td style={{width:"30%"}} align="left"></td>
                <td  style={{width:"50%"}} align="left">
                  <input type="text" onChange={this.handleNewCategoryName}/>
                </td>              
                <td style={{width:"20%"}} align="center">
                <Button onClick={()=>this.handleAddCategory()} size="small" variant="contained" style={{background:"#f7a414", textAlign:'center'}} color="primary" className={classes.button}>
                   <b>Add</b> 
                </Button>
                </td>
              </tr>  
            {this.props.categories.map((row,index) => {
                return <EditableCategoryRow row={row}/>

            }
                
            )}
        </table>
        );
      }
    }
    
    TabsWrappedLabel.propTypes = {
      classes: PropTypes.object.isRequired,
    };
    export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(TabsWrappedLabel);
    
