import React, { Props, Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DateAndTimePickers from '../Components/DateAndTimePickers';
import ViewTotalBookings from './Dialogs/ViewTotalBookings';
import Graph from '../Img/graph.png'
import Typography from '@material-ui/core/Typography';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CssBaseline from "@material-ui/core/CssBaseline";
import Pagination from "material-ui-flat-pagination";

//import FormControl from '@material-ui/core/FormControl';
//import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import Input from '@material-ui/core/Input';
import OutlinedInput from '@material-ui/core/OutlinedInput';
//import InputLabel from '@material-ui/core/InputLabel';

 import ImageBox from '../Components/ImageBox';
 import Taginput from '../Components/TagInput';

import { compose } from 'redux';
import { connect } from 'react-redux';

import { GetSiteSettings,UpdateSiteSettings } from '../Actions/SettingsAction';
import { fetchCategories } from '../Actions/CategoryAction';
import { height } from '@material-ui/system';

const CustomTableCell = withStyles(theme => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const styles = theme => ({
    root: {
      width: '100%',
      marginTop: theme.spacing.unit * 3,
      overflowX: 'auto',
    },
  
  
    table: {
      minWidth: 700,
  
    },
    row: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  });



let id = 0;
function createData(name, type, actions) {
  id += 1;
  return { id, name, type, actions };
}

const rows = [
  createData('1', '2'),

];

const mapStateToProps = (state) => {
  return {
      loading : state.Common.loading,
      loggedIn : state.Auth.loggedIn,
      categories: state.Categories.categories,
      site_title: state.Common.site_title,
      site_description: state.Common.site_description,
      site_keywords: state.Common.site_keywords,
      site_featured: state.Common.site_featured,
  }
}
const mapDispatchToProps = dispatch => ({
  GetSiteSettings: () => dispatch(GetSiteSettings()),
  fetchCategories:()=>dispatch(fetchCategories()),
  UpdateSiteSettings:(data)=>dispatch(UpdateSiteSettings(data))
});


class CustomizedTable extends Component {

  constructor(props) {
    super(props);

    this.state = {
      viewOpen: false,
      site_title: this.props.site_title,
      site_description: this.props.site_description,
      site_keywords: this.props.site_keywords,
      site_featured: this.props.site_featured,
    };

    this.props.fetchCategories();
    this.props.GetSiteSettings();
  }

  handleViewTr = () => {
    this.setState({
      viewOpen: true,
    });
  }

  handleClose = () => {
    this.setState({ viewOpen: false });
  }

  handleChange = (e,field) =>{
    this.setState({
      [field]:e.target.value
    })
  }

  handleUpdateSettings = () =>{
      this.props.UpdateSiteSettings({
                                        title:this.state.site_title,
                                        description:this.state.site_description,
                                        keywords:this.state.site_keywords,
                                        featured:this.state.site_featured
                                    });
  }

  render() {

    const { classes } = this.props;




    return (
     
        <Fragment>
        <div style={{ width:"50%" ,height:"250px", border:'1px solid #d9dadb', borderRadius:'4px'}}>

            
<table width="100%">
<caption><label htmlFor="">  </label> </caption>
<tr>

<div style={{ float:"left" , width:"30%", margin:'8px', height:'400px'  }}>

 <img src="../Img/traveller.png" alt=""/>


</div>

<div style={{ float:"right" , width:"60%"   }}>

<table>

<tr>
 <td> <label htmlFor=""> <h4> Site Title </h4> </label> </td>
              <td>   

              {/* <TextField
                id="outlined-name"
                label="Title"
                className={classes.textField}
                style={{ width: 450 }}
               
                margin="normal"
                variant="outlined"
              />  */}

    


              </td>
</tr>

<tr>
   <td> <label htmlFor=""> <h4>Description </h4> </label> </td>
              <td>
                {/* <TextField
                  id="outlined-name"
                  label="Description"
                  className={classes.textField}
                  style={{ width: 450 }}
                  value={this.state.site_description}
                  onChange={(e)=>this.handleChange(e,'site_description')}
                  margin="normal"
                  variant="outlined"
                /> */}

               


              </td>

</tr>
<tr>

    <td> <label htmlFor=""> <h4> Catagory </h4> </label> </td>    
      <td> <Taginput></Taginput>     </td>



</tr>

<tr>

         <td>

         </td>
             <td>

                            <Button  variant="contained" style={{background:"#f7a414", textAlign:'center', float:'right'}} color="primary" className={classes.button}>
                                      <b>Edit</b>  
                            </Button>
            </td>



</tr>



</table>



</div>

</tr>





</table>

        </div>
        </Fragment>

          


     

    );
  }
}


CustomizedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(CustomizedTable);