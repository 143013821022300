import React from 'react';



class NewStaticTextBox extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            editMode: false,
        }
    }


    handleEditMode = () =>{
        this.setState({
            editMode:true
        });
    }


    handleApply =()=>{

        if(this.props.required === true){
            if(this.props.value === null){
                this.props.onError(true,this.props.label+" can not be empty",'error');
                return false;
            }

            if(this.props.value.length === 0){
                this.props.onError(true,this.props.label+" can not be empty",'error');
                return false;
            }
        }


        this.setState({
            editMode:false
        });
    }

    render() {

    
        
        return <div>
            {this.state.editMode === false ? (
            <div className="profrm-row">
            <label>{this.props.label}</label>
            <textarea style={{width:'98%', backgroundColor:'rgba(202, 201, 201, 0.21)', fontSize:'14px' }}  readOnly={true} onChange={this.props.onChange} value={this.props.value}    ></textarea>
         
            <span onClick={()=>this.handleEditMode()}></span>
          </div>
            
            ) : (
                <div className="profrm-row active">
                    <label>{this.props.label}</label>
                    <textarea style={{width:'98%'}} onChange={this.props.onChange} value={this.props.value}></textarea>
                    <span onClick={()=>this.handleApply()}></span>
                  </div>           
              
            )}
            {this.props.review === true ? (<div style={{fontSize:12,display:'block',marginTop:-15,marginBottom:15}}>
            <a style={{color:'green',cursor:'pointer'}} >Approve</a>&nbsp;&nbsp;<a style={{color:'red',cursor:'pointer'}} >Reject</a>
            </div>):null}
        </div>
    }
}


NewStaticTextBox.defaultProps ={
    review:false,    
}
export default NewStaticTextBox;