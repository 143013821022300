import React, { Props, Component, Fragment } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import ImagesUpload from '../Components/ImagesUpload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';


import MessagesPanel from '../Components/MessagesPanel';
import BookingsView from '../Components/BookingsView';

import Paper from '@material-ui/core/Paper';

import Drawer from './Common/Drawer'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';


import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { compose } from 'redux';
import { connect } from 'react-redux';

import AppBar from './Common/MainAppBar';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import Label from '@material-ui/icons/Label';

import { validateUser, storeAuthToken, fetchUser, storeUser } from '../Actions/AuthActions';
import { Redirect } from 'react-router-dom'

import logo from '../Img/logo.png'
import Profile from '../Img/profile.png'
import Loader from './Common/Loader';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Divider } from '@material-ui/core';


import { UpdateDeal,GetDeal,LockActiveDeal } from '../Actions/DealsActions';


const drawerWidth = 240;

const styles = theme => ({

  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },

  button: {
    display: 'block',
    marginTop: theme.spacing.unit * 2,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },

  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },

  fab: {
    margin: theme.spacing.unit,
  },

  extendedIcon: {
    marginRight: theme.spacing.unit,
  },

  wrap: {
    width: 480,
    margin: 'auto',
    marginTop: 100,

  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,

  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '98%'
  },

  button: {
    margin: theme.spacing.unit,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },

  logoWrap: {
    textAlign: 'center',
  },
  toolbar: theme.mixins.toolbar,
});




const mapStateToProps = (state) => {
  return {
    loading: state.Common.loading,
    loggedIn: state.Auth.loggedIn,
    activeDeal:state.Deals.activeDeal
  }
}


const mapDispatchToProps = dispatch => ({
 
  UpdateDeal:(data)=>dispatch(UpdateDeal(data)),
  GetDeal:(did)=>dispatch(GetDeal(did)),
  LockActiveDeal:()=>dispatch(LockActiveDeal())
});


const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#666ad1',
      main: '#303f9f',
      dark: '#001970',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff5c8d',
      main: '#d81b60',
      dark: '#a00037',
      contrastText: '#000',
    },

    third: {
      light: '#6d6d6d',
      main: '#424242',
      dark: '#1b1b1b',
      contrastText: '#fff',
    },
  },
});

class Tours extends Component {

  constructor(props) {
    super(props);

    var {did} = this.props.match.params; 

    this.state = {      
      id:0,
      title:"",
      description:"",
      discount:null,
      image_name:null,
      uploadImage:null,
    };


    if(did !== undefined && did !== null){
        this.props.GetDeal(did);
    }
  }


  static getDerivedStateFromProps(props, state) {


    
    if (props.activeDeal.updated) {
      
      props.LockActiveDeal();
      return {
        id:props.activeDeal.id,
        title:props.activeDeal.title,
        description:props.activeDeal.description,
        discount:props.activeDeal.discount,
        image_name:props.activeDeal.image_name,
      }
    }

    return null;


  }

  componentDidMount() {
    document.body.style.background = "#eee";

  }

  handleChange = ( event, name ) => {
    this.setState({
      [name]: event.target.value,
    });

  };

  uploadImage = (e) => {

    if (e.target.files[0] != null) {

      this.setState({
        uploadImage: e.target.files[0],
      });      
    }

  }
  

  onSave = () =>{
    this.props.UpdateDeal(this.state);
  }

  render() {

    const { classes } = this.props;

    


    return <Fragment>

      <AppBar title="Dashboard"></AppBar>

      <Drawer></Drawer>

      <div style={{  marginLeft: drawerWidth, paddingTop: 35, height: 250 }}><div>

      <Table>

          
            <tr>
                  <th  style={{ backgroundColor: 'white',  fontWeight: 600, fontSize: 16 }}>Edit Discount</th>
                  <th style={{backgroundColor:'white', width:0,fontWeight:600, fontSize:15}}>
                    <Button style={{ width:150, marginLeft: 350 ,background:"#f7a414" }} variant="contained" color="primary" size="small" component={Link} to="/Deals" className={classes.button}>Back</Button>
                  </th> 

                         

            </tr>

          
        </Table>

        <Table>
          <TableBody>
            <TableRow>

              <TableCell style={{ backgroundColor: '#f2f2f2', width: 700, fontWeight: 500, paddingTop: 25 }} component="th">

                <Paper className={classes.root} elevation={1} style={{ marginBottom: 0 }}>                     

                
                <table width="100%" >
                    <tr>
                        <td width="50%" >
                        <div style={{marginTop:25}}>
                            <FormControl style={{width:'100%'}}>
                                <TextField
                                    name="deal_name"
                                    id="deal_name"
                                    value={this.state.title}
                                    onChange={(e)=>{this.handleChange(e,'title')}}
                                    variant="outlined"
                                    label="Title"
                                    
                                />
                            </FormControl>
                          </div>
                          
                          <div style={{marginTop:25}}>
                          <FormControl style={{width:'100%'}}>
                                <TextField
                                    
                                    multiline
                                    rows="4"
                                    name="deal_name"
                                    id="deal_name"
                                    value={this.state.description}
                                    onChange={(e)=>{this.handleChange(e,'description')}}
                                    variant="outlined"
                                    label="Description"
                                />
                            </FormControl>
                          </div>
                          <div style={{marginTop:25}}>
                          <FormControl style={{width:'100%'}}>
                                <TextField
                                    name="deal_name"
                                    id="deal_name"
                                    value={this.state.discount}
                                    onChange={(e)=>{this.handleChange(e,'discount')}}
                                    variant="outlined"
                                    label="Rate %"
                                    type="number"
                                />
                            </FormControl>
                          </div>   
                          <div style={{marginTop:25}}>
                          <input
                              accept="image/*"
                              style={{ display: "none" }}
                              id="file-work-logo"
                              type="file"
                              onChange={this.uploadImage}
                            />
                            <label htmlFor="file-work-logo">
                              <Button component="span" variant="contained" color="default" className={classes.button}>
                                Upload Image &nbsp;
                                                <CloudUploadIcon className={classes.rightIcon} />
                              </Button>
                            </label>
                          </div>   
                        </td>
                        <td width="50%" style={{ padding: 15, verticalAlign: 'top'}} >
                        { this.state.image_name !== "" ? (<img src={this.state.image_name} width="100%" height="auto" />):null}
                        </td>
                      
                    </tr>
                </table>         
 
                
         
         

       
<TableRow>

<br /> 
<td>
                          <div style={{ float:'right', align:'right'}}>
                          <Button onClick={this.onSave} style={{  marginLeft: '443px' ,background:"#f7a414" }} size="small" variant="contained" color="primary" className={classes.button}>Save</Button>
                          </div>
                        </td>
{/* <Button onClick={this.onSave} style={{  marginLeft: 0 ,background:"#f7a414" }} variant="contained" color="primary" className={classes.button}>Save</Button> */}

</TableRow>

<br /> <br /> 



                </Paper>

              </TableCell>
            </TableRow>
          </TableBody>
          <br />
        </Table>
        
      </div>
      </div>

    </Fragment>
  }
}

Tours.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Tours);