import React, { Props,Component, Fragment} from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';

import Drawer from './Common/Drawer'
 
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InputLabel from '@material-ui/core/InputLabel';
import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { compose } from 'redux';
import { connect } from 'react-redux';

import AppBar from './Common/MainAppBar'; 

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Label from '@material-ui/icons/Label';

import { validateUser, storeAuthToken, fetchUser, storeUser } from '../Actions/AuthActions';
import {Redirect} from 'react-router-dom'

import logo from '../Img/logo.png'
import Profile from '../Img/profile.png'
import Loader from './Common/Loader';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';

import { GetDeals,Deactivate } from '../Actions/DealsActions';


const getStatus = (status) =>{        
  switch(status){
    // case 0 :{ return "Pending" }
    case 0 :{ return "Ongoing" }
    case 3:{ return "Active" }
    case 1 :{ return "Promo Ended" }    
  }
}

 const StatusStyle = (status) => {
  switch(status){
    case 0 :{ return {backgroundColor:'#90A4AE', color:'#FFF'} }
    case 3:{ return {backgroundColor:'#4CAF50', color:'#FFF'} }
    case 1 :{ return {backgroundColor:'#f44336', color:'#FFF'} }   
  }
 }

 const StatusChip = (status) =>{
      return <Chip size="small" style={StatusStyle(status)} label={getStatus(status)}></Chip>
 }

const drawerWidth = 240;

const styles = theme => ({
 
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },

  button: {
    display: 'block',
    marginTop: theme.spacing.unit * 2,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },

  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },

  fab: {
    margin: theme.spacing.unit,
  },

  extendedIcon: {
    marginRight: theme.spacing.unit,
  },

  wrap:{
    width:480,
    margin:'auto',
    marginTop:100,
            
},
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit * 2,
      
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width:'98%'
      },

      button: {
        margin: theme.spacing.unit,
      },
      drawer: {
        width: drawerWidth,
        flexShrink: 0,
      },
      drawerPaper: {
        width: drawerWidth,
      },
      content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
      }, 

      logoWrap:{
        textAlign:'center',
      }, 
      toolbar: theme.mixins.toolbar,
  });

  


const mapStateToProps = (state) => {
    return {
        loading : state.Common.loading,
        loggedIn : state.Auth.loggedIn,
        deals:state.Deals.list 
    }
}




const mapDispatchToProps = dispatch => ({

  GetDeals:()=>dispatch(GetDeals()),
  Deactivate:(id)=>dispatch(Deactivate(id))    
});



class Tours extends Component {

    constructor(props){
        super(props);

        this.props.GetDeals();
    }

    componentDidMount(){
      document.body.style.background = "#eee";
      
    }

    handleChange = name => event => {
      this.setState({
        [name]: event.target.value,
      });

    };

    handleDeactivate = (id) =>{
      this.props.Deactivate(id);
    }

    render(){

      const { classes } = this.props;

    
      
     

        return <Fragment>
   
                    <AppBar title="Dashboard"></AppBar>
                    
                   
                       <Drawer></Drawer>
                      
                       
                      <div style={{width:'auto', marginLeft: drawerWidth+0, paddingTop:35, height:250 }}><div>

                          
                          <Table>
                            
                            <TableBody>
                            <TableRow>
                                    <TableCell style={{ backgroundColor: 'white', width:500,fontWeight:600, fontSize:16}}>Deals & Discounts</TableCell> 
                                   <TableCell style={{backgroundColor: 'white', width:0,fontWeight:600, fontSize:15}}> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  <Button style={{ width:200, marginLeft: 300,background:"#f7a414" }} variant="contained" color="primary" size="small" component={Link} to="/CreateDiscount" className={classes.button}> <b>Create a Discount</b>  </Button></TableCell>
                            </TableRow> 

                            </TableBody>
                          </Table>

                        <Table>
                            <TableBody>
                          <TableRow>
                               
                               
                              
                               
                                <TableCell style={{backgroundColor:'#f2f2f2', width:700,fontWeight:500, paddingTop:25}} component="th">
                                
                                <Paper className={classes.root} elevation={1}  style={{marginBottom:30}}>
                                <div className="tab-pan">
                                <div className="tab-bok">
                                 
                                <Table width="100%" style={{textAlign:"right"}} className={classes.table} >
                                  {/* <FormControl className={classes.formControl}>
          <InputLabel htmlFor="age-native-simple">Filter</InputLabel>
          <Select
            native
           
            inputProps={{
              name: 'age',
              id: 'age-native-simple',
            }}
          >
            <option value="" />
            <option value={10}>01</option>
            <option value={20}>02</option>
            <option value={30}>03</option>
          </Select>
        </FormControl> */}
        </Table>
<Table className={classes.table}>
        
          <tr>
            <th style={{width:"18%" }} ><p >Title</p></th>
            <th style={{width:"18%" }} ><p >Discount Rate %</p></th>
            <th style={{width:"6%" }}><p >Subscriptions</p></th>
            <th style={{width:"15%" }} ><p >Number of Tours</p></th>
            <th style={{width:"8%" }} ><p >Status</p></th>
            {/* <th ><p>Status</p></th> */}
            <th style={{width:"8%" }} align="right"><p style={{fontWeight:600, fontSize:15, color:'#000'}}></p></th>
            <th style={{width:"7%" }} > &nbsp; </th>
          </tr>
    
          {this.props.deals.map(row => (
            <tr key={row.id}>
              <td component="th" scope="row" width="5%">
                {row.title}
              </td>
              <td >{row.discount}</td>
              <td >0</td>
              <td width="8%">0</td>
              <td >{StatusChip(row.status)}</td >
             
               <td align="right" >
                <Button component={Link} to={"/CreateDiscount/"+row.id}  variant="contained" size="small" color="primary" className={classes.button}style={{background:"#f7a414",alignContent:'center', textAlign:"center", marginRight:'-2px' }} textalign="center">
                                               <b>EDIT </b> 
                </Button>
                </td>
                <td align="right" >
                <Button onClick={()=>this.handleDeactivate(row.id)} 
                        style={{paddingLeft:15,background:"#f7a414",textAlign:'center' }}  
                        variant="contained" 
                        size="small" 
                        color="primary" 
                        className={classes.button} 
                        textalign="center">
                                               <b>DEACTIVATE </b> 
                </Button>
              </td> 

              {/* <td style={{width:"5px", marginRight:"-5px" }}>
              <Button component={Link} to={"/CreateDiscount/"+row.id}  variant="contained" size="small" color="primary" className={classes.button}style={{background:"#f7a414",alignContent:'center', textAlign:"center" }} textalign="center">
                                               <b>EDIT </b> 
                </Button>
              </td>

              <td width="5%">
              <Button onClick={()=>this.handleDeactivate(row.id)} style={{background:"#f7a414",textAlign:'center' }}  variant="contained" size="small" color="primary" className={classes.button} style={{background:"#f7a414",alignContent:'center', textAlign:"center" }} textalign="center">
                                               <b>DEACTIVATE </b> 
                </Button>

              </td> */}
            </tr>
          ))}
       
      </Table>

      </div>
        </div> 
            </Paper>
          
  </TableCell>

                            </TableRow>                           
      
                            </TableBody>
                        </Table>
                        </div>
                      </div> 

              </Fragment>
    }
}

Tours.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default compose(connect(mapStateToProps, mapDispatchToProps),withStyles(styles))(Tours);