import React, { Props, Component, Fragment } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import ViewTotalBookings from '../Components/Dialogs/ViewTotalBookings';
import SearchSelect from '../Components/TagInput';


import BookingsView from '../Components/BookingsView';
import FinancesTab from '../Components/FinancesTab';
import Paper from '@material-ui/core/Paper';
import Drawer from './Common/Drawer'

 
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InputLabel from '@material-ui/core/InputLabel';
import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { compose } from 'redux';
import { connect } from 'react-redux';

import AppBar from './Common/MainAppBar';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Label from '@material-ui/icons/Label';

import { validateUser, storeAuthToken, fetchUser, storeUser } from '../Actions/AuthActions';
import { Redirect } from 'react-router-dom'

import logo from '../Img/logo.png'
import Profile from '../Img/profile.png'
import Loader from './Common/Loader';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const drawerWidth = 240;

const styles = theme => ({

  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  table: {
    minWidth: 700,
  },

  button: {
    display: 'block',
    marginTop: theme.spacing.unit * 2,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },

  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },

  fab: {
    margin: theme.spacing.unit,
  },

  extendedIcon: {
    marginRight: theme.spacing.unit,
  },

  wrap: {
    width: 480,
    margin: 'auto',
    marginTop: 100,
  },

  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,

  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '98%'
  },

  button: {
    margin: theme.spacing.unit,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },

  logoWrap: {
    textAlign: 'center',
  },
  toolbar: theme.mixins.toolbar,
});




const mapStateToProps = (state) => {
  return {
    loading: state.Common.loading,
    loggedIn: state.Auth.loggedIn,
    categories: state.Categories.categories,
  }
}

let id = 0;
function createData(name, type, duration, location, status, actions) {
  id += 1;
  return { id, name, type, duration, location, status, actions };
}


const mapDispatchToProps = dispatch => ({

});

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};


class Tours extends Component {

  constructor(props) {
    super(props);

    this.state = {
      age: '',
      name: 'hai',
      labelWidth: 0,
    };
  }

  componentDidMount() {
    document.body.style.background = "#eee";

  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });

  };

  render() {

    const { classes } = this.props;

    const rows = [
      createData('VI1008021_USD_201808809_USD_USUP_BNK_8', '30th April 2019'),
      createData('VI1008021_USD_201808808_USD_USUP_BNK_7', '29th April 2019'),
      createData('VI1008021_USD_201808807_USD_USUP_BNK_6', '27th April 2019'),
      createData('VI1008021_USD_201808806_USD_USUP_BNK_5', '26th April 2019'),
      createData('VI1008021_USD_201808805_USD_USUP_BNK_4', '25th April 2019'),

    ];

    const data = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          label: 'My First dataset',
          fill: false,
          lineTension: 0.1,
          backgroundColor: 'rgba(75,192,192,0.4)',
          borderColor: 'rgba(75,192,192,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: [65, 59, 80, 81, 56, 55, 40]
        }
      ]
    };



    return <Fragment>

      <AppBar title="Dashboard"></AppBar>


      <Drawer></Drawer>


      <div style={{ width: 'auto', marginLeft: drawerWidth + 0, paddingTop: 35, height: 250 }}><div>


        <Table>

          <TableBody>
            <TableRow>
              <TableCell style={{backgroundColor: 'white', width: 500, fontWeight: 600, fontSize: 16 }}>Finances</TableCell>

            </TableRow>

          </TableBody>
        </Table>

        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ backgroundColor: '#f2f2f2', width: 700, fontWeight: 500, paddingTop: 25 }} component="th">

                <Paper className={classes.root} elevation={1} style={{ marginBottom: 30 }}>



                  <div className="tab-pan">
                  <div className="tab-bok">

                  <FinancesTab></FinancesTab>
                
              </div></div>
                </Paper>

              </TableCell>

            </TableRow>

          </TableBody>
        </Table>
      </div>
      </div>

    </Fragment>
  }
}

Tours.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Tours);