import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';      
 
import FinancesDashboard from '../Components/FinancesDashboard';
import Site from '../Components/Site';

import FinancesSupplierAccounts from '../Components/FinancesSupplierAccounts';

import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper'; 

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InputLabel from '@material-ui/core/InputLabel';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Button from '@material-ui/core/Button';
import {logout} from '../Actions/AuthActions';



import { AddPolicy,FetchPolicies } from '../Actions/SettingsAction';

import { compose } from 'redux';
import { connect } from 'react-redux';

import EditPolicy from './EditPolicy';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
});



const mapStateToProps = (state) => {
    return {
        loading : state.Common.loading,
        loggedIn : state.Auth.loggedIn,
        policies: state.Common.policies,
    }
}
const mapDispatchToProps = dispatch => ({
  FetchPolicies:()=>dispatch(FetchPolicies()),
  AddPolicy:(data)=>dispatch(AddPolicy(data))
});

class Locations extends React.Component {


    constructor(props){
      super(props);
  
      this.state={
        value: 'one',
        type:0,
        title:"",
        content:"",
      }
  
      this.props.FetchPolicies();
    
    }
  
    
    handleView = (data) => {
      this.props.ViewRefund(data);
    }
  
    handleChange = (event, field) => {
      this.setState({ [field]:event.target.value});
    };
  
    getRefundStatus(status){        
      switch(status){
      case 0 :{ return "Pending" }
      case 1:{ return "Accepted" }
      case 2 :{ return "Rejected" }
      case 3 :{ return "Completed" }    
      }
    }
  
    getPaymentStatus(status){        
      switch(status){
      case 0 :{ return "Pending" }
      case 1:{ return "Completed" }        
      }
    }


    handleNewCategoryName = (e) => {
      this.setState({
        categoryName:e.target.value
      });
    }

    handleAddCategory = () =>{
        if(this.state.content !== ""){
            this.props.AddPolicy({
              type:this.state.type,
              title:this.state.title,
              content:this.state.content,
            });
            this.setState({
              type:0,
              title:"",
              content:"",
            })
        }
    }
  
    render() {
        const { classes } = this.props;
        const { value } = this.state;
    
     
        
    
        return (<div>

              <div>
                  <table>
                      <tr>
                          <td>Policy type</td>
                          <td><select onChange={(e)=>this.handleChange(e,'type')}>
                                <option value="0">Know before you go</option>
                                <option value="1">Cancellation policy</option>
                              </select></td>
                      </tr>
                      <tr>
                          <td>Policy title</td>
                          <td><input tyep="text" value={this.state.title} onChange={(e)=>this.handleChange(e,'title')} /></td>
                      </tr>
                      <tr>
                          <td>Content</td>
                          <td><textarea cols="100" rows="10" value={this.state.content} onChange={(e)=>this.handleChange(e,'content')} ></textarea></td>
                      </tr>
                      <tr> 
                          <td></td>
                          <td  style={{float:"right",paddingRight:60}}>
                          <Button onClick={()=>this.handleAddCategory()} size="small" variant="contained" color="primary" style={{background:"#f7a414", textAlign:'center'}} className={classes.button}>
                                <b>Add</b> 
                              </Button>
                          </td>
                      </tr>
                  </table>
              </div>

              { this.props.policies.map((item,index)=>{
                  return <EditPolicy row={item}  />
              })} 
                  
            </div>);
      }
    }
    
    Locations.propTypes = {
      classes: PropTypes.object.isRequired,
    };
    export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Locations);
    
