import React, { Props, Component, Fragment } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import MessagesPanel from '../Components/MessagesPanel';
import BookingsView from '../Components/BookingsView';

import Images from '../Components/Images';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Drawer from './Common/Drawer'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';


import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { compose } from 'redux';
import { connect } from 'react-redux';

import AppBar from './Common/MainAppBar';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import Label from '@material-ui/icons/Label';

import { validateUser, storeAuthToken, fetchUser, storeUser } from '../Actions/AuthActions';
import { Redirect } from 'react-router-dom'

import logo from '../Img/logo.png'
import Profile from '../Img/profile.png'
import Loader from './Common/Loader';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Divider } from '@material-ui/core';


const drawerWidth = 240;

const styles = theme => ({

  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },

  button: {
    display: 'block',
    marginTop: theme.spacing.unit * 2,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },

  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },

  fab: {
    margin: theme.spacing.unit,
  },

  extendedIcon: {
    marginRight: theme.spacing.unit,
  },

  wrap: {
    width: 480,
    margin: 'auto',
    marginTop: 100,

  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,

  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '98%'
  },

  button: {
    margin: theme.spacing.unit,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },

  logoWrap: {
    textAlign: 'center',
  },
  toolbar: theme.mixins.toolbar,
});




const mapStateToProps = (state) => {
  return {
    loading: state.Common.loading,
    loggedIn: state.Auth.loggedIn
  }
}

let id = 0;
function createData(name, type, duration, location, status, actions) {
  id += 1;
  return { id, name, type, duration, location, status, actions };
}


const mapDispatchToProps = dispatch => ({

});


const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#666ad1',
      main: '#303f9f',
      dark: '#001970',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff5c8d',
      main: '#d81b60',
      dark: '#a00037',
      contrastText: '#000',
    },

    third: {
      light: '#6d6d6d',
      main: '#424242',
      dark: '#1b1b1b',
      contrastText: '#fff',
    },
  },
});

class Tours extends Component {

  constructor(props) {
    super(props);

    this.state = {
      age: '',
      name: 'hai',
      labelWidth: 0,
    };
  }

  componentDidMount() {
    document.body.style.background = "#eee";

  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });

  };

  render() {

    const { classes } = this.props;

    const rows = [
      createData('JM Bairstow', 10, 6.0, 4, 1),
      createData('AE Jones', 15, 3.0, 1, 2.0),
      createData('AD Hales', 17, 2.0, 5, 4.0),
      createData('SR Taylor', 19, 4.0, 13, 3.0),
      createData('JE Root', 25, 5.0, 2, 3.0),

    ];

    const data = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          label: 'My First dataset',
          fill: false,
          lineTension: 0.1,
          backgroundColor: 'rgba(75,192,192,0.4)',
          borderColor: 'rgba(75,192,192,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: [65, 59, 80, 81, 56, 55, 40]
        }
      ]
    };


    return <Fragment>

      <AppBar title="Dashboard"></AppBar>

      <Drawer></Drawer>

      <div style={{ width: 'auto', marginLeft: drawerWidth + 0, paddingTop: 35, height: 250 }}><div>

      <Table>

          <TableBody>
            <TableRow>
                  <TableCell style={{backgroundColor:'#e3f2fd', width:500,fontWeight:600, fontSize:15}}>My Profile</TableCell>
                  
                       

            </TableRow>

          </TableBody>
        </Table>

        <Table>
          <TableBody>
            <TableRow>

              <TableCell style={{ backgroundColor: '#f2f2f2', width: 700, fontWeight: 500, paddingTop: 25 }} component="th">

                <Paper className={classes.root} elevation={1} style={{ marginBottom: 0 }}>
        
     <Typography variant="h6" component="h3">
     My Profile
        </Typography>
        <br />

                        <Typography color="textSecondary">
                        Name:
        </Typography>
                        <Typography component="p">
                        W.M. Arjuna Jayawardena
        </Typography>
                        <br />
                        <Typography color="textSecondary">
                        NIC Number:
        </Typography>
                        <Typography component="p">
                            870923045 V
        </Typography>
                      <br />
                      <Typography color="textSecondary">
                        E mail:
        </Typography>
                        <Typography component="p">
                            arjunaj@gmail.com
        </Typography>
                      <br />

                        <Typography color="textSecondary">
                           Home Phone No:
        </Typography>
                        <Typography component="p">
                            (+94) 011 1234567
        </Typography>
        <br />
        <Typography color="textSecondary">
                           Mobile Phone No:
        </Typography>
                        <Typography component="p">
                           (+94) 071 1234567
        </Typography>
                <br />
                        <Typography color="textSecondary">
                        Address:
        </Typography>
                        <Typography component="p">
                            No: 101/1B, Pagoda Road, Nugegoda.
        </Typography>
        <br />
       
        {/* <Divider/>
                <br />
<TableRow>
<Button onClick={this.handleClose} style={{ width:150, marginLeft: 0 }} variant="contained" color="primary" className={classes.button}>Accept</Button>
<Button onClick={this.handleClose} style={{ width:150, marginLeft: 0 }} variant="contained" color="third" component={Link} to="/Account" className={classes.button}>Edit</Button>
</TableRow> */}


                </Paper>

              </TableCell>
            </TableRow>
          </TableBody>
          <br />
        </Table>
        
      </div>
      </div>

    </Fragment>
  }
}

Tours.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Tours);