export default function reducer(state={
    list:[],
    activityReivews:[],
    rejectRequestShow:false,
    rejectRequestData:null,
    rejectRequestAction:()=>{}
    
},action){
switch(action.type){
    case "STORE_REVIEWS":{
        return{...state,list:action.list}
    }
    case "STORE_ACTIVITY_REVIEWS":{
        return{...state,activityReivews:action.list}
    }    
    case "LOCK_ACTIVE_BOOKING":{
        return{...state,
                    activeBooking:{                                    
                                    updated:false,
                                   }
              }
    }    
    case "LOADIND_END":{ 
        return{...state,loading:false}
    }
    case "UPDATE_TOUR":{
        return {...state,activeTour:action.tour}
    }
    case "SHOW_SHOW_REJECT_REVIEW":{
        return{...state,rejectRequestShow:action.show,rejectRequestData:action.data,rejectRequestAction:action.on}
    }    
}
return state
}