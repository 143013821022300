import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';      
import Button from '@material-ui/core/Button';
import { fetchLocations,AddLocation } from '../Actions/LocationAction';
import { compose } from 'redux';
import { connect } from 'react-redux';
import EditableLocationRow from './EditableLocationRow';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
});



const mapStateToProps = (state) => {
    return {
        loading : state.Common.loading,
        loggedIn : state.Auth.loggedIn,
        locations: state.Locations.locations,
    }
}
const mapDispatchToProps = dispatch => ({
  fetchLocations: () => dispatch(fetchLocations()),
  AddLocation:(data)=>dispatch(AddLocation(data))
});

class Locations extends React.Component {


    constructor(props){
      super(props);
  
      this.state={
        value: 'one',
        categoryName:null,
      }
  
      this.props.fetchLocations();
    
    }
  
    
    handleView = (data) => {
      this.props.ViewRefund(data);
    }
  
    handleChange = (event, value) => {
      this.setState({ value });
    };
  
    getRefundStatus(status){        
      switch(status){
      case 0 :{ return "Pending" }
      case 1:{ return "Accepted" }
      case 2 :{ return "Rejected" }
      case 3 :{ return "Completed" }    
      }
    }
  
    getPaymentStatus(status){        
      switch(status){
      case 0 :{ return "Pending" }
      case 1:{ return "Completed" }        
      }
    }


    handleNewCategoryName = (e) => {
      this.setState({
        categoryName:e.target.value
      });
    }

    handleAddCategory = () =>{
        if(this.state.categoryName !== null){
            this.props.AddLocation({name:this.state.categoryName});
            this.setState({
              categoryName:null,
            })
        }
    }
  
    render() {
        const { classes } = this.props;
        const { value } = this.state;
    
     
        
    
        return (
           
            
            
             
                  
            <table className={classes.table}>
          
            <tr>
              <th align="left"><p>ID</p></th>   
              <th align="left"><p>Name</p></th>           
            
              
              <th align="center"></th>
            </tr>
          
     
          <tr >               
                <td align="left"></td>
                <td align="left">
                  <input type="text" onChange={this.handleNewCategoryName}/>
                </td>              
                <td align="center">
                <Button onClick={()=>this.handleAddCategory()} size="small" variant="contained" color="primary" style={{background:"#f7a414", textAlign:'center'}} className={classes.button}>
                   <b>Add</b> 
                </Button>
                </td>
              </tr>  
            {this.props.locations.map((row,index) => {
                return <EditableLocationRow row={row}/>

            }
                
            )}

              
               
          
        </table>);
      }
    }
    
    Locations.propTypes = {
      classes: PropTypes.object.isRequired,
    };
    export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Locations);
    
