import React, { Props,Component, Fragment} from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';

import Drawer from './Common/Drawer'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';



import { compose } from 'redux';
import { connect } from 'react-redux';

import AppBar from './Common/MainAppBar'; 

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Label from '@material-ui/icons/Label';

import { validateUser, storeAuthToken, fetchUser, storeUser } from '../Actions/AuthActions';
import {Redirect} from 'react-router-dom'

import logo from '../Img/logo.png'
import Profile from '../Img/profile.png'
import Loader from './Common/Loader';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


const drawerWidth = 240;

const styles = theme => ({
 
  wrap:{
    width:480,
    margin:'auto',
    marginTop:100,
            
},
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit * 2,
      
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width:'98%'
      },

      button: {
        margin: theme.spacing.unit,
      },
      drawer: {
        width: drawerWidth,
        flexShrink: 0,
      },
      drawerPaper: {
        width: drawerWidth,
      },
      content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
      }, 

      logoWrap:{
        textAlign:'center',
      }, 
      toolbar: theme.mixins.toolbar,
  });



const mapStateToProps = (state) => {
    return {
        loading : state.Common.loading,
        loggedIn : state.Auth.loggedIn 
    }
}

const mapDispatchToProps = dispatch => ({
 
});

class Account extends Component {

    constructor(props){
        super(props);

        this.state = {            
        };
    }

    componentDidMount(){
      document.body.style.background = "#eee";
    }

    handleChange = name => event => {
      this.setState({
        [name]: event.target.value,
      });
    };

    render(){

      const { classes } = this.props;

      const data = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
          {
            label: 'My First dataset',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: [65, 59, 80, 81, 56, 55, 40]
          }
        ]
      };
      
     

        return <Fragment>
   
                    <AppBar title="Dashboard"></AppBar>
                    
                   
                       <Drawer></Drawer>
                      
                       
                      <div style={{width:'auto', marginLeft: drawerWidth+0, paddingTop:35, height:250 }}><div>

                          
                          <Table>
                            
                            <TableBody>
                            <TableRow>
                                    <TableCell style={{backgroundColor:'#e3f2fd', width:500,fontWeight:600, fontSize:16}}>My Account</TableCell>
                                                                      
                            </TableRow> 

                            </TableBody>
                          </Table>

                        <Table>
                            <TableBody>
                                <TableRow>
                                <TableCell style={{backgroundColor:'#f2f2f2', width:700,fontWeight:500, paddingTop:25}} component="th">
                                
                                <Paper className={classes.root} elevation={1}  style={{marginBottom:30}}>
                                    <p style={{fontWeight:600, paddingLeft:10, fontSize:15}}>Company Details</p>

                                <TextField
                                 id="outlined-name"
                                  label="Company Name"
                                  className={classes.textField}
                                  value={this.state.name}
                                  onChange={this.handleChange('companyname')}                    
                                  margin="normal"
                                  variant="outlined"
                                 />

                                <TextField
                                 id="outlined-name"
                                  label="Address"
                                  className={classes.textField}
                                  value={this.state.name}
                                  onChange={this.handleChange('Address')}                    
                                  margin="normal"
                                  variant="outlined"
                                 />

                                 <TextField
                                 id="outlined-name"
                                  label="Company Register Number"
                                  className={classes.textField}
                                  value={this.state.name}
                                  onChange={this.handleChange('Register')}                    
                                  margin="normal"
                                  variant="outlined"
                                 />


                                <TextField
                                  id="outlined-name"
                                  label="Land Telephone"
                                  className={classes.textField}
                                  value={this.state.name}
                                  onChange={this.handleChange('LandTelephone')}                    
                                  margin="normal"
                                  variant="outlined"
                                 />

                                <TextField
                                  id="outlined-name"
                                  label="Mobile Telephone"
                                  className={classes.textField}
                                  value={this.state.name}
                                  onChange={this.handleChange('Mobile')}                    
                                  margin="normal"
                                  variant="outlined"
                                 />

<br /> <br />
                                <p style={{fontWeight:600, paddingLeft:10, fontSize:15}}>Contact Person Details</p>


                                <TextField
                                  id="outlined-name"
                                  label="Name"
                                  className={classes.textField}
                                  value={this.state.name}
                                  onChange={this.handleChange('Name')}                    
                                  margin="normal"
                                  variant="outlined"
                                 />

                                <TextField
                                  id="outlined-name"
                                  label="NIC Number"
                                  className={classes.textField}
                                  value={this.state.name}
                                  onChange={this.handleChange('NIC')}                    
                                  margin="normal"
                                  variant="outlined"
                                 />

                                <TextField
                                  id="outlined-name"
                                  label="Home Telephone"
                                  className={classes.textField}
                                  value={this.state.name}
                                  onChange={this.handleChange('HomeTelephone')}                    
                                  margin="normal"
                                  variant="outlined"
                                 />

                                <TextField
                                  id="outlined-name"
                                  label="Mobile Telephone"
                                  className={classes.textField}
                                  value={this.state.name}
                                  onChange={this.handleChange('Mobile Telephone')}                    
                                  margin="normal"
                                  variant="outlined"
                                 />

                                <TextField
                                  id="outlined-name"
                                  label="Address"
                                  className={classes.textField}
                                  value={this.state.name}
                                  onChange={this.handleChange('Address')}                    
                                  margin="normal"
                                  variant="outlined"
                                 />

<br /><br />

                <Button className={classes.button}
  variant="contained"
  component="label"
>
  Upload File
  <input
    type="file"
    style={{ display: "none"}}
  />
</Button>
                <br /><br />

                <p style={{fontWeight:600, paddingLeft:10, fontSize:14}}>Categories <br /><br />

               
        <FormControlLabel control={<Checkbox value="checkedC" />} label="Category 1" />
        <FormControlLabel control={<Checkbox value="checkedC" />} label="Category 2" />
        <FormControlLabel control={<Checkbox value="checkedC" />} label="Category 3" />
        <FormControlLabel control={<Checkbox value="checkedC" />} label="Category 4" />
        <FormControlLabel control={<Checkbox value="checkedC" />} label="Category 5" />

</p><br />
                
<Button  variant="contained" onClick={()=>{ this.setState({loggedIn:true}); }} color="primary" className={classes.button}>
                    Send
                </Button>
                <br />  <br />
    
            </Paper>
          
  </TableCell>
  <TableCell style={{backgroundColor:'#f2f2f2', width:10,fontWeight:500, paddingTop:25, verticalAlign:"top"}} component="th">
                                
<Paper style={{marginBottom:350}}>
<TableRow>
  <TableCell style={{backgroundColor:'#fff', fontWeight:600, fontSize:16}} component="th"><img src={logo}/> </TableCell>
</TableRow> 
</Paper>

<Paper>

<TableRow>
 <TableCell><div style={{paddingTop:10, paddingright:50,  paddingBottom:2}}><img src={Profile}/> </div></TableCell>
</TableRow>
</Paper>   
                                
                                </TableCell>

                            </TableRow>                           
      
                            </TableBody>
                        </Table>
                        </div>
                      </div> 

              </Fragment>
    }
}

Account.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default compose(connect(mapStateToProps, mapDispatchToProps),withStyles(styles))(Account);