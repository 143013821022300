import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import TextField from '@material-ui/core/TextField';


import { compose } from 'redux';
import { connect } from 'react-redux';

import { RejectReview } from '../../Actions/ReviewsActions';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


  
  

   


const mapStateToProps = (state) => {
    return {       
        rejectRequestShow : state.Reviews.rejectRequestShow, 
        rejectRequestData : state.Reviews.rejectRequestData, 
        rejectRequestAction : state.Reviews.rejectRequestAction,               
    }
}

const mapDispatchToProps = dispatch => ({
  RejectReview : (show,data,on) => dispatch(RejectReview(show,data,on)),  
});

class ReviewReject extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            amount:0,
            remark:""
        };
    }

    handleConfirmaton = () =>{
      this.props.rejectRequestAction(this.props.rejectRequestData,this.state.remark);
      this.props.RejectReview(false,null,null)
    }

    handleChage = (e,field)=>{
        this.setState({
            [field]:e.target.value
        });
    }

    handleClose = ()=> {        
       
        this.props.SnackBar(false,null,this.props.alertVarient);
    }

    render() {

        return (
            <div>             
              <Dialog
        open={this.props.rejectRequestShow}
        TransitionComponent={Transition}
        keepMounted
        onClose={()=>{ this.props.RejectReview(false,null,null);}}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Reject Review</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
              <div>
                  <table style={{width:500}}>
                      <tr>
                          <th style={{ width:150, textAlign:'left' }}>REMARK</th>
                          <td style={{verticalAlign:'top'}}>
                          <TextField
                              onChange={(e)=>this.handleChage(e,'remark')}
                              style={{width:'100%'}}
                              type="text"
                              variant='outlined'
                              rows={10}
                              multiline
                              rowsMax="4"
                            />
                          </td>
                      </tr>
                  </table>
              </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{ this.props.RejectReview(false,null,null);}}  style={{ color:'orange'}} color="primary">
            Cancel
          </Button>
          <Button onClick={()=>{this.handleConfirmaton();}}  style={{ color:'orange'}} color="primary">
            Send Request
          </Button>
        </DialogActions>
      </Dialog>
              </div>);
    }
    
}


export default compose(connect(mapStateToProps, mapDispatchToProps))(ReviewReject);
