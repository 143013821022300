import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import TextField from '@material-ui/core/TextField';


import { compose } from 'redux';
import { connect } from 'react-redux';

import { showCreateRefundAction,createRefund } from '../../Actions/FinanceActions';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


  
  

   


const mapStateToProps = (state) => {
    return {       
        refundRequestShow : state.Finance.refundRequestShow, 
        refundRequestData : state.Finance.refundRequestData, 
        refundRequestAction : state.Finance.refundRequestAction,               
    }
}

const mapDispatchToProps = dispatch => ({
  showCreateRefundAction : (show,data,on) => dispatch(showCreateRefundAction(show,data,on)),
  createRefund:(amount,remark,data)=>dispatch(createRefund(amount,remark,data))
});

class RefundRequest extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            amount:0,
            remark:0
        };
    }

    handleConfirmaton = () =>{
      this.props.refundRequestAction(this.state.amount,this.state.remark,this.props.refundRequestData);
      this.props.showCreateRefundAction(false,null,null)
    }

    handleChage = (e,field)=>{
        this.setState({
            [field]:e.target.value
        });
    }

    handleClose = ()=> {        
       
        this.props.SnackBar(false,null,this.props.alertVarient);
    }

    render() {

        return (
            <div>             
              <Dialog
        open={this.props.refundRequestShow}
        TransitionComponent={Transition}
        keepMounted
        onClose={()=>{ this.props.showCreateRefundAction(false,null,null);}}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Refund Request"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
              <div>
                  <table style={{width:500}}>
                      <tr>
                          <th style={{ width:150, textAlign:'left',  height:25 }}>ACTIVITY</th>
                          <td>
                              {this.props.refundRequestData !== null ? this.props.refundRequestData.activity.title:null}
                          </td>
                      </tr>
                      <tr>
                          <th style={{ width:150, textAlign:'left',  height:25 }}></th>
                          <td>
                            &nbsp;
                          </td>
                      </tr>  
                      <tr>
                          <th style={{ width:150, textAlign:'left', height:25 }}>BOOKING DATE</th>
                          <td>
                          {this.props.refundRequestData !== null ? this.props.refundRequestData.created_at:null}
                          </td>
                      </tr>
                      <tr>
                          <th style={{ width:150, textAlign:'left',  height:25 }}></th>
                          <td>
                            &nbsp;
                          </td>
                      </tr>
                      <tr>
                          <th style={{ width:150, textAlign:'left',  height:25 }}>START DATE</th>
                          <td>
                          {this.props.refundRequestData !== null ? this.props.refundRequestData.start_date:null}
                          </td>
                      </tr>
                      <tr>
                          <th style={{ width:150, textAlign:'left',  height:25 }}></th>
                          <td>
                            &nbsp;
                          </td>
                      </tr>
                      <tr>
                          <th style={{ width:150, textAlign:'left', height:25 }}>AMOUNT</th>
                          <td style={{verticalAlign:'top'}}>
                              <TextField
                                onChange={(e)=>this.handleChage(e,'amount')}
                                type="number"
                                variant='outlined'
                              />

                          </td>
                      </tr>
                      <tr>
                          <th style={{ width:150, textAlign:'left',  height:25 }}></th>
                          <td>
                            &nbsp;
                          </td>
                      </tr>
                      <tr>
                          <th style={{ width:150, textAlign:'left' }}>REMARK</th>
                          <td style={{verticalAlign:'top'}}>
                          <TextField
                              onChange={(e)=>this.handleChage(e,'remark')}
                              style={{width:'100%'}}
                              type="text"
                              variant='outlined'
                              rows={10}
                              multiline
                              rowsMax="4"
                            />
                          </td>
                      </tr>
                  </table>
              </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{ this.props.showCreateRefundAction(false,null,null);}}  style={{ color:'orange'}} color="primary">
            Cancel
          </Button>
          <Button onClick={()=>{this.handleConfirmaton();}}  style={{ color:'orange'}} color="primary">
            Send Request
          </Button>
        </DialogActions>
      </Dialog>
              </div>);
    }
    
}


export default compose(connect(mapStateToProps, mapDispatchToProps))(RefundRequest);
