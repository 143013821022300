import {API_CLIENT_ID, API_CLIENT_SECRET, API_URL, API_GRANT_TYPE} from './Config';
import {startLoading,endLoading,SnackBar,handleHTTPError} from './CommonActions';
import store from '../store';

export function fetchCategories(){

    return function (dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/api/categories/get',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',              
            }
        }).then(response => response.json(), error => console.log('An error occurred.', error))               
          .then((json) =>{
                  dispatch(storeCategories(json));                  
                  dispatch(endLoading());
          });
    }
}


export function storeCategories(cats){
    return{type:'STORE_CATEGORIES',categories:cats};
}


export function AddCategory(data) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/admin/categories/add',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            body:JSON.stringify(data),
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(fetchCategories()); 
                  dispatch(SnackBar(true,'Category Added','success'));                                    
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not save data','error'));
        });
    }   

}

export function UpdateCategory(data) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/admin/categories/update',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            body:JSON.stringify(data),
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(fetchCategories()); 
                  dispatch(SnackBar(true,'Category updated','success'));                                    
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not save data','error'));
        });
    }   

}


export function DeleteCategory(id) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/admin/categories/delete?id='+id,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },            
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(fetchCategories()); 
                  dispatch(SnackBar(true,'Category deleted','success'));                                    
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not save data','error'));
        });
    }   

}