import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';  

import FinancesDashboard from '../Components/FinancesDashboard';   
import Site from '../Components/Site';
import Pages from '../Components/Pages';
import Location from '../Components/Location';
import Catagory from '../Components/Catogory'; 
import Policies from '../Components/Policies';
import Sliders from '../Components/Sliders';
import FeaturedCategories from './FeaturedCategories'

import FinancesSupplierAccounts from '../Components/FinancesSupplierAccounts';

import { Link } from 'react-router-dom';



import './../activity/css/style.css'; 
 

import {GetRefunds,ViewRefund,GetInvoices,ShowPayment} from '../Actions/FinanceActions';

 
import { compose } from 'redux';
import { connect } from 'react-redux';


function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
});



const mapStateToProps = (state) => {
    return {
        loading : state.Common.loading,
        loggedIn : state.Auth.loggedIn,
        refunds: state.Finance.refundsList,
        invoices:state.Finance.invoicesList,
    }
}
const mapDispatchToProps = dispatch => ({

  GetRefunds:()=>dispatch(GetRefunds()),
  ViewRefund:(data)=>dispatch(ViewRefund(data)),
  GetInvoices:()=>dispatch(GetInvoices()),
  ShowPayment:(data)=>dispatch(ShowPayment(data))    
});

class TabsWrappedLabel extends React.Component {


  constructor(props){
    super(props);

    this.state={
      value: 'one',
    }

    
  }

  
  handleView = (data) => {
    this.props.ViewRefund(data);
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

handleTabChange = (tab) => {
    this.setState({ value: tab});
  };


  getRefundStatus(status){        
    switch(status){
    case 0 :{ return "Pending" }
    case 1:{ return "Accepted" }
    case 2 :{ return "Rejected" }
    case 3 :{ return "Completed" }    
    }
  }

  getPaymentStatus(status){        
    switch(status){
    case 0 :{ return "Pending" }
    case 1:{ return "Completed" }        
    }
  }



  render() {
    const { classes } = this.props;
    const { value } = this.state;

 
    

    return ( 
       <div className="tab-pan">
  
        <div className="bk-menu">
          <Tabs value={value} onChange={this.handleChange}>
           <a style={{cursor:'pointer'}} className={ this.state.value === 'one' ? 'active':null} onClick={()=>this.handleTabChange('one')}>Site</a>                
                 
            <a style={{cursor:'pointer'}} className={ this.state.value === 'two' ? 'active':null} onClick={()=>this.handleTabChange('two')}>Pages</a>    

            <a style={{cursor:'pointer'}} className={ this.state.value === 'three' ? 'active':null} onClick={()=>this.handleTabChange('three')}>Categories</a>    

            <a style={{cursor:'pointer'}} className={ this.state.value === 'four' ? 'active':null} onClick={()=>this.handleTabChange('four')}>Location</a>    


             <a style={{cursor:'pointer'}} className={ this.state.value === 'five' ? 'active':null} onClick={()=>this.handleTabChange('five')}>Policies</a>
            <a style={{cursor:'pointer'}} className={ this.state.value === 'six' ? 'active':null} onClick={()=>this.handleTabChange('six')}>Sliders</a>
            <a style={{cursor:'pointer'}} className={ this.state.value === 'seven' ? 'active':null} onClick={()=>this.handleTabChange('seven')}>Featured Categories</a>
          </Tabs>
          </div>
       


        {value === 'one' && <TabContainer>
          <Site></Site>
        </TabContainer>}
        

        {value === 'two' && <TabContainer>
        
          <Pages></Pages>




        </TabContainer>}

  
        <div className="tab-bok">
        {value === 'three' && <TabContainer>
        <Catagory></Catagory>
        </TabContainer>}
        </div>

        <div className="tab-bok">
        {value === 'four' && <TabContainer>
          <Location></Location>
        </TabContainer>}
        </div>
        
        <div className="tab-bok">
        {value === 'five' && <TabContainer>
          <Policies></Policies>
        </TabContainer>}
        </div>

        <div className="tab-bok">
        {value === 'six' && <TabContainer>
          <Sliders></Sliders>
        </TabContainer>}
        </div>
        <div className="tab-bok">
        {value === 'seven' && <TabContainer>
          <FeaturedCategories></FeaturedCategories>
        </TabContainer>}
        </div>
      </div>
    );
  }
}

TabsWrappedLabel.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(TabsWrappedLabel);
