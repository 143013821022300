import {API_CLIENT_ID, API_CLIENT_SECRET, API_URL, API_GRANT_TYPE} from './Config';
import {startLoading,endLoading,SnackBar,handleHTTPError} from './CommonActions';
import store from '../store';
import axios from 'axios';


export function GetMessages(){

    return function (dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/api/admin/messages/get',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token              
            }
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                    dispatch(storeMessages(json));                  
                    dispatch(endLoading());                                                      
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Error loading data','error'));
        });
        
    }
}


export function storeMessages(messages){
    return{type:'STORE_ADMIN_MESSAGES',messages:messages};
}


export function DeleteMessage(MsgID){

  return function (dispatch){
      dispatch(startLoading());
      fetch(API_URL+'/api/admin/messages/delete?id='+MsgID,{
          method: 'GET',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token              
          }
      }).then(handleHTTPError)
      .then((response)=>{
            dispatch(endLoading());
            response.json().then((json)=>{
                  dispatch(GetMessages());             
                  dispatch(SnackBar(true,'Message deleted','success'));                                                      
            });
      }).catch((error)=>{              
        dispatch(endLoading());            
        dispatch(SnackBar(true,'Error deleting message','error'));
      });
      
  }
}

export function GetMessage(dealID){

  return function (dispatch){
      dispatch(startLoading());
      fetch(API_URL+'/api/admin/messages/get?id='+dealID,{
          method: 'GET',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token              
          }
      }).then(handleHTTPError)
      .then((response)=>{
            dispatch(endLoading());
            response.json().then((json)=>{
                  dispatch(storeActiveMessage(json));             
                                                                        
            });
      }).catch((error)=>{              
        dispatch(endLoading());            
        dispatch(SnackBar(true,'Error loading locations','error'));
      });
      
  }
}


export function storeActiveMessage(message){
  return{type:'STORE_ACTIVE_MESSAGE',m:message};
}

export function LockActiveDeal(){
  return{type:'LOCK_ACTIVE_MESSAGE'};
}


export function UpdateMessage(data){

  return function(dispatch) {
      dispatch(SnackBar(true,'Uploading image, please wait...','info'));

      var fd = new FormData();

      if(data.uploadImage !== null){
        fd.append('file',data.uploadImage,data.uploadImage.name);
      }     
      
      fd.append('id',data.id);
      fd.append('title',data.title);
      fd.append('description',data.description);      
      

      axios.post(API_URL+'/api/admin/messages/update',fd,{            
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
          },           
      }).then((response)=>{
            dispatch(endLoading());            
            dispatch(GetMessages());
            dispatch(SnackBar(true,'Your profie was updated','success'));
      }).catch((error)=>{
          console.log(error)              
        dispatch(endLoading());            
        dispatch(SnackBar(true,'Data saving error','error'));
      });
  }
}