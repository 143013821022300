export default function reducer(state={
    refundRequestShow:false,
    refundRequestData:null,
    refundRequestAction:null,
    refundsList:[],
    refundRequestView:false,
    refundRequestViewData:null,
    refundRequestViewAction:null,
    invoicesList:[],
    paymentRequestShow:false,
    paymentRequestData:null,
    paymentdRequestAction:null,
    bookings:[],
    
},action){

    switch(action.type){
        case "SHOW_REFUND_REQUEST":{
            return{...state,refundRequestShow:action.show,refundRequestData:action.data,refundRequestAction:action.on}
        }
        case "STORE_REFUNDS":{
            return{...state,refundsList:action.list}
        }
        case "SHOW_REFUND_REQUEST_FORM":{
            return{...state,refundRequestView:action.show,refundRequestViewData:action.data,paymentdRequestAction:action.on}
        }
        case "STORE_INVOICES":{
            return{...state,invoicesList:action.list}
        }
        case "SHOW_PAYMENT_REQUEST":{
            return{...state,paymentRequestShow:action.show,paymentRequestData:action.data,paymentdRequestAction:action.on}
        }
        case "SHOW_STORE_FINANCE_BOOKINGS":{
            return{...state,bookings:action.list}
        }

               
    }

    return state;
}