import React, { Fragment } from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import TextField from '@material-ui/core/TextField';


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { compose } from 'redux';
import { connect } from 'react-redux';



import Button from '@material-ui/core/Button';
import {logout} from '../Actions/AuthActions';

import { DeleteCategory,UpdateCategory } from '../Actions/CategoryAction';

const mapStateToProps = (state) => {
    return {
        
    }
}
const mapDispatchToProps = dispatch => ({ 
    UpdateCategory:(data)=>dispatch(UpdateCategory(data)),
    DeleteCategory:(id)=>dispatch(DeleteCategory(id))
});

class EditableCategoryRow extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            editMode: false,
            id:0,
            value:"",
            updated:false
        }
    }


    handleEditMode = () =>{
        this.setState({
            editMode:true
        });
    }


    handleApply =()=>{

        if(this.props.required === true){
            if(this.props.value === null){
                this.props.onError(true,this.props.label+" can not be empty",'error');
                return false;
            }

            if(this.props.value.length === 0){
                this.props.onError(true,this.props.label+" can not be empty",'error');
                return false;
            }
        }

        this.handleUpdate();

        this.setState({
            editMode:false
        });
    }


    static getDerivedStateFromProps(props, state) {

        if (props.row.id !== state.id && state.updated === false) {
            return {
                id:props.row.id,
                value:props.row.name,
                updated:true
            }
        }
    }

    handleUpdate = () =>{
        this.props.UpdateCategory({id:this.state.id,name:this.state.value});
    }

    handleEditField = (e) => {
        this.setState({
            value:e.target.value
        })
    }

    handleDelete = () =>{
        this.props.DeleteCategory(this.state.id);
    }

    render() {

    
        
        return <Fragment>
            {this.state.editMode === false ? (            
            <TableRow >               
                <TableCell align="left">{this.props.row.id}</TableCell>
                <TableCell align="left">{this.state.value}</TableCell>              
                <TableCell align="center">
                <Button onClick={()=>this.handleEditMode()}  size="small" variant="contained" style={{background:"#f7a414", textAlign:'center'}} color="primary" >
                   <b>Edit</b> 
                </Button> &nbsp;
                <Button onClick={()=>this.handleDelete()} size="small" variant="contained" style={{background:"#f7a414", textAlign:'center'}} color="primary" >
                  <b>Delete</b>  
                </Button>
                </TableCell>
              </TableRow> 
                        ) : (
            
                <TableRow >               
                <TableCell align="left">{this.props.row.id}</TableCell>
                <TableCell align="left">
                    <input type="text" value={this.state.value} onChange={this.handleEditField} />            
                </TableCell>              
                <TableCell align="center">                    
                <Button onClick={()=>this.handleApply()} size="small" variant="contained" style={{background:"#f7a414"}} color="primary" >
                    Update
                </Button>
                </TableCell>
              </TableRow>
            )}
        </Fragment>
    }
}

EditableCategoryRow.defaultProps = {
    readOnly:false
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(EditableCategoryRow);