import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

import FinancesDashboard from '../Components/FinancesDashboard';
import FinancesSupplierAccounts from '../Components/FinancesSupplierAccounts';

import { Link } from 'react-router-dom';
import './../activity/css/style.css';
 

import Table from '@material-ui/core/Table'; 
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InputLabel from '@material-ui/core/InputLabel';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Button from '@material-ui/core/Button';
import { MyBookings } from '../Actions/BookingActions';
import {MyDashboard} from '../Actions/BookingActions';

import TextField from '@material-ui/core/TextField';
import SearchSelect from '../Components/TagInput';


import {GetRefunds,ViewRefund,GetInvoices,ShowPayment} from '../Actions/FinanceActions';
import { fetchLocations } from '../Actions/LocationAction';
import { GetSuppliers,GetSuppliersStausFilter } from '../Actions/SupplierActions';
import { fetchCategories } from '../Actions/CategoryAction';


import { compose } from 'redux';
import { connect } from 'react-redux';
import Chip from '@material-ui/core/Chip';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';

const getStatus = (status) => {
  switch (status) {
    case 0: { return "Pending" }
    case 1: { return "Confirmed" }
    case 2: { return "Rejected" }
    case 3: { return "Canceled" }
    case 4: { return "Completed" }
    case 5: { return "Dispute" }
  }
}

const StatusStyle = (status) => {
  switch (status) {
    case 0: { return { backgroundColor: '#90A4AE', color: '#FFF' } }
    case 1: { return { backgroundColor: '#4CAF50', color: '#FFF' } }
    case 2: { return { backgroundColor: '#673AB7', color: '#FFF' } }
    case 3: { return { backgroundColor: '#263238', color: '#FFF' } }
    case 4: { return { backgroundColor: '#00695C', color: '#FFF' } }
    case 5: { return { backgroundColor: '#f44336', color: '#FFF' } }
  }
}

const StatusChip = (status) => {
  return <Chip size="small" style={StatusStyle(status)} label={getStatus(status)}></Chip>
}

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}



TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
});

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "black"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#f7a414"
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black"
      },
      "&:hover fieldset": {
        borderColor: "#f7a414"
      },
      "&.Mui-focused fieldset": {
        borderColor: "black"
      }
    }
  }
})(TextField);


const mapStateToProps = (state) => {
    return {
        loading : state.Common.loading,
        loggedIn : state.Auth.loggedIn,
        refunds: state.Finance.refundsList,
        invoices:state.Finance.invoicesList,
        BookingList: state.Finance.bookings,
        categories: state.Categories.categories,
        suppliers: state.Suppliers.list,
        BookingList1: state.Booking.list,
    }
}
const mapDispatchToProps = dispatch => ({

  GetRefunds:()=>dispatch(GetRefunds()),
  ViewRefund:(data)=>dispatch(ViewRefund(data)),
  GetInvoices:()=>dispatch(GetInvoices()),
  ShowPayment:(data)=>dispatch(ShowPayment(data)),
  MyBookings: (params) => dispatch(MyBookings(params)),
  GetSuppliers: (gStatus) => dispatch(GetSuppliersStausFilter(gStatus)),
  fetchCategories: () => dispatch(fetchCategories()),    
});

class TabsWrappedLabel extends React.Component {


  constructor(props){
    super(props);

    this.state={
      value: 'one',

      supplier: null,
      fromdate: new Date((new Date().getFullYear())+'-'+(new Date().getMonth() < 1 ? '12':(new Date().getMonth()) )+'-01'),
      todate: new Date((new Date().getFullYear()),(new Date().getMonth() < 1 ? '12':(new Date().getMonth()) ),0),
      category: null,
      payouttotal:0,
      netpayout:0,
      refundtotal:0,

    }


    this.props.MyBookings({
      supplier: this.state.supplier,
      fromdate: this.state.fromdate,
      todate:  this.state.todate,      
    });

    this.props.GetRefunds();
    this.props.GetInvoices();
    this.props.fetchCategories();
    this.props.GetSuppliers('1,2,3,4');
  }

  handleChange1 = name => event => {
    this.setState({
      [name]: event.target.value,
    },()=>this.searchBookings());

  };
  
  handleView = (data) => {
    this.props.ViewRefund(data);
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleTabChange = (tab) => {
    this.setState({ value: tab});
  };

  
   handleDateChange (value,field) {
    console.log(value);
    this.setState({
      [field]: value,
    },()=>this.searchBookings());

  };



  getRefundStatus(status){        
    switch(status){
    case 0 :{ return "Pending" }
    case 1:{ return "Accepted" }
    case 2 :{ return "Rejected" }
    case 3 :{ return "Completed" }    
    }
  }

  getPaymentStatus(status){        
    switch(status){
    case 0 :{ return "Pending" }
    case 1:{ return "Completed" }        
    }
  }


  handleSearchChange = name => event => {
    this.setState({
      [name]: event.target.value,
    },()=>this.searchBookings());

  };

  selectSupplier = (value) => {
    if(value === null){
      this.setState({
        supplier:null
    },()=>this.searchBookings());
    }else{
      this.setState({
        supplier:value.id
    },()=>this.searchBookings());
    }
   
  }

  selectCategory = (e) =>{
      this.setState({
        category:e.target.value
      },()=>this.searchBookings())
  }




  searchBookings = () =>{
    this.props.MyBookings({
      supplier: this.state.supplier,
      fromdate: this.state.fromdate,
      todate:  this.state.todate,      
    });
  }
  

  render() {
    const { classes } = this.props;
    const { value } = this.state;

 
    

    return (
      <div className={classes.root}>



     <div className="bk-mindiv">
              <div className="bk-menu">
                <a style={{cursor:'pointer'}} className={ this.state.value === 'one' ? 'active':null}  onClick={()=>this.handleTabChange('one')}>Dashboard</a>                
                <a style={{cursor:'pointer'}} className={ this.state.value === 'two' ? 'active':null}  onClick={()=>this.handleTabChange('two')}>Supplier Invoice</a>             
                <a style={{cursor:'pointer'}} className={ this.state.value === 'three' ? 'active':null}  onClick={()=>this.handleTabChange('three')}>Refunds</a>              
                <a style={{cursor:'pointer'}} className={ this.state.value === 'four' ? 'active':null}  onClick={()=>this.handleTabChange('four')}>Accounts</a>              
                 </div></div>




<div className="tab-bok">
 <div className="tab-pan">

        {value === 'one' && <TabContainer>
          <FinancesDashboard></FinancesDashboard>
        </TabContainer>}
        {value === 'two' && <TabContainer>
        <Table className={classes.table}>
          
            <tr>
              <th align="left">Supplier</th>   
              <th align="left">Invoice Date</th>              
              <th align="center">Amount(USD)</th>
              <th align="center">Payment </th>
              <th align="center">Payment Date </th>
              
              <th align="center"></th>
            </tr>
          
            {this.props.invoices.map((row,index) => (
              <tr key={row.id}>
                <td align="left">{row.supplier.work_name}</td>
                <td align="left">{row.invoice_date}</td>                
                <td align="center">{row.total}</td>
                <td align="center">{this.getPaymentStatus(row.status)}</td>
                <td align="center">{row.payment_date}</td>                
                <td align="center">
                
                { row.status === 0 ? (<Button onClick={()=>{this.props.ShowPayment(row)}}  variant="contained" style={{background:"#f7a414", textAlign:'center'}} color="primary" className={classes.button}>
                  <b>Pay</b>  
                </Button>):null }
                                                             
                </td>

                <td align="center" >
                <Button target="_blank" href={row.download}  variant="contained" color="primary" style={{background:"#f7a414",textAlign:'center' }} className={classes.button}>
                  <b>Download</b>  
                </Button>

                </td>
              </tr>
            ))}
          
        </Table>
        </TabContainer>}




        {value === 'three' && <TabContainer>


        <Table className={classes.table}>
          
            <tr>
              <th><p >Supplier</p></th>
              <th align="left"><p >Activity</p></th>
              <th align="left"><p >Booking Ref</p></th>
              <th align="left"><p >Amount(USD)</p></th>
              <th align="center"><p >Status </p></th>
              <th align="center"><p ></p></th>
            </tr>
          
                      {this.props.refunds.map((row,index) => (
              <tr key={row.id}>
                <td component="th" scope="row">
                  {row.supplier.work_name}
                </td>
                <td align="left">{row.activity.title}</td>
                <td align="left">{row.booking.booking_reference}</td>
                <td align="center">{row.amount}</td>
                <td align="center">{this.getRefundStatus(row.status)}</td>
                <td align="center">
                <Button onClick={()=>{this.handleView(row)}}  variant="contained" style={{background:"#f7a414", textAlign:'center'}}   color="primary" className={classes.button}>
                  <b>View</b>  
                </Button>
                </td>
              </tr>
            ))}
          
        </Table>
  
              
         


        </TabContainer>}



        {value === 'four' && <TabContainer>
          
                        <div id="sup">

                         {/* <FormControl style={{ width: 190 }}>

                          <SearchSelect
                            options={this.props.suppliers}
                            getOptionLabel={option => `${option.work_name}`}
                            getOptionValue={option => `${option.id}`}
                            value={this.state.supplier}
                          
                            onSelect={this.selectSupplier} 
                            onChange={this.handleChange('supplier')}
                            />
                        </FormControl>    */}
 
  




                        </div>

                        
                          {/*   <form className={classes.container} noValidate>
                          <CssTextField
                            id="date"
                            label="Booking Date"
                            type="date"
                            onChange={this.handleChange('bookingdate')}
                            defaultValue="2019-09-24"
                            className={classes.textField}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </form> */}

                          {/* <form className={classes.container} noValidate>
                          <CssTextField
                            id="date"
                            label="Tour Date"
                            type="date"
                            onChange={this.handleChange('traveldate')}
                            defaultValue="2019-09-24"
                            className={classes.textField}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </form> */}

                       
                        <div id=" cat" style={{float:"right"}} className={classes.table}   >

                          <Table  style={{width:"90%", textAlign: "left"}} className={classes.table}    >
                          {/* <tr>
                              <th>Payout Total</th>
                              <th>Refund Total</th>
                              <th>Net Payout</th>
                            

                          </tr> */}
                            <tr>
                            
                                <td alighn="center" style={{width:"30%"}}  >
                                 <label htmlFor=""><small>Total Pay-out</small> </label><br/><label htmlFor=""><b>{this.props.BookingList1.total_pay}</b></label>
                                </td>
                                <td alighn="center" style={{width:"30%"}}  >
                                <label htmlFor=""> <small> Total Refunds</small> </label><br/> <label htmlFor=""><b>{this.props.BookingList1.total_refund}</b></label>
                                </td>
                                <td alighn="center" style={{width:"30%"}}  >
                                <label htmlFor=""> <small>Net Pay-out</small></label><br/><label htmlFor=""><b>{this.props.BookingList1.net_pay}</b></label>
                                </td>

                            </tr>

                          </Table>
                        
                  <Table  style={{width:"100%", textAlign: "left"}} className={classes.table} >
                    <tr >

                      <td alighn="left" style={{width:"23%"}}  >

                      <MuiPickersUtilsProvider utils={DateFnsUtils}>           
                            <FormControl className={classes.formControl} >
                                <DatePicker 
                                    value={this.state.fromdate}
                                    autoOk={true} 
                                    clearable={true}    
                                    format="dd/MM/yyyy"
                                    inputVariant="standard"
                                    onChange={(e)=>{this.handleDateChange(e,'fromdate')}}
                                    style={{width:100 }}                             
                                    margin="normal" label="From"
                                            />
                                      </FormControl>
                            </MuiPickersUtilsProvider>
                      </td>
                      <td alighn="left" style={{width:"20%"}}  >

                      <MuiPickersUtilsProvider utils={DateFnsUtils}>           
                            <FormControl className={classes.formControl} >
                                <DatePicker 
                                    value={this.state.todate}
                                    autoOk={true} 
                                    clearable={true}    
                                    format="dd/MM/yyyy"
                                    inputVariant="standard"
                                    onChange={(e)=>{this.handleDateChange(e,'todate')}}
                                    style={{width:100 }}                             
                                    margin="normal" label="To"
                                            />
                                      </FormControl>
                            </MuiPickersUtilsProvider>
                      </td>
                      {/* <td alighn="left" style={{width:"20%"}}>

                            <MuiPickersUtilsProvider utils={DateFnsUtils}>           
                            <FormControl className={classes.formControl} >
                                <DatePicker 
                                    value={new Date(this.state.bookingdate)}
                                    autoOk={true}     
                                    format="dd/MM/yyyy"
                                    inputVariant="standard"
                                    onChange={(e)=>{this.handleDateChange(e,'bookingdate')}}
                                    style={{width:100 }}                             
                                    margin="normal" label="Booking Date"
                                            />
                                      </FormControl>
                            </MuiPickersUtilsProvider>
                            
                      </td> */}

                      {/* <td alighn="left" style={{width:"20%"}} >
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>           
                            <FormControl className={classes.formControl} height="10px">
                                <DatePicker 
                                    value={new Date(this.state.traveldate)}
                                    autoOk={true}     
                                    format="dd/MM/yyyy"
                                    inputVariant="standard"
                                    onChange={(e)=>{this.handleDateChange(e,'traveldate')}}
                                    style={{width:100 }}                             
                                    margin="normal" 
                                    label="Tour Date"
                                            />
                                      </FormControl>
                            </MuiPickersUtilsProvider>
                      </td>
                           */}
                     <td alighn="left" style={{width:"20%"}} >
                       {/*   <FormControl className={classes.formControl}>
                          <InputLabel htmlFor="status-native-simple" >Tour Catogery</InputLabel>
                          <Select
                            native
                            // value={this.state.category}
                            onChange={this.selectCategory}
                            inputProps={{
                              name: 'status',
                              id: 'status-native-simple',
                            }}
                          >
                            <option value="" />
                            
                            { this.props.categories.map((item)=>{
                                  return <option value={item.id}>{item.name}</option>; 
                            }) }
                            
                          </Select>
                        </FormControl>
*/}

                               <FormControl style={{ width: 240 }}>

                                  <SearchSelect
                                    options={this.props.suppliers}
                                    getOptionLabel={option => `${option.work_name}`}
                                    getOptionValue={option => `${option.id}`}
                                    value={this.state.supplier}
                                    placeholder="Supplier"
                                     onSelect={this.selectSupplier} 
                                    onChange={this.handleChange1('supplier')}
                                    />
                                </FormControl>  
                        </td> 


                    </tr>
                  </Table>
                        
                        </div>
       


        <Table className={classes.table}>

        
            <tr>
           
                <th className={classes.textAlignCenter}>Tour Date/Time</th>
                  <th >Product Name</th>
                  <th className={classes.textAlignCenter}>Net Amount</th>
                  <th className={classes.textAlignCenter}>Pax Count</th>
                  <th className={classes.textAlignCenter}>Supplier Name</th>
                  <th className={classes.textAlignCenter}>Booking Date/Time</th>
                  <th className={classes.textAlignCenter}>Status</th>
                  <th ></th>
             </tr>
             {this.props.BookingList1.completed.map(row => (
  <tr key={row.id} >
    <td align="left" > {row.start_date} </td>
    <td align="left">  {row.activity.title}   </td>
    <td align="left">  {row.net_price}  </td>
    <td align="left"> {row.num_adults}A/ {row.num_children}C/ {row.num_infants}I  </td>
    <td align="left">{row.activity.supplier_name}</td>
    <td align="left"> {row.created_at} </td>
    <td align="left"> {StatusChip(row.status)} </td>
    <td align="left"></td>
   
  </tr>
))}

{this.props.BookingList1.ongoing.map(row => (
  <tr key={row.id} >

<td align="left" > {row.start_date} </td>
    <td align="left">  {row.activity.title}   </td>
    <td align="left">  {row.net_price}  </td>
    <td align="left"> {row.num_adults}A/ {row.num_children}C/ {row.num_infants}I  </td>
    <td align="left">{row.activity.supplier_name}</td>
    <td align="left"> {row.created_at} </td>
    <td align="left"> {StatusChip(row.status)} </td>
    <td align="left"></td>
  </tr>
))}

{this.props.BookingList1.dispute.map(row => (
  <tr key={row.id} >

<td align="left" > {row.start_date} </td>
    <td align="left">  {row.activity.title}   </td>
    <td align="left">  {row.net_price}  </td>
    <td align="left"> {row.num_adults}A/ {row.num_children}C/ {row.num_infants}I  </td>
    <td align="left">{row.activity.supplier_name}</td>
    <td align="left"> {row.created_at} </td>
    <td align="left"> {StatusChip(row.status)} </td>
    <td align="left"></td>
   
  </tr>
))}

             {this.props.BookingList1.upcoming.map(row => (
                <tr key={row.id} >

<td align="left" > {row.start_date} </td>
    <td align="left">  {row.activity.title}   </td>
    <td align="left">  {row.net_price}  </td>
    <td align="left"> {row.num_adults}A/ {row.num_children}C/ {row.num_infants}I  </td>
    <td align="left">{row.activity.supplier_name}</td>
    <td align="left"> {row.created_at} </td>
    <td align="left"> {StatusChip(row.status)} </td>
    <td align="left"></td>
                 
                </tr>
 ))}
 {this.props.BookingList1.cancelled.map(row => (
  <tr key={row.id} >
<td align="left" > {row.start_date} </td>
    <td align="left">  {row.activity.title}   </td>
    <td align="left">  {row.net_price}  </td>
    <td align="left"> {row.num_adults}A/ {row.num_children}C/ {row.num_infants}I  </td>
    <td align="left">{row.activity.supplier_name}</td>
    <td align="left"> {row.created_at} </td>
    <td align="left"> {StatusChip(row.status)} </td>
    <td align="left"></td>
   
  </tr>
))}
{this.props.BookingList1.pending.map(row => (
  <tr key={row.id} >

<td align="left" > {row.start_date} </td>
    <td align="left">  {row.activity.title}   </td>
    <td align="left">  {row.net_price}  </td>
    <td align="left"> {row.num_adults}A/ {row.num_children}C/ {row.num_infants}I  </td>
    <td align="left">{row.activity.supplier_name}</td>
    <td align="left"> {row.created_at} </td>
    <td align="left"> {StatusChip(row.status)} </td>
    <td align="left"></td>
   
  </tr>
))}












          </Table>


        </TabContainer>}
      </div></div></div>
    );
  }
}

TabsWrappedLabel.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(TabsWrappedLabel);
