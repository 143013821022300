import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';  

 




import Button from '@material-ui/core/Button';
import Select from 'react-select';



import { FetchFeaturedCat,AddFeaturedCat, } from '../Actions/SettingsAction';
import { fetchCategories } from '../Actions/CategoryAction';


import { compose } from 'redux';
import { connect } from 'react-redux';

import EditFeaturedCategory from './EditFeaturedCategory';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
});



const mapStateToProps = (state) => {
    return {
        loading : state.Common.loading,
        loggedIn : state.Auth.loggedIn,
        featuredCategories: state.Common.featuredCategories,
        categories: state.Categories.categories,
    }
}
const mapDispatchToProps = dispatch => ({
  fetchCategories:()=>dispatch(fetchCategories()),
  FetchFeaturedCat: () => dispatch(FetchFeaturedCat()),
  AddFeaturedCat:(data)=>dispatch(AddFeaturedCat(data))
});

class FeaturedCategories extends React.Component {


    constructor(props){
      super(props);
  
      this.state={
        value: 'one',
        title:"",
        description:"",
        categories:[],

      }
  
      this.props.fetchCategories();
      this.props.FetchFeaturedCat();
    
    }
  
    
    handleView = (data) => {
      this.props.ViewRefund(data);
    }
  
    handleChange = (event, value) => {
      this.setState({ value });
    };
  
    getRefundStatus(status){        
      switch(status){
      case 0 :{ return "Pending" }
      case 1:{ return "Accepted" }
      case 2 :{ return "Rejected" }
      case 3 :{ return "Completed" }    
      }
    }
  
    getPaymentStatus(status){        
      switch(status){
      case 0 :{ return "Pending" }
      case 1:{ return "Completed" }        
      }
    }


    handleNewSliderText = (e,field) => {
      this.setState({
        [field]:e.target.value
      });
    }

    handleAddCategory = () =>{
        if(this.state.sliderTitle !== null){
            this.props.AddFeaturedCat({title:this.state.title,description:this.state.description,categories:this.state.categories});
            this.setState({
              title:"",
              description:"",
              categories:[],
            })
        }
    }

    handleCategoryChange = (value) =>{
        console.log(value);
        this.setState({
            categories:value
        })
    }
  
    render() {
        const { classes } = this.props;
        const { value } = this.state;
    
     
        
    
        return (
           
            
            
             
                  
            <table className={classes.table}>
          
            <tr>
              <th align="left" style={{width:'5%'}}><p>ID</p></th> 
              <th align="left" style={{width:'10%'}}><p>Image</p></th>   
              <th align="left" style={{width:'7%'}}><p>Title</p></th>           
              <th align="left" style={{width:'15%'}}><p>Description</p></th>
              <th align="left" style={{width:'15%'}}><p>Categories</p></th>                           
              <th align="center"></th>
            </tr>
          
     
          <tr >               
                <td align="left" style={{width:'5%'}}></td>
                <td align="left" style={{width:'10%'}}></td>
                <td align="left" style={{width:'7%'}}>
                  <input type="text" value={this.state.title} onChange={(e)=>this.handleNewSliderText(e,'title')}/>
                </td>
                <td align="left" style={{width:'15%'}}>
                  <input type="text" value={this.state.description} onChange={(e)=>this.handleNewSliderText(e,'description')}/>
                </td>
                <td align="left" style={{width:'15%'}}>
                  <Select options={this.props.categories}  
                  isMulti 
                  getOptionLabel={option => `${option.name}`}
                  getOptionValue={option => `${option.id}`}
                  value={this.state.categories}
                  onChange={(value)=>this.handleCategoryChange(value)}
                  />
                  

                </td>              
                <td align="center">
                <Button onClick={()=>this.handleAddCategory()} size="small" variant="contained" color="primary" style={{background:"#f7a414", textAlign:'center'}} className={classes.button}>
                   <b>Add</b> 
                </Button>
                </td>
              </tr>  
            {this.props.featuredCategories.map((row,index) => {
                return <EditFeaturedCategory categories={this.props.categories} row={row}/>

            }
                
            )}

              
               
          
        </table>);
      }
    }
    
    FeaturedCategories.propTypes = {
      classes: PropTypes.object.isRequired,
    };
    export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(FeaturedCategories);
    
