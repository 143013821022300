import React, { Component } from 'react';
import CreatableSelect from "react-select/creatable";

const options = [
  { value: 'test1', label: 'test 1' },
  { value: 'test2', label: 'test 2' },
  { value: 'test3', label: 'test 3' },
];

export default class TagMulti extends Component {
  state={

    edit:this.props.edit,
    tags:[],
    tId:0


  }



  componentDidUpdate(prevProps, prevState) {
    if (this.state.tId !==this.props.id&&this.props.id!==0) {
      this.setState({
        tags:this.props.tags,
        tId:this.props.id,
      })
    }
  }




  handleEditMode=()=>{
    this.setState({
      edit:true
    })
  }

  handleDone=()=>{
    this.setState({
      edit:false
    })
  }

  handleChange = (newValue,actionMeta) => {
    if(newValue!==this.state.tags)
      {
        this.setState({
          tags:newValue
    },()=>this.props.onChange(newValue))
      }
    console.group('Value Changed');
    console.log(newValue);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();

  };
  render() {
    console.log(this.state.tags)
    console.log("this.props.tags")
    console.log(this.props.tags)
    return (<div>
                  {this.state.edit!==true ? <div>
                        <div style={{paddingRight:7}}>
                            <CreatableSelect
                            value={this.props.tags}
                            isMulti
                            options={this.props.options}
                            onChange={this.handleChange}
                          />
                        </div>
                    </div>
                  :null}
        </div>

    );
  }
}
