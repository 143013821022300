import React, { Fragment } from 'react';
import Select from 'react-select';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';


import { UpdatePolicy,DeletePolicy } from '../Actions/SettingsAction';

const mapStateToProps = (state) => {
    return {
        
    }
}
const mapDispatchToProps = dispatch => ({ 
    UpdatePolicy:(data)=>dispatch(UpdatePolicy(data)),
    DeletePolicy:(id)=>dispatch(DeletePolicy(id)),   
});

class EditPolicy extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            editMode: false,
            id:0,
            type:0,
            title:"",
            content:"",
            updated:false
        }
    }


    handleEditMode = () =>{
        this.setState({
            editMode:true
        });
    }


    handleApply =()=>{

        

        this.handleUpdate();

        this.setState({
            editMode:false,
            updated:false
        });
    }


    static getDerivedStateFromProps(props, state) {
        
        if (state.updated === false) {
            return {
                id:props.row.id,
                type:props.row.type,
                title:props.row.title,
                content:props.row.content,                               
                updated:true
            }
        }

        
        

        
    }

    handleUpdate = () =>{
        
        this.props.UpdatePolicy({id:this.state.id,
                                title:this.state.title,
                                content:this.state.content,
                                type:this.state.type});
    }

    handleEditField = (e,field) => {
        this.setState({
            [field]:e.target.value
        })
    }

    handleDelete = () =>{
        this.props.DeletePolicy(this.state.id);
    }

    

      handleCategoryChange = (value) =>{
        console.log(value);
        this.setState({
            categories:value
        })
      }

      handleChange = (event, field) => {
        this.setState({ [field]:event.target.value});
      };

    getPolicyType(status){        
        switch(status){
        case 0 :{ return "Know before you go" }
        case 1:{ return "Cancellation policy" }           
        }
      }

    render() {
        const { classes } = this.props;
    
        
        return <div style={{border:'1px solid #ccc', marginTop:25}}>
            {this.state.editMode === false ? (            
            <table>
            <tr>
                <td>Policy type</td>
                <td>{this.getPolicyType(this.state.type)}</td>
            </tr>
            <tr>
                <td>Policy title</td>
                <td>{this.state.title}</td>
            </tr>
            <tr>
                <td>Content</td>
                <td>{this.state.content}</td>
            </tr>
            <tr>
                <td></td>
                <td>
                <Button onClick={()=>this.handleEditMode()} size="small" variant="contained" color="primary" style={{background:"#f7a414", textAlign:'center'}} >
                      <b>Edit</b> 
                    </Button> &nbsp;
                    <Button onClick={()=>this.handleDelete()} size="small" variant="contained" color="primary" style={{background:"#f7a414", textAlign:'center'}} >
                      <b>Delete</b> 
                    </Button>
                </td>
            </tr>
        </table> 
                        ) : (
            
                            <table>
                            <tr>
                                <td>Policy type</td>
                                <td><select value={this.state.type} onChange={(e)=>this.handleChange(e,'type')}>
                                      <option value="0">Know before you go</option>
                                      <option value="1">Cancellation policy</option>
                                    </select></td>
                            </tr>
                            <tr>
                                <td>Policy title</td>
                                <td><input tyep="text" value={this.state.title} onChange={(e)=>this.handleChange(e,'title')} /></td>
                            </tr>
                            <tr>
                                <td>Content</td>
                                <td><textarea cols="100" rows="10" value={this.state.content} onChange={(e)=>this.handleChange(e,'content')} ></textarea></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                <Button onClick={()=>this.handleApply()} size="small" variant="contained" color="primary" style={{background:"#f7a414", textAlign:'center'}} >
                                      <b>Update</b> 
                                    </Button>
                                </td>
                            </tr>
                        </table>
            )}
        </div>
    }
}

EditPolicy.defaultProps = {
    readOnly:false
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(EditPolicy);