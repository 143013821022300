import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import TextField from '@material-ui/core/TextField';
import Taginput from '../../Components/TagInput';

import { compose } from 'redux';
import { connect } from 'react-redux';

import { showPaymentAction,createRefund } from '../../Actions/FinanceActions';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


  
  

   


const mapStateToProps = (state) => {
    return {               
        paymentRequestShow:state.Finance.paymentRequestShow,
        paymentRequestData:state.Finance.paymentRequestData,
        paymentdRequestAction:state.Finance.paymentdRequestAction,               
    }
}

const mapDispatchToProps = dispatch => ({
  showPaymentAction : (show,data,on) => dispatch(showPaymentAction(show,data,on)),
  createRefund:(amount,remark,data)=>dispatch(createRefund(amount,remark,data))
});

class PaymentRequest extends React.Component {
    constructor(props){
        super(props);

        this.state = {            
            reference:""
        };
    }

    handleConfirmaton = () =>{
      this.props.paymentdRequestAction(this.state.reference,this.props.paymentRequestData);
      this.props.showPaymentAction(false,null,null)
    }

    handleChage = (e,field)=>{
        this.setState({
            [field]:e.target.value
        });
    }

    handleClose = ()=> {        
       
        this.props.SnackBar(false,null,this.props.alertVarient);
    }

    render() {

        return (
            <div>             
              <Dialog
       // open={this.props.paymentRequestShow}
       open={true}
        //should true              
        TransitionComponent={Transition}
        keepMounted
        onClose={()=>{ this.props.showPaymentAction(false,null,null);}}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Edit Featured Catagory"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
              <div>
                  <table style={{width:500}}>
                     
                        <tr>
                          <th style={{ width:150, textAlign:'left', height:25 }}>Title</th>
                          <td>
                          <TextField
                             
                              style={{width:'100%'}}
                              type="text"
                              variant='outlined'
                            />
                          </td>
                      </tr>
                      <tr>
                          <th style={{ width:150, textAlign:'left',  height:25 }}></th>
                          <td>
                            &nbsp;
                          </td>
                      </tr> 
                      <tr>
                          <th style={{ width:150, textAlign:'left', height:25 }}>Description</th>
                          <td>
                          <TextField
                             
                              style={{width:'100%'}}
                              type="text"
                              variant='outlined'
                            />
                          </td>
                      </tr>
                      
                      
                      <tr>
                          <th style={{ width:150, textAlign:'left',  height:25 }}></th>
                          <td>
                            &nbsp;
                          </td>
                      </tr>
                      <tr>
                          <th style={{ width:150, textAlign:'left', height:25 }}>Image</th>
                          <td style={{verticalAlign:'top'}}>                          
                          <div style={{marginTop:25}}>
                          <input
                              accept="image/*"
                              style={{ display: "none" }}
                              id="file-work-logo"
                              type="file"
                              onChange={this.uploadImage}
                            />
                            <label htmlFor="file-work-logo">
                              <Button component="span" variant="contained" color="default" >
                                Upload Image &nbsp;
                                                <CloudUploadIcon  />
                              </Button>
                            </label>
                          </div>  
                          </td>
                      </tr>
                      <tr>
                          <th style={{ width:150, textAlign:'left',  height:25 }}></th>
                          <td>
                            &nbsp;
                          </td>
                      </tr>
                      <tr>
                          <th style={{ width:150, textAlign:'left' }}>Catagory</th>
                          <td style={{verticalAlign:'top'}}>
                         <Taginput></Taginput>
                          </td>
                      </tr>
                  </table>
              </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{ this.props.showPaymentAction(false,null,null);}} style={{ color:'orange'}}  color="primary">
            Cancel
          </Button>
          <Button disabled={this.state.reference.length === 0}  onClick={()=>{this.handleConfirmaton();}} style={{ color:'orange'}}  color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
              </div>);
    }
    
}


export default compose(connect(mapStateToProps, mapDispatchToProps))(PaymentRequest);
