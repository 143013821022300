export default function reducer(state={
    list: new Array(),
    activeSupplier: {
                    id: 0,
                    name: null,
                    email: null,
                    user_type: 1,
                    status: 1,
                    work_name: null,
                    work_address: null,
                    work_reg_number: null,
                    work_fix_phone: null,
                    work_mobile: null,
                    work_avatar: null,
                    contact_name: null,
                    contact_NIC: null,
                    contact_home_phone: null,
                    contact_mobile_phone: null,
                    contact_address: null,
                    contact_avatar: null,
                    categories: [],
                    sales_commission_rate:0,
                    sales_commission:false,
                    banned_till:null,
                    br_copy:"",
                    tb_licence_copy:"",
                    ins_copy:"",
                    billing_proof:"",
                    categories:[],
                    email_verified:0,
                    work_name_review: false,
                    work_address_review: false,
                    work_reg_number_review: false,
                    work_fix_phone_review: false,
                    work_mobile_review: false,
                    contact_name_review: false,
                    contact_NIC_review: false,
                    contact_home_phone_review: false,
                    contact_mobile_phone_review: false,
                    contact_address_review: false,
                    bank_name_review: false,
                    bank_branch_review: false,
                    bank_account_review: false,
                    bank_account_name_review: false,
                    work_logo_review: false,
                    contact_logo_review: false,
                    br_copy_review: false,
                    tb_licence_copy_review: false,
                    ins_copy_review: false,
                    billing_proof_review: false, 
                    bank_name: null,
                    bank_branch: null,
                    bank_account: null,
                    bank_account_name: null,
                    in_review_fields:[],
                    rejected_fields:[],   
                    updated:false,
                },
    
},action){
switch(action.type){
    case "STORE_SUPPLIERS":{
        return{...state,list:action.list}
    }
    case "STORE_ACTIVE_SUPPLIERS":{
        return{...state,
                    activeSupplier:{                                   
                        
                                    id: action.user.id,
                                    name: action.user.name,
                                    email: action.user.email,
                                    user_type: action.user.user_type,
                                    status: action.user.status,
                                    work_name: action.user.work_name,
                                    work_address: action.user.work_address,
                                    work_reg_number: action.user.work_reg_number,
                                    work_fix_phone: action.user.work_fix_phone,
                                    work_mobile: action.user.work_mobile,
                                    work_avatar: action.user.work_avatar,
                                    contact_name: action.user.contact_name,
                                    contact_NIC: action.user.contact_NIC,
                                    contact_home_phone: action.user.contact_home_phone,
                                    contact_mobile_phone: action.user.contact_mobile_phone,
                                    contact_address: action.user.contact_address,
                                    contact_avatar: action.user.contact_avatar,
                                    categories: action.user.categories,

                                    sales_commission_rate:action.user.sales_commission_rate,
                                    sales_commission:action.user.sales_commission === 0 ? false:true,
                                    banned_till: action.user.banned_till,

                                    br_copy:action.user.br_copy,
                                    tb_licence_copy:action.user.tb_licence_copy,
                                    ins_copy:action.user.ins_copy,
                                    billing_proof:action.user.billing_proof,
                                    email_verified:action.user.email_verified,

                                    bank_name: action.user.bank_name,
                                    bank_branch: action.user.bank_branch,
                                    bank_account: action.user.bank_account,
                                    bank_account_name: action.user.bank_account_name,

                                    work_name_review: action.user.work_name_review,
                                    work_address_review: action.user.work_address_review,
                                    work_reg_number_review: action.user.work_reg_number_review,
                                    work_fix_phone_review: action.user.work_fix_phone_review,
                                    work_mobile_review: action.user.work_mobile_review,
                                    contact_name_review: action.user.contact_name_review,
                                    contact_NIC_review: action.user.contact_NIC_review,
                                    contact_home_phone_review: action.user.contact_home_phone_review,
                                    contact_mobile_phone_review: action.user.contact_mobile_phone_review,
                                    contact_address_review: action.user.contact_address_review,
                                    bank_name_review: action.user.bank_name_review,
                                    bank_branch_review: action.user.bank_branch_review,
                                    bank_account_review: action.user.bank_account_review,
                                    bank_account_name_review: action.user.bank_account_name_review,
                                    work_logo_review: action.user.work_logo_review,
                                    contact_logo_review: action.user.contact_logo_review,
                                    br_copy_review: action.user.br_copy_review,
                                    tb_licence_copy_review: action.user.tb_licence_copy_review,
                                    ins_copy_review: action.user.ins_copy_review,
                                    billing_proof_review: action.user.billing_proof_review,
                                    in_review_fields:action.user.in_review_fields,
                                    rejected_fields:action.user.rejected_fields,
                                    updated:true,
                                }
              }
    }
    case "LOCK_ACTIVE_SUPPLIER":{
        return{...state,
                activeSupplier:{    ...state.activeSupplier,                                
                                    updated:false,
                                }
              }
    }    
    case "LOADIND_END":{ 
        return{...state,loading:false}
    }
    case "UPDATE_TOUR":{
        return {...state,activeTour:action.tour}
    }    
}
return state
}