import React, { Props,Component, Fragment } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';


import { compose } from 'redux';
import { connect } from 'react-redux';
import { validateUser, storeAuthToken, fetchUser, storeUser } from '../Actions/AuthActions';
import { SnackBar } from '../Actions/CommonActions';

import {Redirect} from 'react-router-dom'

import logo from '../Img/logo.png';
import Loader from './Common/Loader';
import Alert from './Common/Alert';
import Box from '@material-ui/core/Box';
import { green } from '@material-ui/core/colors'; 


 


const styles = theme => ({
   
    wrap:{
        width:480, 
        margin:'auto',
        marginTop:100,
                
    },
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit * 2,

      '& label.Mui-focused': {
      color: 'black',
    }


    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width:'98%'
      },

      button: {
        margin: theme.spacing.unit,
      }, 
      logoWrap:{
          textAlign:'center',
      } 
  });


const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "black"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#f7a414"
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black"
      },
      "&:hover fieldset": {
        borderColor: "#f7a414"
      },
      "&.Mui-focused fieldset": {
        borderColor: "black"
      }
    }
  }
})(TextField);

const defaultProps = {
  bgcolor: 'background.paper',
  m: 1,
  border: 1,
  style: { width: '5rem', height: '5rem' },
};


const mapStateToProps = (state) => {
    return {
        loading : state.Common.loading,
        loggedIn : state.Auth.loggedIn 
    }
}

const mapDispatchToProps = dispatch => ({
    validateUser : (username,password) => dispatch(validateUser(username,password)),
    SnackBar : (show,message,varient) => dispatch(SnackBar(show,message,varient))
});

class Login extends Component {

    constructor(props){
        super(props);
       

        this.state = {
            username: "",
            password:"",
            loggedIn:false,
            loading:false,
        };
    }

    componentDidMount(){
        document.body.style.background = "#eee";
       
       
    }

    keyPressed(event) {
  if (event.key === "Enter") {
    this.submitMessage()
  }
}

    handleChange = name => event => {
        this.setState({
          [name]: event.target.value,
        });
      };
 
    login(){
        console.log('erer');
        if(this.state.username.length == 0){
            this.props.SnackBar(true,"We need your Email",'error');
            return false;
        }

        if(this.state.password.length == 0){
            this.props.SnackBar(true,"Password can not be empty",'error');
        }
        
        this.props.validateUser(this.state.username,this.state.password);
        
    }

    handleEnterKey = (e) =>{
        if (e.key === 'Enter') {
              this.login();  
              e.preventDefault();
            }
    }

    render(){

        const { classes } = this.props;

        return <Fragment>
            <Loader></Loader>
            <Alert/>
            <div className={classes.wrap}>
            <Paper className={classes.root} elevation={1}>
            <div className={classes.logoWrap}>
            <img src={logo}/>
            
            </div>
           
               <CssTextField
                    id="outlined-name"
                    label="Email"
                    className={classes.textField}
                    value={this.state.name}
                    onChange={this.handleChange('username')}
                    onKeyPress={(e)=>this.handleEnterKey(e)}                    
                    margin="normal"
                    variant="outlined"
                        
                    />
           

            <CssTextField
                    id="custom-css-standard-input"
                    label="Password"
                    className={classes.textField}
                    type="password"
                    value={this.state.name}
                    onChange={this.handleChange('password')}
                    onKeyPress={(e)=>this.handleEnterKey(e)}                          
                    margin="normal"
                    variant="outlined"
                    ref=" password"
                    name="password"
                    />



                <Button id="classes.textField" variant="contained" onClick={()=>{ this.login();}} color="primary" className={classes.button} style={{background:"#f7a414"}}>
                    Login
                </Button>

                
            </Paper>
            </div>

            {this.props.loggedIn ? <Redirect to="./Dashboard"></Redirect>:null}
        </Fragment>
    }
}

Login.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default compose(connect(mapStateToProps, mapDispatchToProps),withStyles(styles))(Login);