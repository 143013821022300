import {API_CLIENT_ID, API_CLIENT_SECRET, API_URL, API_GRANT_TYPE} from './Config';
import {startLoading,endLoading, SnackBar,handleHTTPError} from './CommonActions';
import store from '../store';
import axios from 'axios';



export function MyReviews(sid,statusid) {

    if(sid === null || sid === undefined ){
        var sid = "";
    }

    if(statusid === null || statusid === undefined ){
        var statusid = "";
    }

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/admin/reviews/get?sid='+sid+'&status='+statusid,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            // fetch(API_URL+'/api/admin/reviews/get',{
            //  fetch(API_URL+'/api/admin/reviews/get?sid='+sid+'&status='+statusid,{    
            
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());                   
                  dispatch(storeReviews(json));                                                     
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'No items found','info'));
        });
    }   

}



export function storeReviews(list) {
    return {
        type: 'STORE_REVIEWS',
        list: list
    }
}


export function ActivityReviews(aid) { 

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/admin/reviews/get?aid='+aid,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());                   
                  dispatch(storeActivityReviews(json));                                                     
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }   

}


export function storeActivityReviews(list) {
    return {
        type: 'STORE_ACTIVITY_REVIEWS',
        list: list
    }
}



export function RejectReview(show,review) {

    return function(dispatch) {
        dispatch(showRejectReview(show,review,(review,note)=>dispatch(DoReject(review,note))));
    }
}


export function DoReject(review,note) {

    return function(dispatch) {
        dispatch(SnackBar(true,'Updating','info'));
        fetch(API_URL+'/api/admin/reviews/reject',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            body:JSON.stringify({review:review,note:note}),
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());                   
                  dispatch(ActivityReviews(review.activity_id));
                  dispatch(MyReviews()); 
                  dispatch(SnackBar(true,'Updated','success'));                                                    
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }   

}

export function SetStatus(review,status) {

    return function(dispatch) {
        dispatch(SnackBar(true,'Updating','info'));
        fetch(API_URL+'/api/admin/reviews/status',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            body:JSON.stringify({review:review,status:status}),
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());                   
                  dispatch(ActivityReviews(review.activity_id));
                  dispatch(MyReviews());  
                  dispatch(SnackBar(true,'Updated','success'));                                                   
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }   

}

export function showRejectReview(show,review,action) {
    return {
        type: 'SHOW_SHOW_REJECT_REVIEW',
        show: show,
        data: review,
        on:action
    }
}

export function CompleteBookingRefresh() {
    return function(dispatch) {
        dispatch(SnackBar(true,'Refresh the booking status','info'));
        fetch(API_URL+'/api/admin/reviews/crone',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            }
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(SnackBar(true,'Booking status updated','success'));                                                   
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Status updated failed','error'));
        });
    }   

}

