import React, { Fragment } from 'react';
import Select from 'react-select';
import { compose } from 'redux';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import UploadIcon from '@material-ui/icons/CloudUpload';


import { UpdateFeaturedCat,DeleteFeaturedCat,UploadFeaturedCatImage } from '../Actions/SettingsAction';

const mapStateToProps = (state) => {
    return {
        
    }
}
const mapDispatchToProps = dispatch => ({ 
    UpdateFeaturedCat:(data)=>dispatch(UpdateFeaturedCat(data)),
    DeleteFeaturedCat:(id)=>dispatch(DeleteFeaturedCat(id)),
    UploadFeaturedCatImage:(data)=>dispatch(UploadFeaturedCatImage(data))
});

class EditFeaturedCategory extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            editMode: false,
            id:0,
            title:"",
            description:"",
            categories:[],
            subtext:"",
            imageName:null,
            updated:false,
            imageUpdated:false
        }
    }


    handleEditMode = () =>{
        this.setState({
            editMode:true
        });
    }


    handleApply =()=>{

        

        this.handleUpdate();

        this.setState({
            editMode:false,
            updated:false
        });
    }


    static getDerivedStateFromProps(props, state) {
        
        if (state.updated === false) {
            return {
                id:props.row.id,
                title:props.row.title,
                description:props.row.description,
                categories:props.row.categories,
                imageName:props.row.image_name,                
                updated:true
            }
        }

        if (state.imageName !== props.row.image_name) {
            return {                
                imageName:props.row.image_name,               
                
            }
        }

        

        
    }

    handleUpdate = () =>{
        console.log({id:this.state.id,title:this.state.title,description:this.state.description,categories:this.state.categories});
        this.props.UpdateFeaturedCat({id:this.state.id,title:this.state.title,description:this.state.description,categories:this.state.categories});
    }

    handleEditField = (e,field) => {
        this.setState({
            [field]:e.target.value
        })
    }

    handleDelete = () =>{
        this.props.DeleteFeaturedCat(this.state.id);
    }

    uploadImage = (e) => {

        if (e.target.files[0] != null) {
            
          this.props.UploadFeaturedCatImage({uploadImage:e.target.files[0],id:this.state.id});
          this.setState({
            editMode:false,
            imageUpdated:true
            });      
        }
    
      }

      handleCategoryChange = (value) =>{
        console.log(value);
        this.setState({
            categories:value
        })
    }

    render() {
        const { classes } = this.props;
    
        
        return <Fragment>
            {this.state.editMode === false ? (            
            <tr >               
                <td align="left" style={{width:'5%'}}>{this.state.id}</td>
                <td align="left" style={{width:'10%'}}>{ this.state.imageName !== null ? <img src={this.state.imageName} width={120} height="auto" /> : null  }</td>
                <td align="left" style={{width:'7%'}}>{this.state.title}</td>
                <td align="left" style={{width:'15%'}}>{this.state.description !== null ? this.state.description.substring(0,20):""}...</td>              
                <td align="left" style={{width:'15%'}}>{ this.state.categories.length > 0 ? this.state.categories[0].name+( this.state.categories.length > 1 ? " & " + (this.state.categories.length - 1) +' More':""):null}</td>              
                <td align="center">
                <IconButton onClick={()=>this.handleEditMode()} size="small" >
                    <EditIcon />
                </IconButton>    
                 &nbsp;
                 <IconButton onClick={()=>this.handleDelete()} size="small">
                     <DeleteIcon />
                 </IconButton>
                
                </td>
              </tr> 
                        ) : (
            
                <tr >               
                <td align="left" style={{width:'5%'}}>{this.state.id}</td>
                <td align="left" style={{width:'10%'}}>{ this.state.imageName !== null ? <img src={this.state.imageName} width={120} height="auto" /> : null  }</td>
                <td align="left" style={{width:'7%'}}>
                    <input type="text" value={this.state.title} onChange={(e)=>this.handleEditField(e,'title')} />            
                </td>
                <td align="left" style={{width:'15%'}}>
                    <input type="text" value={this.state.description} onChange={(e)=>this.handleEditField(e,'description')} />            
                </td> 
                <td align="left" style={{width:'15%'}}>
                <Select options={this.props.categories}  
                  isMulti 
                  getOptionLabel={option => `${option.name}`}
                  getOptionValue={option => `${option.id}`}
                  value={this.state.categories}
                  onChange={(value)=>this.handleCategoryChange(value)}
                  />            
                </td>              
                <td align="center">
                <input
                              accept="image/*"
                              style={{ display: "none" }}
                              id="file-slide"
                              type="file"
                              onChange={this.uploadImage}
                            />
                            <label htmlFor="file-slide">
                                <IconButton component="a" size="small">
                                    <UploadIcon/>
                                </IconButton>                        
                </label> &nbsp;
                <IconButton onClick={()=>this.handleApply()}>
                    <SaveIcon/>
                </IconButton>
                               
                </td>
              </tr>
            )}
        </Fragment>
    }
}

EditFeaturedCategory.defaultProps = {
    readOnly:false
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(EditFeaturedCategory);